import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes, { string } from 'prop-types';
import { Stack, Card, CardContent, Grid, Typography } from '@mui/material';

AIHistoryCard.propTypes = {
  id: PropTypes.number,
  images: PropTypes.array,
  query: string
};

export default function AIHistoryCard({id, images, query}) {
  const params = useParams();
  return (

    <Link to={`/artist/artwork/generated/${id}`}>
      <Card className='history-card bg-neutral'>
        <CardContent>
          <Typography variant="body1" className={Number(params.id) !== id && "inactive"}>
            “{query}”
          </Typography>
          {Number(params.id) === id &&
            <Grid container spacing={2} className="history-images">
            {images?.map((image) => (
              <Grid item xs={4}>
                <img src={image} alt="generated"/>
              </Grid>
            ))}
            </Grid>
          }
        </CardContent>
      </Card>
    </Link>
  );
};