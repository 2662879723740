import {React, useEffect, useState} from "react";
import InfoIcon from '@mui/icons-material/Info';
import {Typography} from "@mui/material";
import axios from "axios";
import {API_URL} from "../../../hooks/globals";
import {useAuth} from "../../../auth/Authentication";
import CheckoutModal from "../../../auth/register-checkout/CheckoutModal";

const UnpaidAlert = ({props}) => {
    const auth = useAuth();
    const [showAlert, setShowAlert] = useState(false)
    const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
    const [paymentPage, setPaymentPage] = useState(false);
    const [checkoutUrl, setCheckoutUrl] = useState("");
    const checkInvoices = () => {
        const config = {
            method: 'get',
            url: `${API_URL}artist/get-invoices-new/unpaid`,
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        }
        axios(config).then((res) => {
            console.log(res.data)
            if (res.data.length !== 0)
                setShowAlert(() => true)
            else
                setShowAlert(() => false)
        }).finally(() => {
        })
    }
    const updatePaymentMethod = () => {
        setPaymentPage(() => false)
        const config = {
            method: 'get',
            url: `${API_URL}checkout-update-method`,
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        }
        axios(config).then((res) => {
            if (res.data) {
                setCheckoutUrl(() => res.data.url)
                setCheckoutModalOpen(() => true)
            }
        }).finally(() => {
        })
    }
    const handleOncloseCheckoutForm = () => {
        setCheckoutModalOpen(false)
        const config = {
            method: 'get',
            url: `${API_URL}collect-now`,
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        }
        let type
        axios(config).then((res) => {
            console.log(res.data)
            type = res.data.type
            if (res.data) {
                setCheckoutUrl(() => res.data.url)
                setCheckoutModalOpen(() => true)
            }
        }).finally(() => {
            if (type === 'collect_now')
                if (paymentPage) {
                    setCheckoutModalOpen(() => false)
                    checkInvoices()
                }
                else
                    setPaymentPage(() => true)
        })
    }
    useEffect(() => {
        if (auth.userToken)
            checkInvoices()
    }, [])
    return (
        <>
            {showAlert && <div role="button" tabIndex={-99} onKeyUp={() => ''} onClick={updatePaymentMethod} style={{
                backgroundColor: '#f06767',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 0px',
                cursor: 'pointer'
            }}>
                <InfoIcon style={{marginRight: 5}}/>
                <Typography align={'center'}>
                    <strong>Your most recent payment was unsuccessful.</strong> Click here to update your payment method to avoid subscription cancellation.
                </Typography>
            </div>
            }
            <CheckoutModal
                onOpen={checkoutModalOpen}
                checkoutUrl={checkoutUrl}
                onHandleClose={handleOncloseCheckoutForm}
            />
        </>

    )
}
export default UnpaidAlert