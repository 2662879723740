import useShowResource from "../../../hooks/useShowResource";
import ShowLayout from "../../../layouts/dashboard/show/ShowLayout";
import RowLayout from "../../../layouts/dashboard/show/RowLayout";
import LoadingLayout from "../../../layouts/dashboard/show/LoadingLayout";


export default function ShowCoupon() {
  const resource = useShowResource('admin/coupons/');  

  return (
    <ShowLayout>
      {resource?.loading ?
          <LoadingLayout skeletons={1}/>
        :
        <>
          <RowLayout label="Code" value={resource?.code}/>
        </>
      }
    </ShowLayout>
  )
}