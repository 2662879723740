import { useMemo } from "react";

export default function useRunnerLocations() {
  const locations = useMemo(() => ({
    emiel: "Groningen, The Netherlands",
    maria: "Groningen, The Netherlands",
    lucia: "Groningen, The Netherlands",
    flemming: "Groningen, The Netherlands",
    rodrigo: "Groningen, The Netherlands",
    matija: "Ljubljana, Slovenia",
    abhishek: "Groningen, The Netherlands",
    luka: "Ljubljana, Slovenia",
    marino: "Ljubljana, Slovenia",
    matej: "Ljubljana, Slovenia",
    federica: "Groningen, The Netherlands",
    derek: "New York City, USA",
    tsvetina: "Groningen, The Netherlands",
    jasper: "Groningen, The Netherlands",
    boglarka: "Groningen, The Netherlands",
    alejandro: "Groningen, The Netherlands",
    dre: "London, England",
    ethan: "New York City, USA",
    luke: "New York City, USA",
    martina: "Groningen, The Netherlands",
    matt: "Groningen, The Netherlands",
    gijs: "Groningen, The Netherlands",
    matthew: "Groningen, The Netherlands",
    denise: "Groningen, The Netherlands",
    daniela: "Groningen, The Netherlands",
    sem: "Groningen, The Netherlands",
    kris: "Groningen, The Netherlands"
  }), []);

  return locations;
}