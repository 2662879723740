import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/Authentication';
import { useColorMode } from '../theme/artist/ColorModeContext';
import Iconify from '../components/iconify/Iconify';

// eslint-disable-next-line react/prop-types
const SubscriptionNotice = () => {
  const [showLoadingBtn, setShowLoadingButton] = useState(false);
  const { user, checkSubscription } = useAuth();
  const colorMode = useColorMode();

  const handleRefresh = async () => {
    setShowLoadingButton(true);
    await checkSubscription();
    setTimeout(() => {
      setShowLoadingButton(false);
      window.location.reload();
    }, 1500);
  };

  return (
    <Box
      className="showSubscriptionNotice"
      sx={{
        position: 'fixed',
        left: '0px',
        right: '0px',
        bottom: '0px',
        top: '0px',
        color: 'black',
        zIndex: 1000,
      }}
    >
      <Box
        className=""
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          height: '100%',
          padding: '15px',
          backgroundColor: colorMode?.darkMode === true ? '#242424' : 'white',
        }}
      >
        <Iconify icon="material-symbols:lock-outline" width="152px" height="152px" style={{ color: '#66ff99' }} />
        <Typography
          sx={{
            color: colorMode?.darkMode === true ? 'white' : '#242424',
            fontSize: '17px',
            textAlign: 'center',
          }}
        >
          Thank you for subscribing! We're currently processing your payment. To refresh your subscription status, please
          click the "Refresh" button.
        </Typography>
          <LoadingButton
            sx={{ width: '70%', height: '45px', backgroundColor: '#1dd263', boxShadow: 'none' }}
            size="medium"
            type="submit"
            variant="contained"
            loading={showLoadingBtn}
            onClick={handleRefresh}
          >
            <Box
              className="subscription"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                color: 'black', 
              }}
            >
              {!showLoadingBtn && 
                <>
                  <Iconify icon="ion:reload" height="16" width="16" />
                  <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: 500 }}>Refresh Subscription</Typography>
                </>
              }
            </Box>
          </LoadingButton>
      </Box>
    </Box>
  );
};

export default SubscriptionNotice;
