import { useMemo } from "react";

export default function useRunnerAvailability() {
  const availability = useMemo(() => ({
    lucia: [0, 1, 3],
    emiel: [0, 1, 2, 3, 4],
    maria: [0, 1, 2, 3, 4],
    flemming: [0, 1, 2, 3, 4],
    rodrigo: [0, 1, 2, 3, 4],
    matija: [0, 1, 2, 3, 4],
    abhishek: [0, 1, 2, 3, 4],
    luka: [0, 1, 2, 3, 4],
    marino: [0, 1, 2, 3, 4],
    matej: [0, 1, 2, 3, 4],
    federica: [0, 1, 2, 3, 4],
    derek: [0, 1, 2, 3, 4],
    tsvetina: [0, 1, 2, 3, 4],
    jasper: [0, 1, 2, 3, 4],
    boglarka: [0, 1, 2, 3, 4],
    alejandro: [0, 2, 4],
    dre: [0, 1, 2, 3, 4],
    ethan: [0, 1, 2, 3, 4],
    luke: [0, 1, 2, 3, 4],
    martina: [0, 1, 3, 4],
    matt: [0, 1, 2, 3, 4],
    gijs: [0, 1, 2, 3, 4],
    matthew: [0, 1, 2, 3, 4],
    denise: [0, 1, 3, 4],
    daniela: [0, 1, 2, 3, 4],
    sem: [0, 1, 3, 4],
    kris: [0, 1, 2, 3, 4]
  }), []);

  return availability;
}