import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Alert, Card, CardContent, Divider, Grid, Typography, Stack, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "../../auth/Authentication";
import { API_URL } from "../../hooks/globals";
import useConsoleLog from "../../hooks/useConsoleLog";


export default function OrderShow() {
    const [isLoading, setIsLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [axiosError, setAxiosError] = useState(false);
    const [orderUpdates, setOrderUpdates] = useState([]);
    const [order, setOrder] = useState();
    const routeParams = useParams();
    const auth = useAuth();
    const log = useConsoleLog;

    function getOrder() {
        log(auth.userToken);
        if (!order?.id) {
            const url = `${API_URL}artist/orders/${routeParams.id}`;

            const config = {
                method: 'get',
                url,
                headers: { 
                    'Authorization': `Bearer ${auth.userToken}`
                }
            };

            axios(config).then((response) => {
                log(response);
                setOrder(response.data);
            })
            .catch((error) => {
                setAxiosError(true);
                log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    }

    function getOrderUpdates() {
        const url = `${API_URL}artist/order/${routeParams.id}/updates`;
        const config = {
            method: 'get',
            url,
            headers: { 
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            setOrderUpdates(response.data);
        })
        .catch((error) => {
            setAxiosError(true);
            log(error);
        })
        .finally(() => {
            setIsLoading(false);
        });

    }

    useEffect(() => {
        setAxiosError(false);
        getOrder();
        getOrderUpdates();
    }, []);

    return (
        <>
        <Grid container gap={3} sx={{paddingX: '24px'}}>
            <Grid item md={7}>
                <Paper sx={{padding: '30px'}}>
                    <Stack gap={3}>
                        <Grid container justifyContent="space-between">
                        <Typography variant="h4">Order {order?.number}</Typography>
                        </Grid>
                        <Typography variant="h5">Description</Typography>
                        <Typography variant="body1">{order?.description}</Typography>
                        <Typography variant="body2"><strong>Status:</strong> {order?.status}</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item md={4}>
                <Paper sx={{padding: '30px'}}>
                    <Stack gap={3}>
                        <Typography variant="h4">Order update history</Typography>
                        
                        <Box sx={{maxHeight: '600px', overflow: 'auto' }}>
                            <Stack gap={3}>
                                {orderUpdates.length ? orderUpdates.map((orderUpdate) => {
                                    return <Card sx={{marginRight: '10px'}}>
                                        <CardContent>
                                            <Stack gap={3}>
                                                <Typography variant="h5">{orderUpdate.title}</Typography>
                                                <Typography variant="body1">{orderUpdate.description}</Typography>
                                                <Divider/>
                                                <Typography variant="body2">{orderUpdate.doneAt}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                }) : <Alert severity="warning">No order updates yet.</Alert>}                        
                            </Stack>       
                        </Box>
                    </Stack>     
                </Paper>           
            </Grid>
            {axiosError && 
            (
            <Grid item md={12}>
                <Alert severity="error">Something went wrong, please check your internet connection and retry.</Alert>
            </Grid>
                )}
            
        </Grid>
        </>
    )
}