import { Card, useTheme, Table, TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';

UrlTableCellLayout.propTypes = {
  chidlren: PropTypes.element,
  url: PropTypes.string
}

export default function UrlTableCellLayout({children, url}) {
  const theme = useTheme();

  return (
  <>
    <TableRow
     className="bg-neutral">
      <TableCell sx={{
        overflowWrap: "anywhere"
        }}
        className="bg-neutral"
        >
          <a 
          href={url} rel="noreferrer" 
          style={{
            color: theme.palette.common.black, 
            fontWeight: 400,
            borderBottom: `1px solid ${theme.palette.common.black}`,
            fontSize: '1rem'
          }} 
          target="_blank">
            {children}
          </a>
      </TableCell>
    </TableRow>
  </>
  )
}