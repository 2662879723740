import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Grid, useTheme } from "@mui/material";
import Generator from "../generator";
import GeneratedImage from './GeneratedImage';
import galleryItems from '../gallery/config';
import useResponsive from '../../../../hooks/useResponsive';
import Heading from '../heading';
import { APP_NAME, TITLE_SEPERATOR } from '../../../../hooks/globals';
import Layout from '../Layout';
import useGetListing from '../../../../api/useGetListing';
import useConsoleLog from '../../../../hooks/useConsoleLog';
import usePost from '../../../../api/usePost';
import { useAuth } from '../../../../auth/Authentication';
import LoadingScreen from '../loading-screen';

export default function GeneratedImages() {
  const theme = useTheme();
  const [images, setImages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const get = useGetListing();
  const params = useParams();
  const log = useConsoleLog;
  const post = usePost();
  const auth = useAuth();
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const getImages = () => {
    setImages([]);
    get.getListing(`artist/artwork/${params.id}`)
    .then((response) => {
      setImages(response.data.imageUrls);
      setPrompt(response.data.query);
      log(response.data);
    })
    .catch((err) => {
      log(err);
    });
  }
  
  const generateVariations = (url) => {
    log(url);
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    log(fileName);
    setIsLoading(true);
    setErrors({});
    window.scrollTo(0, 0);

    post(`artist/artwork/generate/${params.id}`, {url: fileName})
    .then((res) => {
      log(res);
      auth.updateUser(res.data.user);
      setImages(res.data.artwork.imageUrls);
    }).catch((err) => {
      console.log(err);
      if (err.response?.status === 500) {
        if (err.response?.data?.response?.error?.message) {
          log(err.response?.data?.response?.error?.message);
          setErrors({messageIse: err.response?.data?.response?.error?.message});
        } else {
          setErrors({messageIse: 'Something went wrong on our side!'});
        }
      } else {
        setErrors(err.response?.data.errors);
      }
    }).finally(() => {
      setIsLoading(false);
    })
  }


  useEffect(() => {
    getImages();
  }, [params.id])

  if (!isLoading) {
    return (
      <Layout>
        <Helmet>
          <title>Generated images {TITLE_SEPERATOR} {APP_NAME}</title>
        </Helmet>
        <Heading
          title="Album cover gallery"
          description="Chances are that you are not a professional visual artist. Because of this reason, we give you the opportunity to make use of our ai artwork generator. Type in the perfect prompt and generate an album cover that best fits you."
          showHist={false}
        />
        <Generator
          onSetIsLoading={setIsLoading}
          onSetErrors={setErrors}
          onErrors={errors}
          />
        <Grid container spacing={{md: 1, xs:0}} rowSpacing={{xs: 8.5}}>
            {images.map((image) => (
              <Grid item md={4} xs={12}>
                <GeneratedImage onGenerateVariations={() => generateVariations(image)} image={image} prompt={prompt}/>
                {typeof errors?.messageIse !== "undefined" &&
                  <Box className="mt-2">
                    <Alert severity="error">{errors?.messageIse}</Alert>
                  </Box>
                }
              </Grid>
            ))}
        </Grid>
      </Layout>
    );
  };

  return <LoadingScreen/>
}


