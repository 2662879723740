import { Box, Typography } from "@mui/material";
import { string } from "prop-types";
import { fShortenNumber } from "../../../../utils/formatNumber";

CampaignViews.propTypes = {
  title: string,
  views: string
}

export default function CampaignViews({title, views}) {
  return (
    <Box className="campaign-extra-info-wrapper h-100">
      <Typography className="extra-info-title showmore-title" variant="body1">
        {title}
      </Typography>
      <Box className="green-box mt-1 text-black view-amount text-center d-flex" justifyContent="center" alignItems="center">
        {fShortenNumber(views) || 0}
      </Box>
    </Box>
  )
}
