import { Box, Typography } from "@mui/material";


export default function MostPopular() {
  return (
    <Box className="most-popular-header">
      <Typography className="text">
        Most popular
      </Typography>
    </Box>
  )
}