import {useState} from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import { fShortenNumber } from "../../../../utils/formatNumber";
import Iconify from '../../../../components/iconify/Iconify';
import useResponsive from "../../../../hooks/useResponsive";

PlatformCard.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  dataType: PropTypes.string,
  amount: PropTypes.string,
  active: PropTypes.bool,
  diff: PropTypes.number,
  withMenu: PropTypes.bool,
  onMenuClick: PropTypes.func,
  isMenuItem: PropTypes.bool,
  isDropDownItem: PropTypes.bool
}

export default function PlatformCard({icon, name, dataType, amount, active, diff, withMenu, onMenuClick, isMenuItem, isDropDownItem}) {
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  // usage of this state is important to display updates in this component
  const [platformCardState, setPlatformCardState] = useState(isDropDownItem);
  const onExpandButtonClick = () => {
    setPlatformCardState(!platformCardState);
    onMenuClick();
  }
  console.log('isDropDownItem, platformCardState ', isDropDownItem, platformCardState )
  return (
      <Card className={`social-card ${isOnSmallScreen && 'small-radius'} ${!isDropDownItem ? 'active mb-1 pb-2':''}`}>
        <Grid className={`${isDropDownItem ? 'showWithDataType':''}`} container justifyContent={(isOnSmallScreen && isMenuItem) ? 'center' : 'space-between'}>
          <Grid xs={isDropDownItem ? 5:2} item md={active ? 2 : 12} className={withMenu && 'pl-1'}>
            <CardMedia className="media">
              <img src={`/assets/icons/socials/${icon}.svg`} alt={name} className={withMenu ? `big m-auto socialIconMenu${icon}` : isOnSmallScreen && 'm-auto'}/>
            </CardMedia>
            {isOnSmallScreen &&
                <Typography textAlign={'center'}>{name}</Typography>
            }
          </Grid>
          {(!isOnSmallScreen || !isMenuItem) &&
              <Grid item md={active ? 10 : 12 } sx={{ textAlign: 'right' }}>
                <CardContent className={`content ${withMenu && 'pr-2'}`}>
                  <Box className={!isDropDownItem ? 'dataTypeInfo':''} sx={{ marginTop: { xs: 'none', sm: '1rem' } }}>
                    <Typography variant="body1" className={`datatype ${active && "text-right mt-0"}`}>
                      {dataType}
                    </Typography>
                    <Typography variant="body1" className={`amount ${active && "text-right"}`}>
                      {amount ? fShortenNumber(amount) : "..."}
                    </Typography>
                  </Box>
                  {(withMenu && !platformCardState) &&
                      <Box onClick={onExpandButtonClick} className={'menuButtonDataType'}>
                        <Iconify icon={`material-symbols:expand-more`} width={35}/>
                      </Box>
                  }
                  {(withMenu && platformCardState) &&
                      <Box onClick={onExpandButtonClick} className={'menuButtonDataType'}>
                        <Iconify icon={`material-symbols:expand-less`} width={35}/>
                      </Box>
                  }
                  {
                      active && !withMenu &&
                      <Box className="text-right diff-wrapper">
                        <Typography>
                          Last 30 days
                        </Typography>
                        <Typography className={`diff ${diff > 0 ? "green" : "red"}`}>
                          {diff > 0 ? "+" : "-"}{diff === 0 ? diff : fShortenNumber(diff)}%
                        </Typography>
                      </Box>
                  }
                </CardContent>
              </Grid>
          }
          {isDropDownItem &&
              <Grid item xs={5} className={'socialDropDownDataType'}>
                <CardContent className={`content`}>
                  <Typography variant="body1" className={`datatype center`}>
                    {dataType}
                  </Typography>
                  <Typography variant="body1" className={`amount`}>
                    {amount ? fShortenNumber(amount) : "..."}
                  </Typography>
                </CardContent>
              </Grid>
          }
        </Grid>
      </Card>
  )
}
