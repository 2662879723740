// component
import Iconify from "../../../components/iconify/Iconify";

// ----------------------------------------------------------------------

const icon = (name) => <Iconify icon={name}/>;

const navConfig = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: icon('ion:analytics-sharp'),
    roles: ['super-admin', 'admin']
  },
  {
    title: 'Admins',
    path: '/admin/admins',
    icon: icon('mdi:users-group'),
    roles: ['super-admin']
  },
  {
    title: 'Artists',
    path: '/admin/artists',
    icon: icon('pixelarticons:users'),
    roles: ['admin', 'super-admin']
  },
  {
    title: 'Coupons',
    path: '/admin/coupons',
    icon: icon('mdi:coupon-outline'),
    roles: ['admin', 'super-admin']
  },
];

export default navConfig;
