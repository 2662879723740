import {React, useState} from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from "@mui/system";
import Iconify from "../components/iconify/Iconify";
import { useModal } from "./ModalContext";

export default function FormModal(props) {
  const modal = useModal();

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    

    return (
    <div>
    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={modal.handleOpen}>
        {props.buttonName}
    </Button>
    <Modal
    open={modal.open}
    onClose={modal.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
      <Stack spacing={3}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
        Add a {props.title}
        </Typography>
        {props.form}
      </Stack>
    </Box>
    </Modal>
    </div>
    )
}