import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Alert,
  Button,
  useTheme,
} from '@mui/material';
import axios from 'axios';
// components
import { Link, useLocation } from 'react-router-dom';
import Scrollbar from '../../../../../components/scrollbar';
// sections
import { ItemListHead } from './item';
import { API_URL, APP_NAME, TITLE_SEPERATOR } from '../../../../../hooks/globals';
import useConsoleLog from '../../../../../hooks/useConsoleLog';
import { useAuth } from '../../../../../auth/Authentication';
import Arrow from "../../../../../components/arrow-bg/Arrow";
// ----------------------------------------------------------------------


export default function InvoiceListingLayout(props) {

  function descendingComparator(a, b, orderBy) {
    let theA = a[orderBy];
    let theB = b[orderBy];
    
    if (orderBy === 'createdAt') {
      theA = new Date(a[orderBy]?.split("-").reverse().join("-"));
      theB = new Date(b[orderBy]?.split("-").reverse().join("-"));
    }
  
    if (theB < theA) {
      return -1;
    }
    if (theB > theA) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_item) => {
        return _item[props.objectKeys[1]]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
      });
        
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const [axiosError, setAxiosError] = useState('');

  const log = useConsoleLog;

  const [open, setOpen] = useState(null);


  const [page, setPage] = useState(0);

  const theme = useTheme();

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState({});
  const [selectedForMultiDelete, setSelectedForMultiDelete] = useState([]);

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [itemList, setItemList] = useState([]);

  const [emptyRows, setEmptyRows] = useState(0);

  const [filteredItems, setFilteredItems] = useState();

  const [isNotFound, setIsNotFound] = useState(false);


  const [apiRequestsDone, setApiRequestsDone] = useState(0);



  const location = useLocation();


  // order statuses options start, only if on orders page
  const [startChange, setStartChange] = useState();


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (Object.keys(selected)?.length > 0) {
      setSelected({});
      setSelectedForMultiDelete([])
    } else {
      const newSelecteds = {};
      const multiDelIds = [];

      filteredItems.map((item, i) => {
        newSelecteds[i] = true;
        multiDelIds.push(item.id)
        return newSelecteds;
      });

      log('multideldi');
      log(multiDelIds);

      setSelectedForMultiDelete(multiDelIds);
      
      setSelected(newSelecteds);      
    }
  };

  const handleClick = (event, name) => {
    log(name);
    const newSelected = {...selected};
    let multiDelIds = [...selectedForMultiDelete];
    
    if (newSelected[name]) {
      delete newSelected[name];
      multiDelIds = multiDelIds.filter((item) => item !== filteredItems[name].id);
    } else {
      newSelected[name] = true;
      multiDelIds.push(filteredItems[name].id);
    }

    log('singi');
    log(multiDelIds);
    setSelectedForMultiDelete(multiDelIds);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const auth = useAuth();

  const initItems = (alwaysRun = false) => {
    setAxiosError('');
    if (!props.data) {
      const url = `${API_URL}${props.apiPath}`;

      const config = {
        method: 'get',
        url,
        headers: { 
            'Authorization': `Bearer ${auth.userToken}`
        }
      };
  
      axios(config).then((response) => {
        const theItems = [...Object.values(response.data)]
        log("theItems");
        log(theItems);
        setItemList(theItems);
    
        setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemList.length) : 0);
    
        if ((!filteredItems?.length && theItems.length) || alwaysRun) {
          log('set filtered items');
          setFilteredItems(applySortFilter(theItems, getComparator(order, orderBy), filterName));
        }
  
        if (!theItems.length) {
          setAxiosError("No invoices found!");        
        }
    
        setIsNotFound(!filteredItems?.length && !!filterName);
  
        log(response);
      })
      .catch((error) => {
        setAxiosError('Something went wrong while fetching the data!');
        log(error);
      })
      .finally(() => {
      });
    } else {
        const theItems = [...Object.values(props.data)];
        setItemList(theItems);
        log(theItems);
    
        setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemList.length) : 0);
    
        if ((!filteredItems?.length && theItems.length) || alwaysRun) {
          log('set filtered items');
          setFilteredItems(applySortFilter(theItems, getComparator(order, orderBy), filterName));
        }
    }
  }

  // Function that downloads PDF
  const download = (data, name) => {
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.download = name;
      a.href = url;
      a.target = '_self';
  
      a.click();
  }

  const handleDownload = (id, name) => {
    setAxiosError('');
    const url = `${API_URL}artist/invoices/${id}/download`;

    const config = {
      method: 'get',
      url,
      headers: { 
          'Authorization': `Bearer ${auth.userToken}`
      }
    };

    axios(config).then((response) => {
        log(response);
        window.open(response.data.download);
    })
    .catch((error) => {
        log(error);
    });
  }

  useEffect(() => {
    initItems();
  }, [filterName, order, orderBy, props.data]);



  

  return (
    <>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {props.pageTitle}
          </Typography>
        </Stack>
        <Arrow platformId={props.platform}/>
        <Card className={props.showCustomBorder ? 'custom-border' : ''} sx={{width: "100%", backgroundColor: theme.palette.grey[200], padding: 0}}>

          <Scrollbar>
            <TableContainer>
              <Table sx={{maxWidth: "100%"}}>
                <ItemListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={props.TABLE_HEAD}
                  rowCount={itemList.length}
                  numSelected={Object.keys(selected).length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  pageTitle={`${props.pageTitle}`}
                  noDelete={props.noDelete}
                />
                <TableBody>
                    
                  {filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowKey) => {
                    const { id, name } = row;
                    const cells = Object.values(row);
                    const cellKeys = Object.keys(row);

                    return (
                      <>
                      <TableRow hover key={`row-${rowKey}-${id}-${row[Object.keys(row)[1]]}`} tabIndex={-1} role="checkbox" selected={selected[rowKey]}>
                        {cells.slice(1).map((value, key) => {
                            return key < 4 &&
                              <TableCell key={`ztablecell-${key}-${value}`} align="left" size="small" sx={{borderColor: theme.palette.grey[300], fontSize: 17}}>
                                {value}
                              </TableCell>
                        })}
                        {!props.data &&
                          <TableCell align="left" size="small" sx={{borderColor: theme.palette.grey[300]}}>
                            <Button onClick={() => handleDownload(row.id, `${row.plan}-${row.date}`)} sx={{textDecoration: "underline", color: theme.palette.common.black, fontSize: 17, fontWeight: 400, padding: 0}}>Download</Button>
                          </TableCell>
                        }
                      </TableRow>
                      </>
                    );
                  })}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
                          
          {axiosError && (<Alert severity='error'>{axiosError}</Alert>)}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={itemList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
    </>
  );
}
