import { useState } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Button, Grid, Stack, useTheme } from '@mui/material';
import StyledBox from '../../../theme/artist/overrides/StyledBox';
import ImagesLeft from './images-left';
import History from './history';
import Footer from '../../../layouts/loggedout/Footer';
import useResponsive from '../../../hooks/useResponsive';
import StyledLoadingButton from '../../../theme/artist/overrides/StyledLoadingButton';
import Iconify from '../../../components/iconify';
import GalleryButton from './GalleryButton';
import { useAuth } from '../../../auth/Authentication';
import { deviceChecker } from '../../../helpers/checkDeviceHelpers';
import SubscriptionNotice from '../../../modals/SubscriptionNotice';

export default function Layout({ children }) {
  const isOnSmallScreen = useResponsive('down', 'md', 'lg');
  const auth = useAuth();
  const { isApp } = useAuth();
  const location = useLocation()

  if (auth.user?.subscription?.plan?.permissions?.includes('can-generate-artwork') &&
    auth.user?.subscription?.status !== 'cancelled') {
    return (
      <>
        <StyledBox className="container">
          <Grid container spacing={6}>
            <Grid item md={8.75} xs={12}>
              <Stack spacing={3}>{children}</Stack>
            </Grid>
            {!isOnSmallScreen && (
              <Grid item md={3.25} xs={12}>
                <ImagesLeft />
                <Stack spacing={2} className="nav-btn-wrapper" direction="column">
                  <History />
                  <GalleryButton />
                </Stack>
              </Grid>
            )}
          </Grid>
        </StyledBox>
        <Footer />
      </>
    );
  }

  if (isApp) {
    return <SubscriptionNotice />;
  }

  return <Navigate to={'/checkout'} />;
}
