import { useContext } from "react";
import axios from "axios";
import { useAuth } from "../auth/Authentication";
import useConsoleLog from "../hooks/useConsoleLog";
import { API_URL } from "../hooks/globals";


export default function usePost () {
    const auth = useAuth();
    const log = useConsoleLog;

    return (path, data, method = 'POST') => {
        log(auth.userToken);
        const url = `${API_URL}${path}`;

        const config = {
            method,
            url,
            data: {...data, _method: method},
            headers: { 
                'Authorization': `Bearer ${auth.userToken}`,
                'Accept': 'application/json'
            }
        };

        return axios(config);
    }
}

    