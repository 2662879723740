import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { object, string } from "prop-types";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fShortenNumber } from "../../../../utils/formatNumber";

TopTrack.propTypes = {
  data: object,
  platformColor: string
}


export default function TopTrack({data, platformColor}) {
  const params = useParams();


  return (
    <Link to={`/artist/statistics/track/${data.songstats_track_id}`}>
      <Card className={`track-card ${params?.id === data.songstats_track_id && 'active'}`} sx={{backgroundColor: platformColor}}>
        <CardMedia className="track-image">
          <img src={data.image_url} alt={data.track_name}/>
        </CardMedia>
        <CardContent className="track-info">
          <Typography className="track-name">
            {data.track_name?.length > 10 ? `${data.track_name.slice(0, 10)}...` : data.track_name}
          </Typography>
          <Box className="track-stats">
            <Typography>
              Streams
            </Typography>
            <Typography>
              {fShortenNumber(data.rank_value)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  )
}
