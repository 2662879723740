import { Box, Typography } from "@mui/material";
import { func, object, string } from "prop-types";
import { Link } from "react-router-dom";
import Iconify from "../../../components/iconify";
import { menuTextSx } from "./DesktopMenu";


LockedMenuItem.protoTypes = {
  page: object,
  onCloseNavMenu: func,
  toolTipTitle: string
}

export default function LockedMenuItem({page, onCloseNavMenu, toolTipTitle}) {
  return (
    <a href="https://landing.yougrowplus.com/" target="_blank" rel="noreferrer">
      <Box className="locked-menu-item">
        <Box className='locked'>
          <Iconify icon="mdi:lock-outline"/>
        </Box>
        <Typography 
          variant="body1"
          className='locked'
          onClick={onCloseNavMenu}
          sx={menuTextSx}
        >
          {page.title}
        </Typography>
        <Box className="custom-tooltip">
          <Typography>
            Only available with the {toolTipTitle}
          </Typography>
        </Box>
      </Box>
    </a>
  )
}