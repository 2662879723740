const navConfigLoggedOut = [
  {
    title: 'Login',
    path: '/login',
    mobile: true,
  },
  {
    title: 'Register',
    path: '/register-free',
    mobile: true
  },
];

export default navConfigLoggedOut;
