import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#242424',
  100: '#767676',
  200: '#454545',
  300: '#403e3e',
  400: '#666666',
  500: '#fff',
  600: '#eee',
  700: '#5A5A5A',
  800: '#101101',
  900: '#232323',
  1000: '#434343',
};

const PRIMARY = {
  lighter: '84e184',
  light: '#70dc70',
  main: '#32CD32',
  dark: '#239023',
  darker: '#0a290a',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '84e184',
  light: '#70dc70',
  main: '#32CD32',
  dark: '#239023',
  darker: '#0a290a',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#c2c2c2',
  light: '#d9d9d9',
  main: '#aaaaaa',
  dark: '#8c8c8c',
  darker: '#707070',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const paletteDark = {
  common: {
    black: '#fff',
    loDark: '#f4f4f4',
    white: '#242424',
    light: '#000',
    whiteLow: '#FCFCFC',
    litUp: '#242424',
    litDown: '#f2f2f2',
    seefour: '#C4C4C4',
  },
  darkGreen: '#4d5c55',
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  light: {main: "#fff"},
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[500],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: GREY[900],
    default: GREY[0],
    neutral: GREY[900],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  platform: {
    spotify: '#1DD263',
    tiktok: '#ea2157',
    youtube: '#FC0404',
    instagram: '#F0B25C',
    apple_music: '#6CB1F7',
    deezer: '#9EF935',
    soundcloud: '#FF6C00',
    itunes: '#FF7BDB',
    tracklist: '#E86666',
    tidal: '#009B83',
    traxsource: '#40A0FF',
    beatport: '#01FF95',
    shazam: '#0088FF',
    amazon: '#FF9900',
    facebook: '#1976d2',
    twitter: '#03a9f4'
  },
};

export default paletteDark;
