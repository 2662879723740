import { Stack, Box, TextField, Typography, useTheme, Alert } from "@mui/material";
import { useState } from "react";
import useGetListing from "../../../api/useGetListing";
import usePost from "../../../api/usePost";
import { useAuth } from "../../../auth/Authentication";
import useConsoleLog from "../../../hooks/useConsoleLog";
import { useFormHandler } from "../../../hooks/useFormHandler";
import useResponsive from "../../../hooks/useResponsive";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";
import ArtistSearchList from "./ArtistSearchList";
import Iconify from "../../../components/iconify";

export default function SearchArtist() {
  const theme = useTheme();
  const fHandler = useFormHandler();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [artists, setArtists] = useState([]);
  const [selected, setSelected] = useState("");
  const log = useConsoleLog;
  const auth = useAuth();
  const get = useGetListing();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const post = usePost();
  const [searched, setSearched] = useState(false);
  
  const search = (e) => {
    e.preventDefault();
    log(setIsLoading);
    setIsLoading(true);
    log(fHandler.formValues);

    get.getListing(`artist/artists-search/${fHandler.formValues?.prompt}`)
    .then((res) => {
      log(res);
      setArtists(res.data.results);
      setSearched(true);
    }).catch((err) => {
      log(err);
      if (err.response?.status === 500) {
        if (err.response?.data?.response?.error?.message) {
          log(err.response?.data?.response?.error?.message);
          setErrors({messageIse: err.response?.data?.response?.error?.message});
        } else {
          setErrors({messageIse: 'Something went wrong on our side!'});
        }
      } else {
        setErrors(err.response?.data.errors);
      }
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const confirm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    post('artist/add-selected-id', {selected}, "PATCH")
    .then((response) => {
      log(response);
      const userb = {...auth.user};
      userb.spotify_id = response.data.platformId;
      if (userb.avatar_url) {
          userb.avatar_url = response.data.avatarUrl;
      }
      auth.updateUser(userb);

      window.location.reload();

    }).catch((error) => {
      log(error.response.data.errors);
      log(error);
      
      setErrors(error.response.data.message || "Something went wrong, the ID might not exist.");

    }).finally(() => {
      setIsLoading(false);
    });
  }

  const searchChange = (e) => {
    setSelected("");
    fHandler.handleValChange(e);
  }

  return (
    <form onSubmit={Boolean(selected) ? confirm : search}>
      <Stack className="add-spotify-id-section" spacing={3}>  
        <Typography className="add-id-tips-title">
          Adding your account
        </Typography>
        <Box className="search-artist-wrapper">
          <TextField
            className="search-artist"
            placeholder={`Type in your artist name`}
            sx={{
              backgroundColor: theme.palette.common.white,
            }}
            name="prompt"
            value={fHandler.formValues?.prompt}
            onChange={searchChange}
            error={errors?.messageIse} 
            helperText={errors?.messageIse}
            multiline={isOnSmallScreen}
          />
          {Boolean(artists?.length) && 
            <ArtistSearchList 
              selectedArtist={selected} 
              onSetSelected={setSelected} 
              data={artists}
            />
          }
          {!Boolean(artists?.length) && searched && !isLoading &&
            <Alert severity="warning" className="mt-2">No artist found for this query</Alert>
          }
        </Box>
        <StyledLoadingButton loading={isLoading} className="search-artist-btn" type="submit">
          {Boolean(selected) ? 
              "Confirm"
          :
            "Search"
          }
        </StyledLoadingButton>
      </Stack>
    </form>
  )
}