import { Box, Button, Grid, Stack, useTheme } from "@mui/material";
import { array } from "prop-types";
import { useState } from "react";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import galleryItems from "./config";


GalleryItems.propTypes = {
  items: array
}
export default function GalleryItems({items}) {
  const theme = useTheme();
  const [loadMore, setLoadMore] = useState(9);
  const log = useConsoleLog;

  log(items.length);

  return (
    <Box>
      <Grid container spacing={{xs: 1, md: 3}}>
        {items?.slice(0, loadMore).map((item) => (
          <Grid item md={4} xs={6}>
            <img width="100%" src={item.url} alt={item.id}/>
          </Grid>
        ))}
      </Grid>
      {items?.length > loadMore &&
        <Button variant="contained" className="load-more-images bg-neutral-hover bg-neutral contrast-neutral-bg"
        onClick={() => setLoadMore(loadMore + 9)}
        >Load more</Button>
      }
    </Box>
  ) 
}