import { forwardRef } from 'react';
import { useTheme, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';
import PropTypes from 'prop-types';
import UrlTableLayout from './UrlTableLayout';
import UrlTableCellLayout from './UrlTableCelLayout';
import { StyledScrollbar } from '../../../components/scrollbar/styles';


const Playlists = forwardRef(({playlists, type}, ref) => {
  return (
    <Box className="white-box bg-neutral mt-1" ref={ref}>
      <StyledScrollbar
        forceVisible="y"
        autoHide={false}
        hidden={false}
      >
          <UrlTableLayout>
              <TableBody>
                {playlists.map((playlist) => (
                  <UrlTableCellLayout url={playlist.Playlist__c}>
                    {playlist.Playlist__c}
                  </UrlTableCellLayout>
                ))}
              </TableBody>
          </UrlTableLayout>
      </StyledScrollbar>
    </Box>
  )
});

Playlists.propTypes = {
  playlists: PropTypes.array,
  type: PropTypes.string
}

export default Playlists;