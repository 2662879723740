import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, redirect} from "react-router-dom";
import Cookies from "universal-cookie";
import { useAuth } from "../../../auth/Authentication";
import PersonalDetails from "./my-account/PersonalDetails";
import SubscriptionDetails from "./my-account/SubscriptionDetails";
import StyledBox from "../../../theme/artist/overrides/StyledBox";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import Invoices from "./my-account/invoices/Invoices";
import AddSocials from "./my-account/AddSocials";

export default function MyAccount() {
    const navigate = useNavigate();
  
    const auth = useAuth();
    const theme = useTheme();
  
  
    useEffect(() => {    
      if (!auth.isAuthed()) {
        navigate("/login")
      } else {
        auth.checkSubscription();
      }
    }, []);

    if (auth.user) {
        return (
            <>
            <Helmet>
                <title>Your account {TITLE_SEPERATOR} {APP_NAME}</title>
            </Helmet>
            <StyledBox className="container">
                <Typography variant="h1" sx={{color: theme.palette.common.black}} className="intro-title">
                    Your account
                </Typography>
                <Grid container rowGap={5} columnSpacing={{md: 14, xs: 3}} sx={{paddingTop: {md: "66px", xs: 1}}}>
                    <Grid item md={7} xs={12} order={{md: 0, xs: 2}}>
                        <PersonalDetails auth={auth}/>
                    </Grid>
                    <Grid item md={5} order={{md: 0, xs: 1}}>
                        <SubscriptionDetails
                            auth={auth}
                        />
                    </Grid>
                    <Grid item sx={{flexBasis:'100%'}} md={12} order={{md: 0, xs: 3}}>
                        <Box>
                            <AddSocials/>
                        </Box>
                    </Grid>
                    <Grid item md={6} order={{md: 0, xs: 4}}>
                        <Invoices/>
                    </Grid>
                </Grid>
            </StyledBox>
            </>
        )
    }

    return null;
}