import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Box, Typography, Stack, Tooltip} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes, { func, object, string } from 'prop-types';
import { useAuth } from '../../../auth/Authentication';
import { useColorMode } from '../../../theme/artist/ColorModeContext';
import Iconify from '../../../components/iconify';
import SubMenu from './SubMenu';
import useCheckMatchingArrayValues from '../../../hooks/useCheckMatchingArrayValues';
import LockedMenuItem from './LockedMenuItem';


WithSubMenu.protoTypes = {
  page: object,
  onCloseNavMenu: func
}

export const menuTextSx = { 
  color: 'text.primary',
}

function WithSubMenu({page, onCloseNavMenu}) {
  return (
    <>
      <Typography variant="body1"
        onClick={onCloseNavMenu}
        sx={menuTextSx}
        >
          {page.title}
        </Typography>
        {page.subMenu !== null &&
        <Box className="contrast-neutral-bg">
          <Iconify icon="pepicons-pop:angle-down"/>
        </Box>
        }
      </>
  )
}

MenuLink.protoTypes = {
  page: object,
  onCloseNavMenu: func
}

function MenuLink({page, onCloseNavMenu}) {
  return (
    <Link
    to={page.path}
    key={page.title}>
      <Typography variant="body1"
      onClick={onCloseNavMenu}
      sx={menuTextSx}
      >
      {page.title}
      </Typography>
    </Link>
  )
}

DesktopMenu.propTypes = {
    pages: PropTypes.array,
    closeNavMenu: PropTypes.func
}

export default function DesktopMenu({pages, closeNavMenu}) {
    const checkMatchingPermissions = useCheckMatchingArrayValues();
    const auth = useAuth();

    const StyledMenu = styled(Box)({
      flexGrow: 1,
      display: "flex"
    });
    
    const StyledMenuItem = styled(Box)(({theme}) => ({
      marginLeft: "28px",
      marginRight: "28px",
      paddingLeft: "8px",
      paddingRight: "8px",
      lineHeight: "0.5",
    }));



    return (
        <StyledMenu>
            {pages.map((page) => (
              (page.roles?.includes(auth.user.role) || !page.roles) 
              && 
            <StyledMenuItem>
              <Box className="head-menu">
                <Stack direction="row" gap={1} alignItems="center">
                  {
                  ( 
                    (!page.subscribed || (page.subscribed && auth.isSubscribed())) 
                    && (
                      !page.permissions || (auth.user?.subscription?.plan?.permissions
                      && checkMatchingPermissions(auth.user?.subscription?.plan?.permissions, page.permissions))
                      && auth.user.subscription.status !== "cancelled"
                    )
                  ) ?
                  page.path !== null ?
                    <MenuLink
                      page={page}
                      onCloseNavMenu={closeNavMenu}
                      />
                  :
                  <WithSubMenu
                    page={page}
                    onCloseNavMenu={closeNavMenu}
                  />
                  :
                  <LockedMenuItem
                    page={page}
                    onCloseNavMenu={closeNavMenu}
                    toolTipTitle={page.availableAt}
                  />
                }
                </Stack>
                {page.subMenu !== null &&
                  <SubMenu config={page.subMenu}/>
                }
              </Box>
            </StyledMenuItem>
            ))}
       </StyledMenu>
    )
}