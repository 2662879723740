import { useTheme } from "@emotion/react";
import { Paper, Grid, Typography, Box, Stack } from "@mui/material";
import styled from '@emotion/styled';
import PlanFeatures from "../../layouts/artist-dashboard/PlanFeatures";
import useGetSkeletons from "../../hooks/useGetSkeletons";


const StyledBorderBox = styled(Box)(({sx, theme}) => ({
    position: "absolute",
    width: "1px",
    backgroundColor: theme.palette.grey[700],
    height: "251px",
    top: 130,
    ...sx
}));


export default function PricingPlans(props) {
    const theme = useTheme(); 
    const [getSkeletons] = useGetSkeletons();

    return (
        <Paper sx={{
                boxShadow: "0px 3px 3px rgba(0,0,0,0.4 )", 
                marginTop: "91px", 
                paddingTop: "33px", 
                paddingLeft: "48px", 
                paddingBottom: "46px", 
                paddingRight: "48px",
                backgroundColor: theme.palette.grey[200]
            }}>
            <Grid container spacing={5}>
                {props.loading ? getSkeletons(1, "100%", "500px") :
                <>
                    {props.plans?.map((plan, index) => (
                        <Grid item xs={6} key={plan.name} sx={{position: "relative"}}>
                            {index+1 % 3 === 2 && <StyledBorderBox sx={{left: 10}}/>}
                            <Box>
                                <Typography sx={{fontSize: "20px", fontWeight: "bold", color: theme.palette.common.black}}>
                                    {plan.name}
                                </Typography>
                            </Box>
                            <Box sx={{mt: {md: "84px", xs: "20px"}}}>
                                <Stack spacing={4} justifyContent="start" textAlign={"left"}>
                                    <PlanFeatures allFeatures={props.allFeatures} features={plan.features}/>
                                </Stack>
                            </Box>
                            {index+1 % 3 === 2 && <StyledBorderBox sx={{right: -10}}/>}
                        </Grid>
                    ))}
                </>
                }
            </Grid>
        </Paper>
    )
}