import { useContext } from "react";

const { useState, createContext, default: React } = require("react");

const ModalContext = createContext();


export function ModalProvider({children}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [delOpen, setDelOpen] = useState({open: false, delId: '', name: '', path: '', key: -1});
    const handleDelOpen = (id, name, path, key) => setDelOpen({open: true, delId: id, name, path, key});
    const handleDelClose = () => setDelOpen({open: false, delId: '', name: '', path: '', key: -1});
    const [newItem, setNewItem] = useState({});

    return <ModalContext.Provider value={{setNewItem, newItem, handleOpen, handleClose, open, delOpen, handleDelClose, handleDelOpen}}>{children}</ModalContext.Provider>;
}

export function useModal() {
    return useContext(ModalContext);
}
    