import { useCallback, useContext, useEffect } from "react";
import axios from "axios";
import FormData from "form-data";
import Cookies from 'universal-cookie';
import { useLocation, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL, APP_URL } from "../hooks/globals";
import useConsoleLog from "../hooks/useConsoleLog";

const { useState, createContext, default: React } = require("react");

const AuthContext = createContext();


export function AuthProvider({children}) {
    const [searchParams] = useSearchParams();
    const cookies = new Cookies();
    const [user, setUser] = useState(cookies.get('user') || null);
    const [loading, setLoading] = useState(false);
    const [socialStats, setSocialStats] = useState({
        instagram: localStorage.getItem("ig-followers") || 0,
        facebook: localStorage.getItem("fb-likes") || 0,
        tiktok: localStorage.getItem("tt-followers") || 0,
        youtube: localStorage.getItem("yt-subs") || 0,
        twitter: localStorage.getItem("tw-followers") || 0,
    });
    const [prevSocialStats, setPrevSocialStats] = useState({
        instagram: localStorage.getItem("ig-followers-prev") || 0,
        facebook: localStorage.getItem("fb-likes-prev") || 0,
        tiktok: localStorage.getItem("tt-followers-prev") || 0,
        youtube: localStorage.getItem("yt-subs-prev") || 0,
        twitter: localStorage.getItem("tw-followers-prev") || 0,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(searchParams.has('authCode') ? searchParams.get('authCode') : cookies.get('user')?.token || '');
    const [isApp, setIsApp ] = useState(searchParams.has('isApp'));
    const [errors, setErrors] = useState();
    const router = useLocation();
    const [passwordResetResponse, setPasswordResetResponse] = useState('');
    const log = useConsoleLog;

    log(user?.token);
    log(user);

    const setUserCookie = ( user, remember ) => {
        const options = {
            path: '/',
            secure: true,
            sameSite: 'Strict'
        };

        if (remember) {
            options.expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000); // One year from now
        }

        cookies.set('user', user, options);
    }

    if (user) {
        log(user?.token);
    }

    const login = (data) => {
        setLoading(true);

        const config = {
            method: 'post',
            url: `${API_URL}login-admin`,
            data,
            headers: {
                "Accept": "application/json",
            }
        };

        axios(config)
        .then((response) => {
            const userb = {...response.data.data, authed: true, token: response.data.token};

            setUser(userb);
            setUserToken(userb.token);

            setUserCookie(userb);


            log(response);

            setErrors(null);

            navigate('/admin/dashboard', true);
            window.location.reload(true);
        })
        .catch((error) => {
            log(error);
            setErrors(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const artistLogin = (data, refresh = false) => {
        setLoading(true);

        const config = {
            method: 'post',
            url: `${API_URL}login-artist`,
            data
        };

        axios(config)
        .then((response) => {
            const userb = {...response.data.data, authed: true, token: response.data.token};
            const remember = data.rememberMe;

            setUser(userb);
            setUserToken(userb.token);
            setUserCookie(userb, remember);
            checkSubscription();

            log(cookies.get('user'));

            setErrors(null);

            if (refresh) {
                window.location.reload(false);
                return;
            }

            navigate('/artist/orders', true);
        })
        .catch((error) => {
            log(error);
            setErrors(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const registerFree = (data) => {
        setLoading(true);

        const config = {
            method: 'post',
            url: `${API_URL}register-free`,
            data
        };

        axios(config)
        .then((response) => {
            const userb = {...response.data.data, authed: true, token: response.data.token};

            log(response);
            setErrors(null);
            
            updateUser(userb);

            navigate('/artist/orders');
            window.location.reload(true);
        })
        .catch((error) => {
            log(error);
            setErrors(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const registerWithCheckout = (data) => {
    }

    const authedCheckout = (data) => {
        setLoading(true);

        const config = {
            method: 'post',
            url: `${API_URL}authed-subscription-checkout`,
            data,
            headers: { 
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios(config)
        .then((response) => {
            log(response);

            setErrors(null);

            const theData = response.data;

            const newLocation = theData.payment_link;

            window.location.replace(newLocation);
        })
        .catch((error) => {
            log(error.response);
            setErrors(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const logout = (redirectTo = '/admin/login') => {
        cookies.remove('user', {path: '/'});
        setUser(null);

        const config = {
            method: 'post',
            url: `${API_URL}logout`,
            headers: { 
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios(config)
        .then((response) => {
            log(response);
        })
        .catch((error) => {
            log(error);
        });

        setUser(null);
        setUserToken('');
        setLoading(false);

        navigate(redirectTo, true);
    }

    const passwordReset = (data) => {
        setLoading(true);
        setPasswordResetResponse(false);
        setErrors([]);

        const config = {
            method: 'post',
            url: `${API_URL}password/email`,
            data
        };

        axios(config)
        .then((response) => {
            log(response);
            setPasswordResetResponse({sevirity: "success", message: response.data.message});
        })
        .catch((error) => {
            log(error);
            setErrors(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getAndUpdateUser = () => {
        const config = {
            method: 'get',
            url: `${API_URL}current-user`,
            headers: { 
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios(config)
        .then((response) => {
            log(response);
            const userb = response.data;
            log('userb');
            log(userb);
            updateUser(userb);
        })
        .catch((error) => {
            log(error);
        });
    }

    
    const getAndUpdateUserForApp = () => {
        const config = {
            method: 'get',
            url: `${API_URL}current-user`,
            headers: { 
                'Authorization': `Bearer ${searchParams.get('authCode')}`
            }
        };

        axios(config)
        .then((response) => {
            log(response);
            const userb = response.data;
            log('userb');
            log(userb);
            userb.token = searchParams.get('authCode');
            userb.authed = true;
            updateUser(userb);
        })
        .catch((error) => {
            log(error);
        });
    }

    const checkSubscription = () => {
        const config = {
            method: 'get',
            url: `${API_URL}artist/check-subscription`,
            headers: { 
                'Authorization': `Bearer ${searchParams.has('authCode') ? searchParams.get('authCode') : cookies.get('user')?.token || ''}`
            }
        };

        axios(config)
        .then((response) => {
            const userb = response.data;

            updateUser(userb);

            log("Subscriotion");
            log(response);
        })
        .catch((error) => {
            log(error);
        });
    }

    function updateUser(userb) {
        const updatedUser = {...userb, token: userb.token ? userb.token : user.token};

        setUser(updatedUser);
        setUserCookie(updatedUser);
        setUserToken(updatedUser.token);
    }

    function handleUnauthorized(err) {
        if (err.status === 401) {
            logout('/login');
        }
    }

    function isSubscribed() {
        log("status subscribed");
        log(user?.subscription?.status);
        return user?.subscription?.status === "active";
    }

    function hasRole(role) {
        return user?.role === role;
    }

    function isAuthed() {
        if (user) {
            return true;
        }  
        
        if (searchParams.has('authCode')) {
            getAndUpdateUserForApp();
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (searchParams.has('authCode')) {
            checkSubscription();
        }
    }, [])

    return <AuthContext.Provider value={{isApp, setSocialStats, socialStats, setPrevSocialStats, prevSocialStats, passwordResetResponse, passwordReset, getAndUpdateUser, registerFree, handleUnauthorized, updateUser, isSubscribed, checkSubscription, authedCheckout, setUser, artistLogin, user, login, errors, setErrors, logout, loading, userToken, registerWithCheckout, hasRole, isAuthed}}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
    