import {useState} from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { string } from 'prop-types';
import Iconify from '../../../../components/iconify';

Explaination.propTypes = {
  heading: string,
  text: string
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));


const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


export default function Explaination({heading, text}) {
  const [open, setOpen] = useState(false);

  return (
    <Accordion className='accordion-wrapper'>
      <AccordionSummary  className='accordion-summary bg-neutral'
       onClick={() => setOpen(!open)}
        expandIcon={open ? 
          <Iconify width={25} icon="ic:baseline-minus"/>
         : <Iconify width={25} icon="ic:baseline-plus" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails className='accordion-details'>
        <Typography textAlign="left">
          {text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}