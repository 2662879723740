import { useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import ReactFbq from 'react-fbq';
import { APP_NAME, TITLE_SEPERATOR } from "../../hooks/globals";
import ArtistThemeProvider from "../../theme/artist";
import { useAuth } from "../../auth/Authentication";

export default function ThankYou() {
    const auth = useAuth();

    const redirectTo = () => {
        window.location.replace('/artist/statistics');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const purchaseValue = urlParams.get('value');

    useEffect(() => {
        window.scrollTo(0, 0);
        auth.checkSubscription(false);

        ReactGA.event({
            category: 'conversion',
            action: 'conversion',
            send_to: 'AW-10937809005/PluGCLySk5kZEO3wxt8o',
            transaction_id: id || '',
        });

        ReactFbq.track({
            title: 'Purchase',
            data: {
                value: purchaseValue,
                currency: 'EUR'
            }
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        auth.checkSubscription(false);
    }, []);

    return (
        <>
            <Helmet>
                <title>Thank you {TITLE_SEPERATOR} {APP_NAME}</title>
            </Helmet>
            <ArtistThemeProvider>
                <Box sx={{height: '69.5vh', pt: 8}}>
                        <Typography variant="h1" textAlign="center">Thanks for your order</Typography>
                        <Button sx={{display: 'block', margin: 'auto', marginTop: '20px'}} variant="contained" onClick={() => redirectTo()}>Go to dashboard</Button>
                </Box>
            </ArtistThemeProvider>
        </>
    )
}