import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Typography, Grid, Box, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import StyledBox from '../../../theme/artist/overrides/StyledBox';
import platforms from './paltforms';
import OverviewGraph from './sections/OverviewGraph';
import SocialCard from './sections/PlatformCard';
import { useAuth } from '../../../auth/Authentication';
import useConsoleLog from '../../../hooks/useConsoleLog';
import useResponsive from '../../../hooks/useResponsive';
import { fShortenNumber } from '../../../utils/formatNumber';
import SubscriptionNotice from '../../../modals/SubscriptionNotice';

export default function SocialLayout() {
  const [activePlatform, setActivePlatform] = useState('');
  const { isApp } = useAuth();
  const location = useLocation();
  const url = location.pathname;
  const segments = url.split('/');
  const lastSegment = segments.pop();
  const auth = useAuth();
  const log = useConsoleLog;
  const isOnSmallScreen = useResponsive('down', 'md', 'lg');
  const [platformsGridHidden, setPlatformGridHidden] = useState(true);

  const availablePaths = ['facebook', 'youtube', 'twitter', 'instagram', 'tiktok'];

  const colWidth = availablePaths.includes(lastSegment) ? 2 : 2.4;
  const activeColWith = 2 * colWidth;

  const togglePlatformGrid = () => {
    setPlatformGridHidden(!platformsGridHidden);
  };

  useEffect(() => {
    if (isOnSmallScreen && lastSegment === 'home') {
      togglePlatformGrid();
    }
  }, [lastSegment]);
  console.log('lastSegment', lastSegment);
  if (
    auth.user?.subscription?.plan?.permissions?.includes('can-analyse-socials') &&
    auth.user?.subscription?.status !== 'cancelled'
  ) {
    return (
      <>
        <Box sx={{ display: 'flex', gap: '24px'}}>
          <Box sx={{ flexBasis: `calc(1/12 * 100%)`, display: { xs: 'none', lg: 'block' } }}/>

          <Box
            className={`social-media-container ${
              !platformsGridHidden ? 'social-media-container-active' : 'social-card-active'
            }`}
            sx={{ padding: { xs: '0 10px', md: '0 24px' }}}
          >

            {(!isOnSmallScreen || !platformsGridHidden) && (
                <>
                  <Box onClick={togglePlatformGrid} className={isOnSmallScreen && 'platforms-grid-overlay-wrapper'} />
                </>
            )}

            {isOnSmallScreen &&
              platforms.map((platform) => {
                if (platform.path === lastSegment) {
                  let theDiff = 0;

                  if (auth.prevSocialStats[platform.path]) {
                    if (auth.socialStats[platform.path] - auth.prevSocialStats[platform.path]) {
                      theDiff =
                        (auth.socialStats[platform.path] - auth.prevSocialStats[platform.path]) /
                        auth.prevSocialStats[platform.path];
                    }
                  }

                  return (
                    <Box
                      item
                      key={platform.name}
                      className={`${platformsGridHidden ? 'active-platform' : 'active-platforms'} ${
                        isOnSmallScreen && !platformsGridHidden && 'overlayed'
                      }`}
                    >
                      <SocialCard
                        key={platform.name}
                        name={platform.name}
                        icon={platform.icon}
                        dataType={platform.dataType}
                        amount={auth.socialStats[platform.path]}
                        active={platform.path === lastSegment}
                        diff={theDiff}
                        withMenu
                        onMenuClick={togglePlatformGrid}
                      />
                    </Box>
                  );
                }

                return null;
              })}

            {(!isOnSmallScreen || !platformsGridHidden) && (
              <Grid container spacing={3} className={`platforms-grid`}>
                {platforms.map((platform) => {
                  let theDiff = 0;

                  if (auth.prevSocialStats[platform.path]) {
                    if (auth.socialStats[platform.path] - auth.prevSocialStats[platform.path]) {
                      theDiff =
                        (auth.socialStats[platform.path] - auth.prevSocialStats[platform.path]) /
                        auth.prevSocialStats[platform.path];
                    }
                  }

                  if ((isOnSmallScreen && platform.path !== lastSegment) || !isOnSmallScreen) {
                    return (
                      <Grid
                        onClick={togglePlatformGrid}
                        item
                        xs={12}
                        md={platform.path === lastSegment ? activeColWith : colWidth}
                        key={platform.name}
                        className={platform.path === lastSegment && 'active-platform'}
                      >
                        <Link to={`/artist/social-media/${platform.path}`}>
                          <SocialCard
                            key={platform.name}
                            name={platform.name}
                            icon={platform.icon}
                            dataType={platform.dataType}
                            amount={auth.socialStats[platform.path]}
                            active={platform.path === lastSegment}
                            diff={theDiff}
                            isMenuItem
                            isDropDownItem={!platformsGridHidden}
                          />
                        </Link>
                      </Grid>
                    );
                  }

                  return null;
                })}
              </Grid>
            )}
            <Box className="stats-wrapper">
              <Outlet />
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (isApp) {
    return <SubscriptionNotice />;
  }

  return <Navigate to={'/checkout'} />;
}
