import PropTypes from "prop-types";
import { useState } from "react";
import { Stack, Box, Drawer, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Iconify from "../../../components/iconify/Iconify";
import { useAuth } from "../../../auth/Authentication";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";
import { useColorMode } from "../../../theme/artist/ColorModeContext";
import SubMenu from "./SubMenu";
import useCheckMatchingArrayValues from "../../../hooks/useCheckMatchingArrayValues";
import LockedMenuItem from "./LockedMenuItem";

ResponsiveNav.propTypes = {
    pages: PropTypes.array,
    openNavMenu: PropTypes.func,
    anchorElNav: PropTypes.any,
    closeNavMenu: PropTypes.func,
}

export default function ResponsiveNav({pages, openNavMenu, anchorElNav, closeNavMenu}) {
  const theme = useTheme();
  const auth = useAuth();
  const colorMode = useColorMode();
  const [showSubMenu, setShowSubMenu] = useState();
  const checkMatchingPermissions = useCheckMatchingArrayValues();
    return (
          
          <Box sx={{ position: "absolute", right: 0, color: theme.palette.common.black, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={colorMode.toggleDarkMode}>{colorMode.darkMode ? <Iconify icon={'icon-park-solid:dark-mode'}/> : <Iconify icon={'ic:sharp-light-mode'}/>}</IconButton>
            <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openNavMenu}
              sx={{padding: 0}}
            >
              <Iconify icon="charm:menu-hamburger" width={30} sx={{color: "#707070"}}/>
            </IconButton>
            <Drawer
            className={`mobile-menu ${colorMode.darkMode ? 'dark-mode' : ''}`}
            anchor="right"
            open={Boolean(anchorElNav)}
            onClose={closeNavMenu}
            PaperProps={{sx: {
            width: "80%", 
            backgroundColor: theme.palette.platform.spotify,
            zIndex: 12001
          }}}
            >
              <Stack gap={5} sx={{marginTop: "20px"}} alignItems="flex-end">
                <IconButton onClick={closeNavMenu} sx={{color: theme.palette.common.white}}>
                  <Iconify width="22px" icon="akar-icons:cross"/>
                </IconButton>
                {pages.map((page, i) => {
                  if (page.roles?.includes(auth.user?.role) || !page.roles) {

                  if ( 
                    (!page.subscribed || (page.subscribed && auth.isSubscribed())) 
                      && (!page.permissions || (auth.user?.subscription?.plan?.permissions
                      && checkMatchingPermissions(auth.user?.subscription?.plan?.permissions, page.permissions)))
                    )  {
                    return (
                      page.path !== null ?
                        <Link to={page.path} key={page.title} style={{textAlign: "right", color: theme.palette.common.white}}>
                          <MenuItem sx={{display: "block", marginLeft: "auto"}} key={page.title} onClick={closeNavMenu}>
                            <Typography textAlign="right" sx={{fontSize: "30px", color: theme.palette.common.white}}>{page.title}</Typography>
                          </MenuItem>
                        </Link>
                      :
                        <MenuItem className="responsive-menu-wrapper" sx={{display: "block", marginLeft: "auto"}} key={page.title} onClick={() => {if (showSubMenu === i) { setShowSubMenu(null) } else { setShowSubMenu(i) }}}>
                          <Stack direction="row" alignItems="center" spacing={3}>
                            <Typography textAlign="right" sx={{fontSize: "30px", color: theme.palette.common.white}}>{page.title}</Typography>
                            {page.subMenu !== null &&
                            <Box className="mt-2 contrast-neutral-bg-r">
                              <Iconify icon="pepicons-pop:angle-down"/>
                            </Box>
                            }
                          </Stack>
                          {page.subMenu !== null && showSubMenu === i &&
                            <Box onClick={closeNavMenu}>
                              <SubMenu config={page.subMenu}/>
                            </Box>
                          }
                        </MenuItem>
                    )
                  }

                    return <LockedMenuItem
                      page={page}
                      onCloseNavMenu={closeNavMenu}
                      toolTipTitle={page.availableAt}
                    />;
                  }

                  return false;
                })}
                {auth.user &&
                  <>
                    <Link to={'/my-account'} style={{textAlign: "right", color: theme.palette.common.white}}>
                      <MenuItem sx={{display: "block", marginLeft: "auto"}} onClick={closeNavMenu}>
                        <Typography textAlign="right" sx={{fontSize: "30px", color: theme.palette.common.white}}>My account</Typography>
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={() => auth.logout('/login')} sx={{display: "block", marginLeft: "auto"}}>
                      <Typography textAlign="right" sx={{fontSize: "30px", color: theme.palette.common.white}}>Logout</Typography>
                    </MenuItem>
                  </>
                }
              </Stack>
            </Drawer>
          </>
        </Box>
      );
}