import { useState, useEffect } from "react";
import { array, bool, func, string } from "prop-types";
import { Stack, Box, Button, TextField, Grid, Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import useResponsive from "../../../../hooks/useResponsive";
import useConsoleLog from "../../../../hooks/useConsoleLog";

Switchers.propTypes = {
  onChangeStartDate: func,
  onChangeEndDate: func,
  onSetDefaultOverview: func,
  theLabels: array,
  theSubs: array,
  theViews: array,
  theStartDate: string,
  theEndDate: string,
  theDefaultOverview: bool,
  firstStat: string,
  secondStat: string,
  justice: string
}

export default function Switchers({
  onChangeStartDate, 
  onChangeEndDate, 
  theLabels,
  theSubs, 
  theViews,
  theStartDate,
  theEndDate,
  justice
}) {
  const isOnSmallerScreen = useResponsive("down", "sm", "lg");

  useEffect(() => {
    // Log theSubs when the component initializes
    console.log("theSubs on component mount:", theSubs);
  }, [theSubs]); // Empty dependency array to run the effect only once

  return (
    <>
      <Grid container spacing={2} sx={{ paddingLeft: '1rem' }}>
        <Grid item>
          <Box className="start-date date">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
                <DatePicker
                label="Start date"
                value={theStartDate}
                onChange={(newValue) => {
                    if (new Date(newValue) < new Date(theEndDate)) {
                      onChangeStartDate(new Date(newValue), theLabels, theSubs[0].data, theViews[0].data);
                    }
                }}
                renderInput={(params) => <TextField sx={{width: isOnSmallerScreen ? '100%' : 'auto'}} {...params} />}
                />
            </LocalizationProvider>
          </Box>
        </Grid>

        <Grid item>
          <Box className="end-date date">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
              <DatePicker
              label="End date"
              value={theEndDate}
              onChange={(newValue) => {
                  if (new Date(newValue) > new Date(theStartDate)) {
                    onChangeEndDate(new Date(newValue), theLabels, theSubs[0].data, theViews[0].data);
                  }
              }}
              renderInput={(params) => <TextField
                  sx={{marginLeft: !isOnSmallerScreen && 2, marginTop: isOnSmallerScreen && 2, width: isOnSmallerScreen ? '100%' : 'auto'}} {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}