// ----------------------------------------------------------------------

import { useTheme } from '@mui/material';

export default function Checkbox() {
  const theme = useTheme();

  return {  
    MuiCheckbox: {
      styleOverrides: {
          color: "#9d9d9d!important"
      }
    }
  };
}
