import { Stack } from "@mui/material";
import platforms from "../../paltforms";
import RegisterSocialAccordion from "./Accordion";

export default function AccountsAccordions() {
  return (
    <Stack spacing={2} className="social-accordion-stack">
      {platforms.map((platform) => (
          <RegisterSocialAccordion
            icon={platform.icon}
            name={platform.name}
            idName={platform.idName}
          />
        ))}
      </Stack>
    )
}