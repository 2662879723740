import { Select } from "@mui/material";
import styled from '@emotion/styled';


const StyledSelect = styled(Select)(({sx, theme}) => ({
    "& fieldset": {border: "none"},
    "& div": {padding: "12px"},
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    backgroundColor: theme.palette.common.litUp,
    borderRadius: "5px",
    paddingRight: "10px",
    color: theme.palette.common.black,
    border: `0px solid ${theme.palette.common.litUp}`,
    menuProps: {
        paper: { sx: {backgroundColor: theme.palette.common.litUp} } 
    },
    ...sx
}));

export default StyledSelect;