import { React, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { TextField, Grid, Switch, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Alert, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormHandler } from '../hooks/useFormHandler';
import Iconify from '../components/iconify';
// components



export default function CouponForm() {
    const fHandler = useFormHandler(`admin/coupons`);

    return (
    <Grid container gap={3}>
        <Grid item md={12}>
            <TextField fullWidth name="code" label="Code" value={fHandler.formValues?.code} onChange={fHandler.handleValChange} error={fHandler.errors?.code} helperText={fHandler.errors?.code} />
        </Grid>

        <Grid item md={12}>
            <LoadingButton sx={{height: 56, border: 'none'}} fullWidth size="large" type="submit" variant="contained" onClick={() => fHandler.handleSubmit()} loading={fHandler.isSubmitting}>
                Submit
            </LoadingButton>
        </Grid>
    </Grid>
    )
}