import { Modal, Box } from "@mui/material"

export default function CheckoutModal({onOpen, checkoutUrl, onHandleClose}) {
  return (
    <Modal 
      open={onOpen}
      onClose={onHandleClose}
      className="checkout-modal"
    >
      <Box className="checkout-modal-content"
        onClick={onHandleClose}
      >
        <iframe src={checkoutUrl} width="365px" height="90%" title="checkout-iframe"/>
      </Box>
    </Modal>
  )
}