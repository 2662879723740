import { useTheme, Box, Typography } from "@mui/material";
import Amount from "./Amount";

export default function ImagesLeft() {
  
  return (
    <Box className="ai-credits-wrapper">
      <Typography variant="h4">Generated images left</Typography>
      <Amount/>
    </Box>
  )
}