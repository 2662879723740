import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { Alert, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { ComposableMap, ZoomableGroup, projection } from "react-simple-maps";
import Iconify from "../../../components/iconify/Iconify";
import { useAuth } from "../../../auth/Authentication";
import SpotifyHeatMap from "../stats-components/heatmaps/SpotifyHeatMap";
import Arrow from "../../../components/arrow-bg/Arrow";
import YouTubeHeatMap from "../stats-components/heatmaps/YouTubeHeatMap";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import TiktokHeatMap from "../stats-components/TiktokeatMap";

export default function Audience(props) {
    const theme = useTheme();
    const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  
    const handleZoomIn = () => {
      if (position.zoom >= 8) return;
      setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.5 }));
    }
  
    const handleZoomOut = () => {
      if (position.zoom <= 1) return;
      setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.5 }));
    }
  
    const handleMoveEnd = (position) => {
      setPosition(position);
    }

    const platformsWitoutAudiences = ["amazon", "shazam", "itunes", "deezer", "apple_music", "instagram", "soundcloud", "beatport", "traxsource", "tidal", "tracklist", "tiktok"]


    return (
        <Stack gap={3} position="relative">
            <Grid container justifyContent="space-between">
                <Typography textAlign="left" variant="h4"  sx={{fontSize: "30px", paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>
                    <Stack alignItems={"center"} spacing={2} direction="row">
                        <span>Your audience</span>
                    </Stack>
                </Typography>
                {props.orderArrows}
            </Grid>
            <Arrow platformId={props.platformId}/>
            <StyledPaper
                className={`your-audience`}
                sx={{position: "relative", borderRadius: {md: "10px", xs: "0"}, backgroundColor: theme.palette.grey[200], "&:before": {backgroundColor: {xs: "transparent", md: props.platformColor} }, "&:after": { backgroundColor: {xs: "transparent", md: props.platformColor} }}}>
                     {
                        
                    platformsWitoutAudiences.includes(props.platformId) ?
                    <Typography className="no-data">
                        Heatmap is not supported for this platform.
                    </Typography> 
                    :
                        ((props.platformId === "youtube" && !props.data?.views?.length) || 
                        (props.platformId === "soptify" && !props.data?.monthly_listeners?.length))
                        ? 
                        <Typography className="no-data">
                            No data found
                        </Typography> 
                        :
                        <Stack spacing={2}>
                            <Grid container className="controls" justifyContent="end">
                                <IconButton onClick={handleZoomOut} sx={{color: props.platformColor}}>
                                    <Iconify icon="ic:baseline-minus"/>
                                </IconButton>
                                <IconButton onClick={handleZoomIn} sx={{color: props.platformColor}}>
                                    <Iconify icon="material-symbols:add"/>
                                </IconButton>
                            </Grid>
                            <Box>
                                <ComposableMap 
                                height={494}
                                projectionConfig={{ rotate: [-10, 0, 0] }}>      
                                    <ZoomableGroup
                                    maxZoom={position.zoom}
                                    minZoom={position.zoom}
                                    zoom={position.zoom}
                                    center={position.coordinates}
                                    onMoveEnd={(position) => handleMoveEnd(position)}>
                                        {props.platformId === "spotify" && <SpotifyHeatMap data={props.mapData}/>}
                                        {props.platformId === "youtube" && <YouTubeHeatMap data={props.mapData}/>}
                                        {props.platformId === "tiktok" && <TiktokHeatMap data={props.mapData}/>}
                                    </ZoomableGroup>
                                </ComposableMap>
                            </Box>
                        </Stack>
                    }

            </StyledPaper>
        </Stack>
    )
}