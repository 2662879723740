import { Grid, Typography, Box } from "@mui/material";
import Example from "./Example";
import examples from "./config";
import useResponsive from "../../../../hooks/useResponsive";


export default function Examples() {
    const isOnSmallScreen = useResponsive("down", "md", "lg");

    return (
    <Box className="examples-wrapper">
      <Typography variant="h4" className="examples-title">
        Examples
      </Typography>
      <Grid container spacing={1} className="examples">
          {examples.map(({img, text}) => (
            <Grid item md={4} xs={6}>
              <Example imgSrc={img} text={text}/>
            </Grid>
          ))}
        </Grid>
    </Box>
  )
}