const examples = [
  {
    img: "bearo",
    text: `“A bear playing a flute”`,
  },
  {
    img: "musician",
    text: `“A high quality photo of a crowd in a small venue”`,
  },
  {
    img: "birds",
    text: `“An abstract painting of birds flying over the sea”`,
  },
  {
    img: "mars",
    text: `“A photo of an alien music group that is performing on mars”`,
  },
  {
    img: "cat",
    text: `“An oil painting of a cat napping in the sunlight”`,
  },
  {
    img: "office",
    text: `“A painting of people working in an office at night”`,
  },
]

export default examples;