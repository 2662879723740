import React, { useEffect, useState, useMemo } from "react";
import { useTheme } from "@emotion/react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  useZoomPanContext,
  ZoomableGroup
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { Tooltip } from "@mui/material";
import useConsoleLog from "../../../hooks/useConsoleLog";

const geoUrl = "/assets/topo.json";

export default function TiktokHeatMap(props) {

  const log = useConsoleLog;
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const theme = useTheme();
  const maxScale = 10;

  useEffect(() => {
    if (props.data?.creator_reach_total?.length) {
        // set monthly views
        const sortedCities = sortBy(props.data.creator_reach_total, (o) => -o.creator_reach_total);
        setMaxValue(sortedCities[0].creator_reach_total);
        setData(sortedCities);
        log("props.data")
        log(props.data)
    }
  }, [props.data]);

  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0, 24]),
    [maxValue]
  );

  return (
        <>
          <Geographies 
          geography={geoUrl}
          >
              {({ geographies }) =>
                  geographies.map((geo) => (
                      <Geography 
                      key={geo.rsmKey} 
                      geography={geo} 
                      fill={theme.palette.info.main} 
                      />
                  ))
              }
          </Geographies>
          {data.map((city) => {
              return (
              <Tooltip title={`${city.city_name} | Creator reach total: ${city.creator_reach_total}` }>
                  <Marker key={city.city_name} coordinates={[city.city_lng, city.city_lat]}>
                      <circle fill={popScale(city.creator_reach_total) > maxScale ? "#000" : theme.palette.platform.youtube} style={{opacity: ".46"}} r={Math.min(popScale(city.creator_reach_total), maxScale)} />
                  </Marker>
              </Tooltip>
              );
          })}
        </>
  );
};

