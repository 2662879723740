// ----------------------------------------------------------------------

import { useTheme } from '@mui/material';

export default function Paper(props) {
  const theme = useTheme();

  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: theme.palette.common.litUp,
          padding: '20px',
          boxSizing: 'border-box',
          py: 5,
          px: 0,
          boxShadow: "0px 3px 5px rgba(0,0,0,0.16 )",
          textAlign: 'center',
          borderRadius: {md: "10px", xs: 0},
          "&:before": {
              content: '""',
              display: "block",
              height: "78%",
              width: "4px",
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: props.platformColor
          },
          "&:after": {
              content: '""',
              display: "block",
              height: "4px",
              width: "78%",
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: props.platformColor
          },
        },
      },
    },
  };
}
