import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Stack, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Grid, Button } from '@mui/material';
// component
import { useTheme } from '@emotion/react';
import { useDelete } from '../../../hooks/useDelete';
import { useModal } from '../../../modals/ModalContext';
import Iconify from '../../../components/iconify';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: "112px!important", 
  paddingLeft: "112px!important"
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 489,
  border: "1px solid #eaeaea",
  borderRadius: "10px",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  '&:placeholder': {
    color: "#acacac"
  }
}));

// ----------------------------------------------------------------------

ArtistItemListToolbar.propTypes = {
  locationType: PropTypes.string,
  onHandleLocationType: PropTypes.func,
  platformColor: PropTypes.string,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  countryOnly: PropTypes.bool
};

export default function ArtistItemListToolbar({ onHandleLocationType, locationType, platformColor, filterName, onFilterName, countryOnly }) {
  const theme = useTheme();
  
  const switchStyles = {
    fontSize: "14px",
    fontWeight: "400",
    height: "28px",
    color: "#000",
    borderRadius: "14px"
  }

  const isOnSmallScreen = useResponsive("down", "md", "lg");

  return (
    <StyledRoot sx={{paddingLeft: {xs: "0!important", md: "112px!important"}, paddingRight: {xs: "0!important", md: "112px!important"} }}
    >
      <Grid container alignItems={"center"}>
        {!countryOnly &&
        <Grid item xs={12} md={1}>
          <Grid container justifyContent={"space-between"} alignItems="center">
            {isOnSmallScreen && <Typography sx={{fontSize: "20px", fontWeight: "bold", color: theme.palette.common.black}}>Location</Typography> }
            <Stack direction="row" sx={{backgroundColor: "#D8D8D8", borderRadius: "14px"}}>
              <Button onClick={() => onHandleLocationType("country")} sx={{...switchStyles, backgroundColor: locationType === "country" && platformColor}}>Country</Button>
              <Button onClick={() => onHandleLocationType("city")} sx={{...switchStyles, backgroundColor: locationType === "city" && platformColor}}>City</Button>
            </Stack>
          </Grid>
        </Grid>
        }
        { 
          !isOnSmallScreen &&
          <Grid item sx={{marginLeft: "176px"}}>
              <StyledSearch
                value={filterName}
                onChange={onFilterName}
                placeholder={`Search`}
                startAdornment={
                  <InputAdornment position="end" sx={{color: "#acacac"}}>
                    <Iconify icon="eva:search-fill" sx={{ color: '#acacac', width: 27, height: 27 }} />
                  </InputAdornment>
                }
              />
          </Grid> 
        }
      </Grid>
    </StyledRoot>
  );
}
