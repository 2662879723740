const platforms = [
  {
    name: "Instagram",
    icon: "insta",
    dataType: "Followers",
    path: "instagram",
    idName: "instagram"
  },
  {
    name: "Facebook",
    icon: "fb",
    dataType: "Likes",
    path: "facebook",
    idName: "facebook"
  },
  {
    name: "TikTok",
    icon: "tt",
    dataType: "Followers",
    path: "tiktok",
    idName: "tiktok"
  },
  {
    name: "YouTube",
    icon: "yt",
    dataType: "Subscribers",
    path: "youtube",
    idName: "youtube"
  },
  {
    name: "Twitter",
    icon: "tw",
    dataType: "Followers",
    path: "twitter",
    idName: "twitter"
  },
]

export default platforms;