import { useEffect, useRef, useState, useMemo } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import { Button, Grid, Paper, Typography, Box, TextField, Alert, Container } from "@mui/material";
import { useAuth } from "../../../auth/Authentication";
import { API_URL } from "../../../hooks/globals";
import useGetSkeletons from "../../../hooks/useGetSkeletons";
import useResponsive from "../../../hooks/useResponsive";
import useConsoleLog from "../../../hooks/useConsoleLog";

export default function ArtistCatalog() {
    const [catalog, setCatalog] = useState([]);
    const [origCatalog, setOrigCatalog] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const auth = useAuth();
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [catalogSearchq, setCatalogSearchq] = useState('');
    const [getSkeletons] = useGetSkeletons();
    const isOnSmallerScreen = useResponsive("down", "sm", "lg");
    const [fetchedImages, setFetchedImages] = useState();
    const log = useConsoleLog;

    const getArtistCatalog = (offset = 0) => {
      log(offset);
      const url = `${API_URL}artist/artist-catalog`;
      const userToken = auth.userToken;
  
      const config = {
          method: 'get',
          url,
          headers: { 
              'Authorization': `Bearer ${userToken}`
          }
      };
      axios(config).then((response) => {
        const newItems = response.data.links;

        setIsLoading(true);

        const catalogb = [...catalog, ...newItems];
        log('got artist catalog');
        log(newItems);
        setCatalog(catalogb);
        setOrigCatalog(catalogb);
        
        setPageNumber(pageNumber => pageNumber + 1);
      }).catch((error) => {
          log(error);
  
      }).finally(() => {
        setIsLoading(false);  
      });  
    }


    const getErrorImage = (track, index) => {
        const catalogb = [...catalog];
        track.avatar = '/assets/placeholders/track-image.png';
        catalogb.splice(index, 1, track)

        setCatalog(catalogb)
    }

    const catalogSearch = (e) => {
        const newVal = e.target.value;
        setCatalogSearchq(newVal)

        if (newVal) {
            const results = catalog.filter((item) =>
                item.title.toLowerCase().includes(newVal.toLowerCase())
            );
            setCatalog(results);
        } else {
            setCatalog(origCatalog);
        }
    }

    useEffect(() => {
        log(window.scrollYOffset);
        getArtistCatalog();
    }, []);

    return (
        <Box container sx={{paddingX: {md: '24px', xs: 0}, width: '100%'}}>
            <Paper className="mb-2">
                <Stack gap={3}>
                    <Typography variant='h4'>
                        Your tracks
                    </Typography>
                    <Grid Container justifyContent="center">
                        <TextField className="catalog-search" label="Search tracks" name="catalogSearch" value={catalogSearchq} onChange={catalogSearch}/>
                    </Grid>
                    <Grid container spacing={3}>
                        {
                        !catalog?.length && !isLoading ?
                        <Grid item md={12} className="text-center"> 
                                <Typography textAlign={'center'}>No tracks found for your query</Typography>
                        </Grid>
                        :
                        catalog  ?
                        catalog.map((track, index) => (
                            <Grid item xs={6} md={3} key={track.songstats_track_id}>
                                <Link sx={{height: "100%"}} key={`link-${track.songstats_track_id}`} to={`/artist/statistics/track/${track.songstats_track_id}`}>
                                    <Stack gap={3}>
                                        <LazyLoadImage
                                        style={{objecFit: "cover", borderRadius: "5px"}}
                                        effect="blur"
                                        alt={track.title}
                                        height={isOnSmallerScreen ? '200px' : '300px'}
                                        width="100%"
                                        src={track.avatar || '/assets/placeholders/track-image.png'}
                                        onError={() => {getErrorImage(track, index)}}
                                        />
                                        <Typography variant='h5'>
                                            {track.title}
                                        </Typography>
                                    </Stack>
                                </Link>
                            </Grid>
                        ))
                        :
                            getSkeletons(16, '100%', 424).map((skeleton) => (
                                <Grid item md={3}>
                                    {skeleton}
                                </Grid>
                            ))
                        }
                        {
                        isLoading && 
                        getSkeletons(16, '100%', 424).map((skeleton) => (
                            <Grid item md={3}>
                                {skeleton}
                            </Grid>
                        ))
                        }
                    </Grid>
                </Stack>
            </Paper>
        </Box>
    )
}