import axios from "axios";
import Cookies from "universal-cookie";
import { API_URL } from "../hooks/globals";
import { useAuth } from "../auth/Authentication";


export default function useGetListing () {
    const auth = useAuth();
    
    const getListing = (path) => {
        const url = `${API_URL}${path}`;

        const config = {
            method: 'get',
            url,
            headers: { 
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        return axios(config);
    }

    return {getListing};
}

    