import PropTypes from 'prop-types';
import { useMemo, useEffect } from 'react';
// @mui
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import ReactFbq from 'react-fbq';
//
import palette from './palette';
import paletteDark from './paletteDark';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import Tooltip from './overrides/Tooltip';
import Button from './overrides/Button';
import Checkbox from './overrides/Checkbox';
import { useColorMode } from './ColorModeContext';
// ----------------------------------------------------------------------

ArtistThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ArtistThemeProvider({ children }) {
  const colorMode = useColorMode();


  function getPalette() {
    if (colorMode.darkMode) {
      return paletteDark;
    }

    return palette;
  }

  const themeOptions = useMemo(
    () => ({
      palette: getPalette(),
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      components: {
        Button,
        Tooltip,
        Checkbox
      }
    }),
    [colorMode.darkMode]
  );

  ReactFbq.initialize({ id: '913255773933883' });
  ReactFbq.pageView();

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
      <Box className={`main-view ${colorMode.darkMode && 'dark-mode'}`}>
          {children}
        </Box>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
