export default function useSensableWord() {
    const sensableWord = (word) => {
        const words = word.split("_"); // split the string into an array of words
        const reversedWords = words.reverse(); // reverse the order of the words
        const capitalizedWords = reversedWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)); // capitalize the first letter of each word
        const result = capitalizedWords.join(" "); // join the words back into a single string, without underscores
        
        return result; // "CurrentListenersMonthly"
    }

    return {sensableWord};
}