import { Alert, Typography } from "@mui/material";
import { useState } from "react";
import usePost from "../../../../api/usePost"
import { useAuth } from "../../../../auth/Authentication";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";

export default function CancelCancellation({onSetCancelSuccessMsg}) {
  const post = usePost();
  const log = useConsoleLog;
  const auth = useAuth();
  const [successMsg, setSuccessMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const removeCancellation = () => {
    setIsLoading(true);
    onSetCancelSuccessMsg("");

    post("artist/cancel-cancellation", {})
      .then((res) => {
        auth.checkSubscription();
        log(res);
        setSuccessMsg("Successfully cancelled your cancellation!");
      })
      .catch((err) => {
        log(err);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Typography>
        You cancelled your subscription, it will officially end at {auth.user.subscription.cancelledAt}
      </Typography>
      <StyledLoadingButton className="mt-2" loading={isLoading} onClick={removeCancellation}>
        Stop cancellation
      </StyledLoadingButton>
      {successMsg && <Alert className="mt-2" severity="success">{successMsg}</Alert>}
    </>
  )
}