import { Box, styled } from "@mui/material";



const StyledBox = styled(Box)(({sx, theme}) => ({
    [theme.breakpoints.up("xs")]: {
        width: "98%"
    },
    [theme.breakpoints.up("md")]: {
        width: "90%"
    },
    maxWidth: "1620px", 
    margin: "auto",
    padding: "10px", 
    paddingLeft: "10px",
    paddingRight: "10px",
    ...sx
}));

export default StyledBox;