export default function useCheckMatchingArrayValues () {
    return (arr1, arr2) => {  // Iterate over each element in the first array
      for (let i = 0; i < arr1.length; i+=1) {
        // Check if the current element in arr1 matches any element in arr2
        const foundMatch = arr2.some(item => item === arr1[i]);
    
        // If a match is found, return true
        if (foundMatch) {
          return true;
        }
      }
    
      // No matches found
      return false;
    }
}

    