import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import 'dayjs/locale/nl';
import { Alert, Box, Button, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import useGetListing from "../../../api/useGetListing";
import useConsoleLog from "../../../hooks/useConsoleLog";
import useGetFormattedDate, { useGetFormattedTextDate } from "../../../hooks/useGetFormattedDate";
import { fShortenNumber } from "../../../utils/formatNumber";
import InvoiceListingLayout from "../auth/my-account/invoices/InvoiceListingLayout";
import OverviewGraph from "./sections/OverviewGraph";
import StatRankCard from "./sections/StatRankCard";
import Switchers from "./sections/Switchers";
import SwitchersTwo from "./sections/SwitchersTwo";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import Diff from "./sections/Diff";
import { useAuth } from "../../../auth/Authentication";
import AddIdForm from "../forms/AddIdForm";
import RegisterSocialAccordion from "./sections/register-accounts/Accordion";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import Arrow from "../../../components/arrow-bg/Arrow";

function InsufficientInfo() {
  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography className="badge">
            Things to note
          </Typography>
          <Typography>
            <br/>
            <br/>
            Before linking your Twitter account to your personal dashboard, there are a couple of things to take note of. 
            <br/>
            <br/>
            2) If this is your first time tracking your data through our dashboard, it could take a couple of days for your data to show up in your dashboard 
            <br/>
            <br/>
            3) Below you can add you social media account. Please make sure that you add the correct account. An account can only be added once.
            <br/>
            <br/>
          </Typography>
          <RegisterSocialAccordion
              icon={"tw"}
              name={"Twitter"}
              idName={"twitter"}
            />
        </Grid>
      </Grid>
    </>
  )
}

export default function Twitter() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [followers, setfollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [defaultOverview, setDefaultOverview] = useState(true);
  const [changablefollowers, setChangablefollowers] = useState([]);
  const [changableFavorites, setChangableFavorites] = useState([]);
  const [changableFollowing, setChangableFollowing] = useState([]);
  const [currentFollowers, setCurrentfollowers] = useState(0);
  const [prevFollowers, setPrevfollowers] = useState(0);
  const [labelsMonth, setLabelsMonth] = useState([]);
  const [currentFollowing, setCurrentFollowing] = useState(0);
  const [currentFavorites, setCurrentFavorites] = useState(0);
  const [prevFavorites, setPrevFavorites] = useState(0);
  const [currentTweets, setCurrentTweets] = useState(0);
  const [prevTweets, setPrevTweets] = useState(0);
  const [prevFollowing, setPrevFollowing] = useState(0);
  const [prevMedia, setPrevMedia] = useState(0);
  const [prevAverageFavorites, setPrevAverageFavorites] = useState(0);
  const [currentGrowthStat, setCurrentGrowthStat] = useState("Followers");
  const [followersMonth, setfollowersMonth] = useState([]);
  const [gainMonth, setGainMonth] = useState([]);
  const [followersGainPerMonth, setFollowersGainPerMonth] = useState([]);
  const [tweetsGainPerMonth, setTweetsGainPerMonth] = useState([]);
  const [ranks, setRanks] = useState({
    followers: 0,
    following: 0,
    media: 0,
    engagement_rate: 0,
  });
  const [labels, setLabels] = useState([]);
  const [labelsChangable, setLabelsChangable] = useState([]);
  const {getListing} = useGetListing();
  const log = useConsoleLog;
  const getFormattedDate = useGetFormattedDate();
  const formatDate = useGetFormattedTextDate();
  const firstStartDate = new Date();
  firstStartDate.setDate(firstStartDate.getDate() - 30);
  const lastEndDate = new Date();
  firstStartDate.setDate(firstStartDate.getDate() - 30);
  lastEndDate.setDate(lastEndDate.getDate() - 1);
  const [startDate, setStartDate] = useState(getFormattedDate(firstStartDate));
  const [endDate, setEndDate] = useState(getFormattedDate(lastEndDate));
  const auth = useAuth();

  const platform = 'twitter';
  document.documentElement.style.setProperty('--custom-border-color', theme.palette.platform[platform]);

  const changefollowers = (amounts) => {
    setChangablefollowers([{
      name: "Followers",
      data: amounts,
      type: 'area',
      fill: 'gradient',
      stroke: {
        curve: 'smooth',
      }
    }]);
  }

  const changeFavorites = (amounts) => {
    setChangableFavorites([{
      name: "Favorites",
      data: amounts,
      type: 'area',
      fill: 'gradient',
      stroke: {
        curve: 'smooth',
      }
    }]);
  }

  const changeStartDate = (value, theLables, thefollowers, theFavorites) => {
    log(thefollowers);
    const startDateb = getFormattedDate(new Date(value));
    setStartDate(startDateb);
    const start = theLables.indexOf(startDateb);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedfollowers = thefollowers.slice(end, start);
    const changedFavorites = theFavorites.slice(end, start);
    changefollowers(changedfollowers);
    changeFavorites(changedFavorites);
    setLabelsChangable(changedLabels);
  }

  const changeEndDate = (value, theLables, thefollowers, theFavorites) => {
    log(thefollowers);
    const endDateb = getFormattedDate(new Date(value));
    setEndDate(endDateb);
    const start = theLables.indexOf(startDate);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedfollowers = thefollowers.slice(end, start);
    const changedFavorites = theFavorites.slice(end, start);
    changefollowers(changedfollowers);
    changeFavorites(changedFavorites);
    setLabelsChangable(changedLabels);
  }

  const fetchPlatformData = () => {
    getListing(`artist/social-media/twitter/${auth.user.twitter_id}`)
    .then((res) => {
      log("res");
      log(res);
      const datab = res.data.data.daily;
      const dataWithId = datab.map((item, key) => {
        item = {
          id: key,
          ...item,
          date: formatDate(item.date),
        };

        return item
      });
      const slicedData = dataWithId.slice(0, 30);
      log("slicedData");
      log(slicedData);

      if (slicedData.length >= 30) {
        setData(slicedData.map((theData, index) => {
          let followerDiff = 0;
          let followerDiffClass = 'neutral';
          let postDiff = 0;
          let postDiffClass = 'neutral';

          if (index + 1 < 30) {
            followerDiff = theData.followers - slicedData[index+1].followers;
            followerDiff = ` ${followerDiff >-1 ? "+" : "-"}${Math.abs(followerDiff)}`;
            followerDiffClass = followerDiff >-1 ? "positive" : "negative";

            postDiff = theData.tweets - slicedData[index+1].tweets;
            postDiff = ` ${postDiff >-1 ? "+" : "-"}${Math.abs(postDiff)}`
            postDiffClass = postDiff >-1 ? "positive" : "negative";
          }

          return {
            id: theData.id,
            date: theData.date,
            followers: <Diff
                          classy={followerDiffClass}
                          content={theData.followers ? theData.followers : 0}
                          diff={followerDiff}
                        />,
            posts: <Diff
                      classy={postDiffClass}
                      content={theData.tweets ? theData.tweets : 0}
                      diff={postDiff}
                    />  
          }
        }));
      }

      log("dataWithId");
      log(dataWithId);

      const followersb = datab.map((item) => (item.followers));
      const favoritesb = datab.map((item) => {return item.favorites ? item.favorites : 0 });
      const tweetsb = datab.map((item) => (item.tweets));
      const followingb = datab.map((item) => (item.following));

      const followersbOneMonth = followersb.slice(0, 30);
      const followersbTwoMonths = followersb.slice(0, 60);

      const followingOneMonth = followingb.slice(0, 30);
      const followingTwoMonths = followingb.slice(0, 60);

      const tweetsOneMonth = tweetsb.slice(0, 30);
      const tweetsTwoMonths = tweetsb.slice(0, 60);

      const followersbGainMonth = followersbOneMonth.map((item, key) => {
        return followersbOneMonth[key] - followersbTwoMonths[key];
      });

      const followingGainMonth = followingOneMonth.map((item, key) => {
        return followingOneMonth[key] - followingTwoMonths[key];
      });

      const tweetsGainMonth = tweetsOneMonth.map((item, key) => {
        return tweetsOneMonth[key] - tweetsTwoMonths[key];
      });
      
      const followersGainPerMonthDays = followersb.map((item, key) => {
        if (key-30 > 0) {
          return followersb[key] - followersb[key-30] + 0;
        }

        return 0;
      });

      const tweetsGainPerMonthDays = tweetsb.map((item, key) => {
        if (key-30 > 0) {
          return tweetsb[key] - tweetsb[key-30] + 0;
        }

        return 0;
      });
      
      const monthCount = Math.floor(followersb.length / 30); // Get the total number of complete months in the array
      const followersGainPerMonth = []

      for (let i = 0; i < monthCount; i+=1) {
        const startIndex = i * 30; // Start index of each month
        const endIndex = (i + 1) * 30; // End index of each month

        const startSubs = followersb[startIndex];
        const endSubs = followersb[endIndex - 1];
        const change = startSubs - endSubs;

        followersGainPerMonth.push(change);
      }


      changefollowers(followersb);
      
      log("changedfollowers");
      log(followersb);
      
      setfollowers([{
        name: "followers",
        data: followersb,
        type: 'area',
        fill: 'gradient',
        stroke: {
          curve: 'smooth',
        }
      }]);

      changeFavorites(followersb);
      
      setFavorites([{
        name: "Favorites",
        data: favoritesb,
        type: 'area',
        fill: 'gradient',
        stroke: {
          curve: 'smooth',
        }
      }]);

      const gainChartSettings = {
        height: 100,
        type: 'line',
        stroke: {
          curve: 'smooth',
      }
      }

      log("followersbGainMonth");
      log(followersbGainMonth);
      setGainMonth({
        "Followers": {
          name: "Followers",
          data: followersbGainMonth,
          ...gainChartSettings
        },
        "Following": {
          name: "Favorites",
          data: followingGainMonth,
          ...gainChartSettings
        },
        "Tweets": {
          name: "Tweets",
          data: tweetsGainMonth,
          ...gainChartSettings
        }
      });

      setFollowersGainPerMonth([
        {
          name: "Followers gained",
          data: followersGainPerMonth.slice(0, 9),
          type: 'bar',
          options: {
            bar: {
              columnWidth: 20, // Adjust the percentage to change the width of the bars

            }
          }
        },
      ]);

      setTweetsGainPerMonth([{
        name: "Tweets posted gained",
        data: tweetsGainPerMonth,
        type: 'bar',
      }]);


      setfollowersMonth([{
        name: "Followers gained",
        data: followersb,
        type: 'bar',
      }]);

      const labelsb = datab.map((item) => (item.date));

      const theLabelsMonth = [];
      labelsb.forEach((label, key) => {

        if (key % 30 === 0) {
          theLabelsMonth.push(label);
        }
      });

      setLabelsMonth(theLabelsMonth.slice(0, 9));

      setLabels(labelsb);
      setLabelsChangable(labelsb);

      setCurrentFavorites(favoritesb[0]);
      log("favoritesb[0]");
      log(favoritesb[0]);
      setPrevFavorites(favoritesb[1]);

      setCurrentTweets(tweetsb[0]);
      setPrevTweets(tweetsb[1]);

      setCurrentfollowers(followersb[0]);
      localStorage.setItem("tw-followers", followersb[0]);
      auth.setSocialStats({...auth.socialStats, twitter: followersb[0]})
      setPrevfollowers(followersb[1]);
      localStorage.setItem("tw-followers-prev", followersb[1]);
      auth.setPrevSocialStats({...auth.socialStats, twitter: followersb[1]})

      setCurrentFollowing(followingb[0]);
      setPrevFollowing(followingb[1]);

      setRanks(res.data.data.ranks);      
      changeStartDate(firstStartDate, labelsb, followersb, followingb);
      changeEndDate(new Date(), labelsb, followersb, followingb);
    })
    .catch((err) => {
      log(err);
    });
  }

  const handleGrowthStatChange = (stat) => {
    setCurrentGrowthStat(stat)
  }

  useEffect(() => {
    fetchPlatformData();
  },[auth.user.twitter_id])

  return (
    <>
      <Helmet>
        <title>Twitter {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
      {auth.user.twitter_id ?  
      <>
      <Stack gap={3}>
        <Typography variant="h4">
          Overview
        </Typography>
        <Arrow platformId={platform}/>
        <StyledPaper sx={{
          px: {md: 5, xs: 0},
          zIndex: 999,
          backgroundColor: theme.palette.grey[200],
          borderRadius: {xs: "0", md: "10px"}}}
        >
          <Switchers
            onChangeStartDate={changeStartDate}
            onChangeEndDate={changeEndDate}
            theStartDate={startDate}
            theEndDate={endDate}
            theSubs={followers}
            theViews={favorites}
            theLabels={labels}
          />
          <Box className="overview-wrapper mt-2 graph">
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <OverviewGraph
                    title="Followers"
                    data={changablefollowers}
                    labels={labelsChangable}
                    color={theme.palette.primary.main}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <OverviewGraph
                    title="Favorites"
                    data={changableFavorites}
                    labels={labelsChangable}
                    color={theme.palette.warning.main}
                />
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
        <Grid container columnSpacing={5} rowSpacing={{ xs: 0, sm: 2, md: 3 }} className="other-stats">
          <Grid item xs={12}>
            <Typography variant="h4">
              Other stats
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
              <StatRankCard
                type="Followers"
                number={currentFollowers}
                prevNumber={prevFollowers}
                ranked={false}
              />
          </Grid>
          <Grid item xs={12} md={4}>
              <StatRankCard
                type="Favorites"
                number={currentFavorites}
                prevNumber={prevFavorites}
                ranked={false}
              />
          </Grid>
          <Grid item xs={12} md={4}>
              <StatRankCard
                type="Tweets"
                number={currentTweets}
                prevNumber={prevTweets}
                ranked={false}
              />
          </Grid>
        </Grid>
      </Stack>
        <Grid container columnSpacing={5} className="stats-wrapper">
          <Grid item xs={12} lg={7} sx={{ position: 'relative', paddingTop: '0' }}>
          <Typography variant="h4">
            Monthly gained
          </Typography>
            {Boolean(followersGainPerMonth[0]?.data?.length > 4) ?
              <>
                <Arrow platformId={platform}/>
                <Box className="mt-2 graph custom-border">
                  <OverviewGraph height={490} data={followersGainPerMonth} labels={labelsMonth} color={theme.palette.platform.spotify} bg={theme.palette.grey[200]}/>
                </Box>
              </>
              :
              <Alert className="mt-2" severity="error">
                Insufficient data
              </Alert>
            }
        </Grid>
          <Grid item xs={12} lg={5} className="hide-stack table-data" sx={{ marginTop: { xs: '24px', md: 0 } }}>
          <Typography variant="h4">
            Your Growth the past month
          </Typography>
          <Box className="mt-2">
            {Boolean(data?.length) ?
              <InvoiceListingLayout
                  name="Growth" 
                  TABLE_HEAD={[
                  { id: 'date', label: 'Date', alignRight: false },
                  { id: 'followers', label: 'Followers', alignRight: false },
                  { id: 'posts', label: 'Posts', alignRight: false },
                  ]}
                  data={data}
                  objectKeys={['id']}
                  showCustomBorder
                  platform={platform}
              />
            :
                <Alert severity="error">
                  Insufficient data
                </Alert>
            }
          </Box>
        </Grid>
      </Grid>
    </>
    :
      <InsufficientInfo/>
    }
    </>
  )
}