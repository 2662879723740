const APP_NAME = "YouGrow+";

const TITLE_SEPERATOR = " | "


const APP_URL = process.env.REACT_APP_URL;

const API_URL =  process.env.REACT_APP_API_URL;


export {APP_NAME, TITLE_SEPERATOR, APP_URL, API_URL}