import { useTheme } from "@emotion/react";
import { Box, Paper, Grid, Typography, Button, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material"
import { Stack } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { API_URL } from "../../../hooks/globals";
import { useAuth } from "../../../auth/Authentication";
import useSensableWord from "../../../hooks/useSensableWord";
import { ArtistAppWidgetSummary } from "../../../sections/@dashboard/app"
import useGetSkeletons from "../../../hooks/useGetSkeletons";
import useResponsive from "../../../hooks/useResponsive";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import Arrow from "../../../components/arrow-bg/Arrow";
import Scrollbar from "../../../components/scrollbar";
import { StyledScrollbar } from "../../../components/scrollbar/styles";

export default function CurrentStats(props) {
    const [showMore, setShowMore] = useState(false);
    const sensableWord = useSensableWord();
    const routeParams = useParams();
    const [currentTrack, setCurrentTrack] = useState();
    const theme = useTheme();
    const [currentAudioAnalysis, setCurrentAudioAnalysis] = useState(false);
    const auth = useAuth();
    const [showTrackInfo, setShowTrackInfo] = useState(false);
    const [getSkeletons] = useGetSkeletons();
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const isOnSmallerScreen = useResponsive("down", "sm", "lg");


    const getCurrentTrackInfo = () => {
        const url = `${API_URL}artist/track-info/${routeParams.id}`;

        const userToken = auth.userToken;

        const config = {
            method: 'get',
            url,
            headers: { 
                'Authorization': `Bearer ${userToken}`
            }
        };
        axios(config).then((response) => {            
            setCurrentTrack(response.data.track_info);
            setCurrentAudioAnalysis(response.data.audio_analysis);
        }).catch((error) => {

        }).finally(() => {

        });

    }

    
    const getTrackImage = (imgSrc) => {
        const http = new XMLHttpRequest();
        http.open('HEAD', imgSrc, false);
        const fallBackURL = '/assets/placeholders/track-image.png';

        if (!imgSrc) {
            return fallBackURL;
        }


        try {
            http.send();

            if (http.status !== 200) {
                return fallBackURL;
            }
        } catch(error) {
            return fallBackURL;
        }

        return imgSrc;
    }

    const getGridAmountResponsive = () => {
        if (isOnSmallerScreen) {
            return 5;
        }

        if (isOnSmallScreen) {
            return 5;
        } 
        
        return 5;
    }

    useEffect(() => {
        if (routeParams.id) {
            getCurrentTrackInfo();
        }
    }, []);

    

    return (
        <>
        <Stack gap={3}>
            <Grid container justifyContent='space-between' sx={{paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>
                <Typography variant='h4'>Live performance {currentTrack?.title && `of ${currentTrack.title}`}</Typography>
                {props.orderArrows}
                <Grid item xs={12}>
                    {currentTrack?.title && <Button className="p-0" onClick={() => setShowTrackInfo(!showTrackInfo)}>{!showTrackInfo ? `Show track info` : `Hide track info`}</Button>}
                </Grid>
            </Grid>
            {
            showTrackInfo && 
            <Stack gap={3} position="relative">    
                <Grid container justifyContent="space-between">   
                    <Typography variant="h4" sx={{paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>Artist performance</Typography>            
                    {props.orderArrows}
                </Grid>
                <Arrow platformId={props.platformId}/>
                <StyledPaper sx={{
                    px: {md: 5, xs: 0},
                    zIndex: 999,
                    backgroundColor: theme.palette.grey[200], borderRadius: {xs: "0", md: "10px"}, "&:before": { md: {backgroundColor: props.platformColor} }, "&:after": { md: {backgroundColor: props.platformColor} }}}>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <LazyLoadImage
                        alt={currentTrack.title}
                        src={getTrackImage(currentTrack.avatar)}
                        height={isOnSmallScreen ? "400px" : "300px"}
                        width={isOnSmallScreen ? "100%" : "auto"}/>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Stack gap={3}>
                            <Typography variant="h4">
                                {currentTrack.title}
                            </Typography>
                            <Typography variant="h5">Artists</Typography>
                            {currentTrack.artists?.map((artist) => (
                                <Typography variant="body2">
                                    {artist.name}
                                </Typography>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        {
                            currentAudioAnalysis &&
                                <Stack gap={{md: 3, sx: 1}}>
                                <Typography textAlign="left" sx={{p: 1}} variant='h5'>Audio analysis</Typography>
                                <StyledScrollbar sx={{width: "100%"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {currentAudioAnalysis.map((analysis) => (
                                                    <TableCell key={analysis.key}>
                                                        {analysis.key}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {currentAudioAnalysis.map((analysis) => (
                                                    <TableCell key={analysis.value}>
                                                        {analysis.value}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </StyledScrollbar>
                            </Stack>
                        }
                    </Grid>
                </Grid>
                </StyledPaper>
            </Stack>
            }
            {props.currentStats?.[props.platformId] ?
            <>
                <Grid container className="current-stat-items" spacing={{md: 3, xs: 0}} sx={{
                    paddingTop: {md: "0", xs: "56px"}, 
                    paddingBottom: {md: "0", xs: "54px"}, 
                    backgroundColor: {md: "transparent", xs: theme.palette.grey[200]}, boxShadow: {md: "none", xs: "0px 3px 3px rgba(0,0,0,0.16 )"}}}>
                    {Object.keys(props.currentStats?.[props.platformId]).map((item, i) => (
                            <Grid 
                            sx={{display: showMore || i < getGridAmountResponsive() ? 'block' : 'none'}} 
                            key={i} 
                            item 
                            xs={12} 
                            sm={6} 
                            md={2.4}
                            className="current-stat-item"
                            >
                                <Box className="current-stat-item-before"
                                    sx={{backgroundColor: props.platformColor}}
                                />
                                <ArtistAppWidgetSummary color={props.platformColor} title={sensableWord.sensableWord(item)} total={props.currentStats[props.platformId][item] || "None"} icon={'material-symbols:chart-data'} />
                            </Grid> 
                        )
                    )}
                    <Grid item xs={12}>
                        <Stack sx={{
                            paddingLeft: {xs: "30px", md: "0"},
                            paddingRight: {xs: "30px", md: "0"},
                        }}>
                        {Object.keys(props.currentStats?.[props.platformId]).length > getGridAmountResponsive() &&
                            <Button sx={{
                                border: `1px solid ${props.platformColor}`, color: props.platformColor}}  variant="outlined" onClick={() => {setShowMore(!showMore)}}>
                                    {showMore ? 'Show less' : 'Show more'}
                            </Button>
                        }
                        </Stack>
                    </Grid>
                </Grid>
                </>
                :
                <Grid container spacing={3}>
                    {getSkeletons(getGridAmountResponsive(), '100%', 238).map((skeleton) => (
                        <Grid item xs={12} sm={6} md={2.4}>
                            {skeleton}
                        </Grid>
                    ))}
                    <Grid item md={12}>
                        {getSkeletons(1, '100%', 24)}
                    </Grid>
                </Grid>
            }
        </Stack>
        </>
    )
}