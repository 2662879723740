import { Box, Grid, Typography, useTheme } from "@mui/material"
import "react-multi-carousel/lib/styles.css";
import { string, arrayOf, bool, node } from "prop-types"
import Carousel from "react-multi-carousel"
import useGetSkeletons from "../../../../hooks/useGetSkeletons"
import useResponsive from "../../../../hooks/useResponsive"
import TopTrack from "./TopTrack"
import "../../../../scss/top-tracks.scss";
import StyledPaper from "../../../../theme/artist/overrides/StyledPaper";


TopTracks.propTypes = {
  tracks: arrayOf(string),
  platformColor: string,
  loading: bool,
  orderArrows: node,
  platformId: string,
}

function TopTracks({tracks, platformColor, loading, orderArrows, platformId}) {
  const [getSkeletons] = useGetSkeletons();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const theme = useTheme();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.2,
    }
  };

  return (
    <>
      <Box>
        <Grid
        className="tracks-wrapper-title"
        container justifyContent="space-between" sx={{paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>   
            <Typography variant="h4">Most popular tracks</Typography>            
            {orderArrows}
        </Grid>
      </Box>
      
      {
      platformId !== "spotify" ? 
        <StyledPaper container className="no-tracks-container"
        sx={{position: "relative", borderRadius: {md: "10px", xs: "0"}, backgroundColor: theme.palette.grey[200], "&:before": {backgroundColor: {xs: "transparent", md: platformColor} }, "&:after": { backgroundColor: {xs: "transparent", md: platformColor} }}}>
          <Typography className="no-data">Top tracks not supported for this platform</Typography>
        </StyledPaper>
      :
      !loading && !tracks?.length ?
      <StyledPaper container className="no-tracks-container"
      sx={{position: "relative", borderRadius: {md: "10px", xs: "0"}, backgroundColor: theme.palette.grey[200], "&:before": {backgroundColor: {xs: "transparent", md: platformColor} }, "&:after": { backgroundColor: {xs: "transparent", md: platformColor} }}}>
        <Typography className="no-data">No popular tracks found</Typography>
      </StyledPaper>
      :
      isOnSmallScreen ? 
      <Carousel
        responsive={responsive}
        containerClass="carousel-container-with-scrollbar"
      >
         {!loading ? 
            tracks.map((track) => (
              <Box className="responsive-track">
                <TopTrack 
                  data={track}
                  platformColor={platformColor}
                />
              </Box>
            ))
          : 
              getSkeletons(6, "100%", 303).map((skeleton) => (
                <Box>
                  {skeleton}
                </Box>
              ))
          }
      </Carousel>
      :
        <Grid container className="top-track-container" spacing={2}>
          {!loading ? 
            tracks.map((track) => (
              <Grid item md={4}>
                <TopTrack 
                  data={track}
                  platformColor={platformColor}
                />
              </Grid>
            ))
          : 
              getSkeletons(6, "100%", 303).map((skeleton) => (
                <Grid item md={4}>
                  {skeleton}
                </Grid>
              ))
          }
        </Grid>
      }
    </>
  )
}

export default TopTracks;