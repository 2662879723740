import { React } from 'react';
// @mui
import { TextField, Switch, FormControlLabel, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useFormHandler} from '../hooks/useFormHandler';
// components

// ----------------------------------------------------------------------

export default function AdminForm() {
  const fHandler = useFormHandler('admin/admin-users');

  return (
    <>
      <TextField name="email" label="Email" value={fHandler.formValues?.email} onChange={fHandler.handleValChange} error={fHandler.errors?.email} helperText={fHandler.errors?.email} />

        <TextField
            name="password"
            label="Password"
            onChange={fHandler.handleValChange} 
            value={fHandler.formValues?.password}
            error={fHandler.errors?.password} 
            helperText={fHandler.errors?.password} />

            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">User role</InputLabel>
            <Select
                name="role"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fHandler.formValues?.role}
                label="User role"
                onChange={fHandler.handleValChange}
            >
                <MenuItem value={1}>Admin</MenuItem>
                <MenuItem value={2}>Super admin</MenuItem>
            </Select>
            </FormControl>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => {fHandler.handleSubmit()}} loading={fHandler.isSubmitting}>
          Submit
        </LoadingButton>
    </>
  );
}
