import { Card, Stack, Typography } from "@mui/material";
import { number } from "prop-types";

CollectiveContribution.propTypes = {
  palnted: number
}

export default function CollectiveContribution({planted}) {
  return (
    <Stack spacing={2}>
    <Typography className="contibution-title">
      Our collective contribution
    </Typography>
    <Card className="tree-collective-card">
      <Typography className="planted-title">
        Trees planted
      </Typography>
      <Typography className="planted">
        {planted}  
      </Typography>
      <img src="/assets/trees/trees.svg" alt="trees" className="trees-img"/>
    </Card>
    </Stack>
  )
}