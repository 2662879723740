// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Admin dashboard',
    path: '/admin/dashboard',
    mobile: true,
    roles: ['super-admin', 'admin'],
    permissions: false,
    availableAt: "advanced subscription"
  },
  {
    title: 'YouGrow',
    subscribed: false,
    subMenu: [
      {
        title: "Orders",
        path: '/artist/orders',
        permissions: false,
      },
      {
        title: "Discount code",
        path: '/artist/coupon',
        permissions: ['can-get-discounts'],
        availableAt: "advanced subscription"
      },
    ],
    mobile: true,
    path: null,
    permissions: false
  },
  {
    title: 'Analytics',
    subMenu: [
      {
        title: "Milestones",
        path: '/artist/activity-feed',
        permissions: ['can-analyse-music'],
        availableAt: "basic subscription"
      },
      {
        title: "Music analytics",
        path: '/artist/statistics',
        permissions: ['can-analyse-music'],
        availableAt: "basic subscription"
      },
      {
        title: "Social media analytics",
        path: '/artist/social-media',
        permissions: ['can-analyse-socials'],
        availableAt: "advanced subscription"
      },
    ],
    mobile: true,
    path: null,
    permissions: false,
  },
  {
    title: 'Artwork',
    path: '/artist/artwork',
    subMenu: null,
    subscribed: false,
    mobile: true,
    permissions: ['can-generate-artwork'],
    availableAt: "basic subscription"
  },
];

export default navConfig;
