import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import ArtistThemeProvider from './theme/artist';
// pages
import Artists from './pages/admin/artists/Artists'
import LoginPage from './auth/login-admin/LoginPage';
import LogOut from './auth/LogOut';
import CheckoutForm from './auth/register-checkout/CheckoutForm';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/admin/DashboardAppPage';
import { ModalProvider } from './modals/ModalContext';
import PricingPlans from './pages/admin/plans/PricingPlans';
import ActiveSubscriptions from './pages/admin/subscriptions/ActiveSubscriptions';
import Admins from './pages/admin/admins/Admins';
import LoggedOutLayout from './layouts/loggedout/LoggedOutLayout';
import ThankYou from './pages/thankyou-cancel/Thankyou';
import Cancel from './pages/thankyou-cancel/Cancel';

// artist dashboard
import ArtistDashboardLayout from './layouts/artist-dashboard';
import ArtistOrders from './pages/artist/ArtistOrders';
import ArtistOrderShow from './pages/artist/ArtistOrderShow';
import ArtistSettings from './pages/artist/ArtistSettings';
import ArtistStatistics from './pages/artist/ArtistStatistics';
import ArtistTrackInfo from './pages/artist/ArtistTrackInfo';
import ArtistLoginPage from './pages/artist/auth/ArtistLoginPage';
import MyAccount from './pages/artist/auth/MyAccount';
// artist auth
import ThemeProvider from './theme/admin';

// show
import ShowLayout from './layouts/dashboard/show/ShowLayout';
import ShowAdmin from './pages/admin/admins/Show';
import ShowArtist from './pages/admin/artists/Show';
import ShowPlan from './pages/admin/plans/Show';
import ShowSubscription from './pages/admin/subscriptions/Show';
import ActivityFeed from './pages/artist/ArtistActivityFeed';
import SocialMedia from './pages/artist/social-media';
import AIArtworkMain from './pages/artist/ai-artwork';
import Faq from './pages/artist/ai-artwork/faq';
import Layout from './pages/artist/ai-artwork/Layout';
import Gallery from './pages/artist/ai-artwork/gallery';
import LoadingScreen from './pages/artist/ai-artwork/loading-screen';
import AddCredits from './pages/artist/ai-artwork/add-credits';
import ThankYouCredits from './pages/thankyou-cancel/ThankyouCredits';
import CancelCredits from './pages/thankyou-cancel/CancelCredits';
import GeneratedImages from './pages/artist/ai-artwork/generated-images';
import AIArtworkPricingPlans from './pages/admin/ai-artwork-pricing-plans/AIArtworkPricingPlans';
import ShowAIArtworkPricingPlan from './pages/admin/ai-artwork-pricing-plans/Show';
import SocialLayout from './pages/artist/social-media/Layout';
import YouTube from './pages/artist/social-media/YouTube';
import Facebook from './pages/artist/social-media/Facebook';
import Instagram from './pages/artist/social-media/Instagram';
import TikTOk from './pages/artist/social-media/TikTok';
import Twitter from './pages/artist/social-media/Twitter';
import SharedArtwork from './pages/artist/ai-artwork/SharedArtwork';
import History from './pages/artist/ai-artwork/history';
import Coupons from './pages/admin/coupons/Coupons';
import ShowCoupon from './pages/admin/coupons/Show';
import CouponGenerator from './pages/artist/coupon-generator';
import RegisterSocialAccount from './pages/artist/social-media/sections/register-accounts';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/admin/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/dashboard" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'admins', element: <ModalProvider><Admins /></ModalProvider> },
        { path: 'admins/:id', element: <ShowAdmin /> },
        { path: 'artists', element: <ModalProvider><Artists /></ModalProvider> },
        { path: 'artists/:id', element: <ShowArtist /> },
        { path: 'coupons', element: <ModalProvider><Coupons /></ModalProvider> },
        { path: 'coupons/:id', element: <ShowCoupon /> },
      ]
    },
    { path: 'admin/login', element: <ThemeProvider><LoginPage /></ThemeProvider> },
    {
      path: '/artist/',
      element: <ArtistDashboardLayout />,
      children: [
        { element: <Navigate to="/artist/orders" />, index: true },
        { path: 'statistics', element: <ArtistStatistics /> },
        { path: 'statistics/track/:id', element: <ArtistTrackInfo /> },
        { path: 'activity-feed', element: <ActivityFeed /> },
        {
          path: 'artwork',
          children: [
            { element: <Navigate to="/artist/artwork/main" />, index: true },
            { path: 'main', element: <AIArtworkMain /> },
            { path: 'faq', element: <Faq /> },
            { path: 'gallery', element: <Gallery /> },
            { path: 'generated/:id', element: <GeneratedImages />, exact: true },
            { path: 'add-credits', element: <AddCredits /> },
            { path: 'history', element: <History /> },
          ]
        },
        {
          path: 'social-media', element: <SocialLayout />, children: [
            { element: <Navigate to={'/artist/social-media/home'} />, index: true },
            { path: 'home', element: <SocialMedia /> },
            { path: 'youtube', element: <YouTube /> },
            { path: 'twitter', element: <Twitter /> },
            { path: 'facebook', element: <Facebook /> },
            { path: 'instagram', element: <Instagram /> },
            { path: 'tiktok', element: <TikTOk /> },
          ]
        },
        { path: 'register-social-accounts', element: <RegisterSocialAccount /> },
        { path: 'orders/:id', element: <ArtistOrderShow /> },
        { path: 'coupon', element: <CouponGenerator /> },
        { path: 'settings', element: <ArtistSettings /> },
        { path: 'thank-you-credits', element: <ThankYouCredits /> },
        { path: 'canceled-credits', element: <CancelCredits /> },
      ]
    },
    { path: 'login', element: <ArtistThemeProvider><ArtistLoginPage /></ArtistThemeProvider> },
    { path: 'login/:message', element: <ArtistThemeProvider><ArtistLoginPage /></ArtistThemeProvider> },
    { path: 'register-free', element: <ArtistThemeProvider><ArtistLoginPage register /></ArtistThemeProvider> },
    {
      path: '/', element:
        <ArtistThemeProvider><LoggedOutLayout /></ArtistThemeProvider>, children: [
          { element: <Navigate to="/login" />, index: true },
          { path: 'checkout', element: <CheckoutForm /> },
          { path: 'thank-you', element: <ThankYou /> },
          { path: 'cancel', element: <Cancel /> },
          { path: 'my-account', element: <MyAccount /> },
          { path: 'artist/orders', element: <ArtistOrders /> },
          { path: 'admin-artist-orders/:userId/:userEmail', element: <ArtistOrders /> },
          {
            path: "/shared-artwork/:prompt",
            element: <SharedArtwork />,
          }
        ]
    },
    {
      path: 'logout',
      element:
        <LogOut />
      ,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      element: <LoggedOutLayout />,
      children: [
        { path: '404', element: <Page404 /> },
      ]
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
