import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Box, Button, Grid, Stack, Typography } from "@mui/material";
import Iconify from "../../../../components/iconify/Iconify";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import galleryItems from "../gallery/config";
import AIHistoryCard from "./AIHistoryCard";
import useGetListing from "../../../../api/useGetListing";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import Layout from "../Layout";
import useResponsive from "../../../../hooks/useResponsive";
import useGetSkeletons from "../../../../hooks/useGetSkeletons";

export default function History() {
  const [historyItems, setHistoryItmes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const get = useGetListing();
  const log = useConsoleLog;
  const [showHistory, setShowHistory] = useState(false);
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const [getSkeletons] = useGetSkeletons();

  const getHistoryItems = () => {
    get.getListing("artist/artwork")
    .then((response) => {
      setHistoryItmes(response.data.reverse());
      log("images");
      log(response);
    })
    .catch((err) => {
      log(err);
    }).finally(() => {
      setIsLoading(false);
    })
    ;
  }

  useEffect(() => {
    getHistoryItems();
  },[])

  if (!isLoading) {
    return (
      <Box className="hsitory-button-wrapper">
        <Button
        onClick={() => setShowHistory(!showHistory)}
        disabled={historyItems.length < 1}
        variant="contained" className="history-btn bg-neutral contrast-neutral-bg">
            {!isOnSmallScreen && "Your history "}<Iconify className="icon" icon="uil:history-alt" width="20px"/>
        </Button>
        {showHistory &&
          <Stack spacing={3} className="history-wrapper bg-neutral">
            <Scrollbar sx={{height: 300}}>
              <Stack>
                {historyItems.map((item) => (
                  <AIHistoryCard
                      id={item.id}
                      images={item.imageUrls}
                      query={item.query}
                  />
                ))}
              </Stack>
            </Scrollbar>
          </Stack>
        }
      </Box>
    )
  }

  return getSkeletons(1, "75%", "46px");
  


}