import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Typography, useTheme } from "@mui/material";
import Generator from "../generator";
import GalleryItems from "./GalleryItems";
import useResponsive from "../../../../hooks/useResponsive";
import Heading from "../heading";
import { APP_NAME, TITLE_SEPERATOR } from "../../../../hooks/globals";
import Layout from "../Layout";
import useGetListing from "../../../../api/useGetListing";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import LoadingScreen from "../loading-screen";

export default function Gallery() {
  const theme = useTheme();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const get = useGetListing();
  const log = useConsoleLog;
  const [galleryItems, setGalleryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGallery, setIsLoadingGallery] = useState(true);
  const [errors, setErrors] = useState();

  const getGallery = () => {
    get.getListing('artist/artwork/gallery/view')
    .then((res) => {
      log(res);
      setGalleryItems(res.data);
    }).catch((err) => {
      log(err);
    }).finally(() => {
      setIsLoadingGallery(false);
    })
  }


  useState(() => {
    getGallery();
  },[]);

  if (!isLoading) {
      return (
        <Layout>
          <Helmet>
            <title>Album cover gallery {TITLE_SEPERATOR} {APP_NAME}</title>
          </Helmet>
          <Heading
          title="Album cover gallery"
          description="Chances are that you are not a professional visual artist. Because of this reason, we give you the opportunity to make use of our ai artwork generator. Type in the perfect prompt and generate an album cover that best fits you."
          showHist={false}
          />
          <Generator
            onSetIsLoading={setIsLoading}
            onSetErrors={setErrors}
            onErrors={errors}
            />
            {galleryItems.length ? 
            <GalleryItems items={galleryItems}/>
            :
            !isLoadingGallery && 
            <Typography>No Gallery items found.</Typography>
            }
        </Layout>
      )
  }

    return <LoadingScreen/>
}