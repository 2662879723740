import { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Iconify from "../../../components/iconify";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";

export default function Tips() {
  const [hidden, setHidden] = useState(true);

  return (
    <>
    <Button
      className="show-tips-btn"
      onClick={() => {setHidden(!hidden)}}
    >
       Generator tips  {hidden ? <Iconify icon="fontisto:angle-right"/> : <Iconify icon="fontisto:angle-down"/>}
    </Button>
    {!hidden &&
        <Grid container spacing={3} className="tips-wrapper">
          <Grid item md={12}>
            <Typography variant="h1">
              Things to keep in mind when generating images
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Stack gap={2}>
              <Box>
                <Typography variant="h2">
                  1. Define your preferred art style
                </Typography>
                <Typography variant="body-1" className="contrast-neutral-bg">
                  To get the most accurate result, specify what art form you want your art to be in. For example; 3d render, abstract or realism.
                </Typography>
              </Box>
              <Box>
                <Typography variant="h2">
                  2. Be as detailed as possible
                </Typography>
                <Typography variant="body-1" className="contrast-neutral-bg">
                  Try to describe the image that you want in as much detail as possible. The more the better!
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack gap={2}>
              <Box>
                <Typography variant="h2">
                  3. Avoid generating words
                </Typography>
                <Typography variant="body-1" className="contrast-neutral-bg">
                  The image generator has difficulties with generating words. To avoid gibberish, avoid prompts that may result in words or letters.
                </Typography>
              </Box>
              <Box>
                <Typography variant="h2">
                  4. Avoid specifying that it’s an album cover
                </Typography>
                <Typography variant="body-1" className="contrast-neutral-bg">
                  When specifying that it’s an album cover, the AI generator will most likely generate a title. And since the generator struggles with words and letters, this can best be avoided.
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      }
    </>
  )
}