import { Box, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import { AppWebsiteVisits } from "../../../../sections/@dashboard/app";

OverviewGraph.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
  color: PropTypes.string,
  bg: PropTypes.string,
  height: PropTypes.string
}

export default function OverviewGraph({title, labels, data, bg, color, height = 364,
  theDefaultOverview,
  firstStat,
  secondStat,
  onSetDefaultOverview
}) {
  const log = useConsoleLog;
  const [showOther, setShowOther] = useState();


  const change = (sth) => {
    log(sth.target.value);
    onSetDefaultOverview(sth.target.value)
    setShowOther(false);
  }


  useEffect(() => {
    log(data);
    log(labels);
  },[])

  return (
    <Box sx={{
      backgroundColor: bg,
      borderRadius: '10px'
    }}>
      <AppWebsiteVisits
        title={title}
        subheader="Overview"
        chartLabels={labels}
        chartData={data}
        color={color}
        height={height}
      />
      
    {(firstStat || secondStat) &&
      <Select
          name="select-data-type"
          className="switch-data-type"
          onChange={change}
          defaultValue
        >
          {typeof firstStat !== "undefined" &&
            <MenuItem className={`btn ${theDefaultOverview ? "active" : "inactive"}`}
              value
              selected
            >
              {firstStat}
            </MenuItem>
          }
          {typeof secondStat !== "undefined" &&
              <MenuItem className={`btn ${!theDefaultOverview ? "active" : "inactive"}`}
                value={false}
              >
                {secondStat}
              </MenuItem>
          }
        </Select>
      }
    </Box>
  )
}