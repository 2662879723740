import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";

const ClaimTen = () => {
  const navigate = useNavigate();

  return (
    <Box className="green-box cta-wrapper">
      <Typography className="cta-title text-black">
        Claim 15% off your next order
      </Typography>
      <Typography className="cta-description text-black">
        Join YouGrow+ and enjoy monthly discount codes for your YouGrow Promo orders. Try it out now!
      </Typography>
      <StyledLoadingButton
        className="mt-2"
        onClick={() => {window.open('http://landing.yougrowplus.com/', '_blank')}}
      >
        Try out for free now!
      </StyledLoadingButton>
    </Box>
  )
}

export default ClaimTen;