import { React, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { TextField, Grid, Switch, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Alert, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useFormHandler} from '../../hooks/useFormHandler';
import Iconify from '../../components/iconify/Iconify';
// components

UpdateCouponForm.propTypes = {
    couponId: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    apiDone: PropTypes.number.isRequired,
    setApiDone: PropTypes.func.isRequired,
    editClose: PropTypes.func.isRequired
}


export default function UpdateCouponForm({couponId, data, apiDone, setApiDone, editClose}) {
    const fHandler = useFormHandler(`admin/coupons/${couponId}`);

    useEffect(() => {
        const roles = {
            "super-admin": 2,
            admin: 1
        }

        fHandler.setFormValues({
            code: data.code,
        });
    }, []);

    useEffect(() => {
        if (fHandler.successMsg) {
            setApiDone(apiDone + 1);
        }
    }, [fHandler.successMsg]);

    return (
    <Grid container gap={3}>
        <Grid item md={3}>
            <TextField fullWidth name="code" label="Code" value={fHandler.formValues?.code} onChange={fHandler.handleValChange} error={fHandler.errors?.code} helperText={fHandler.errors?.code} />
        </Grid>

        <Grid item md={2}>
            <LoadingButton sx={{height: 56, border: 'none'}} fullWidth size="large" type="submit" variant="contained" onClick={() => fHandler.handleUpdateSubmit()} loading={fHandler.isSubmitting}>
                Submit
            </LoadingButton>
        </Grid>

        <Grid item md={12}>
            {fHandler.successMsg && <Alert>{fHandler.successMsg}</Alert>}
            {fHandler.errors?.messageIse && <Alert severity='error'>{fHandler.errors?.messageIse}</Alert>}
        </Grid>
        <Grid item md={12}>
            <IconButton onClick={() => editClose()} sx={{margin:"auto", display: 'block'}} aria-label='Cancel'>
                <Iconify icon="system-uicons:cross-circle"/>
            </IconButton>
        </Grid>
    </Grid>
    )
}