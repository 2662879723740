import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import {Tooltip, tooltipClasses, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// components
import styled from "@emotion/styled";
import {useChart} from '../../../components/chart';
import {fShortenNumber} from '../../../utils/formatNumber';
import useConsoleLog from '../../../hooks/useConsoleLog';
import useResponsive from '../../../hooks/useResponsive';
import {useColorMode} from "../../../theme/artist/ColorModeContext";


// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    color: PropTypes.string,
    height: PropTypes.string
};

export default function AppWebsiteVisits({title, subheader, chartLabels, chartData, color, height = 364, ...other}) {
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const [open, setTooltipOpen] = useState(false);   
    const wrapperRef = useRef(null);
    const colorMode = useColorMode();
    
    const toggleOpen = () => {
        if (isOnSmallScreen) {
            setTooltipOpen(!open);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOnSmallScreen) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setTooltipOpen(false);
                }
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("scroll", handleClickOutside);

        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
            document.addEventListener("scroll", handleClickOutside);
        };
      }, [wrapperRef]);

    const log = useConsoleLog;
    const LightTooltip = styled(({className, ...props}) => (
        <Tooltip onClick={toggleOpen} ref={wrapperRef} open={isOnSmallScreen ? open : undefined} disableInteractive enterTouchDelay={0} {...props} arrow classes={{popper: className}}/>))(({theme}) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: 'rgba(226, 226, 226, 0.9)'
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: colorMode.darkMode ? '#000' : '#fff',
            color: colorMode.darkMode ? '#fff' : 'rgba(0, 0, 0, 0.87)',
            boxShadow: '0px 3px 5px rgba(0,0,0,0.16 )',
            boxSizing: 'border-box',
            borderRadius: '10px',
            padding: 24,
            fontSize: 16,
            fontWeight: 400
        },
    }));
    const tooltipText = {
        playlist_reach_current: {
            title: "Current playlist reach",
            text: "Your playlist reach is the combined number of followers of all playlists you appear on."
        },
        playlists_current: {
            title: "Current Playlists",
            text: "The total number of playlists with over 200 followers your music appears on."
        },
        streams_current: {
            title: "Current streams",
            text: "This is the total amount of streams your music has gotten on this specific platform."
        },
        monthly_listeners_current: {
            title: "Current Listeners Monthly",
            text: "The number of unique listeners that have listened to your music the past month."
        },
        followers_total: {
            title: "Total Followers",
            text: "The total number of people that are following you on this specific platform."
        },
        popularity_current: {
            title: "Popularity",
            text: "Spotify's popularity ranking measures how often a song or artist is streamed, included in playlists, and listened to by unique users to gauge their popularity on the platform."
        }
    }
    const chartOptions = useChart({
        plotOptions: {bar: {columnWidth: '32%'}},
        fill: {type: chartData.map((i) => i.fill)},
        colors: [color],
        labels: chartLabels,
        xaxis: {type: 'datetime'},
        yaxis: {
            labels: {
                formatter: (value) => {
                    return fShortenNumber(value);
                }
            }
        },
        tooltip: {
            shared: true,
            theme: "dark",
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${fShortenNumber(y.toFixed(0))}`;
                    }
                    return y;
                },
            },
        },

    });

    log("chartDatalog");
    log(chartData);
    return (
        <>
        <Typography>{title}</Typography>
        {tooltipText[chartData[0]?.key]?.title &&
            <LightTooltip title={
                <>
                    <h3 className={'custom-border'}> {tooltipText[chartData[0].key]?.title}</h3>
                    {tooltipText[chartData[0].key]?.text}
                </>
            } sx={{float: "right", marginTop: "-25px"}}>
                <InfoOutlinedIcon/>
            </LightTooltip>
        }
            <ReactApexChart series={chartData} options={chartOptions} height={height}/>
        </>
    );
}
