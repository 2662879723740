import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme, Divider, Typography, Box } from "@mui/material";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import usePost from "../../../../api/usePost";
import useConsoleLog from "../../../../hooks/useConsoleLog";

Package.propTypes = {
  id: PropTypes.number,
  amount: PropTypes.number,
  price: PropTypes.string,
  loading: PropTypes.bool,
  SetLoading: PropTypes.func,
  paymentMethod: PropTypes.number
}

export default function Package({onIsLoading, onSetIsLoading, onBuy, id, name, price, loading, setLoading, paymentMethod}) {
  const theme = useTheme();
  const post = usePost();
  const log = useConsoleLog;


  return (
    <Box className="package-wrapper" sx={{
      backgroundColor: theme.palette.common.white
    }}>
      <Typography className="add-text">Add extra credits</Typography>
        <Divider/>
      <Typography variant="h4" className="credit-text">
        {name}
      </Typography>
      <Typography className="price-text">
        ${price}
      </Typography>
      <StyledLoadingButton className="buy" onClick={() => onBuy(id)} loading={onIsLoading}>
        Buy now
      </StyledLoadingButton>
    </Box>
  )
}