import { Link } from "react-router-dom";
import { Typography, Box, Stack } from "@mui/material";
import Explaination from "./Explaination";
import explainations from "./config";
import { useAuth } from "../../../../auth/Authentication";

export default function Explainations() {
  const auth = useAuth();

  if (!auth.isApp) {
    return (
      <Box className="explanations-wrapper">
        <Typography variant="h4">
          Frequently asked questions
        </Typography>
        <Stack gap={3} className="mt-2">
          {explainations.map(({heading, text}) => (
            <Explaination heading={heading} text={text}/>
          ))}
        </Stack>
      </Box>
    )
  }

  return (<></>);
}