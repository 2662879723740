import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify/Iconify';
import useResponsive from '../../../hooks/useResponsive';

DataCardContent.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  platform: PropTypes.object,
  platformColor: PropTypes.string,
  songUrl: PropTypes.string,
};

export default function DataCardContent({ avatar, title, text, platform, platformColor, songUrl }) {
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  return (
    <Grid container spacing={2}>
        {avatar ?
          <Grid md={3} xs={6} item>
            {songUrl ? 
              <a href={songUrl} target="_blank" rel="noreferrer">
                <img width="78" height="78" src={avatar} alt={title} />
              </a>
              : 
              <img width="78" height="78" src={avatar} alt={title} />
            }
          </Grid>
          : isOnSmallScreen &&
          <Grid xs={6} item/>
        }
        {isOnSmallScreen &&
        <Grid item xs={6} sx={{textAlign: "right"}}>
          <Iconify sx={{ color: platformColor }} width="50.6px" icon={platform?.icon} />
        </Grid>
        }
        <Grid md={9} item>
          <Typography variant="h4" sx={{ 
            fontSize: {xs: "17px!important", md: "25px!important"},
            fontWeight: "bold", 
            lineHeight: 1, 
            pb: "5px",
            textTransform: "capitalize" 
            }}>
            {title?.replace('_', ' ')}
          </Typography>
          {text && <Typography variant="body">{text}</Typography>}
        </Grid>
    </Grid>
  );
}
