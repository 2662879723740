import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Iconify from "../../../components/iconify";
import StyledBox from "../../../theme/artist/overrides/StyledBox";
import AddIdForm from "../forms/AddIdForm";



export default function AddSpotifyId() {
  const [showMore, setShowMore] = useState(false);


  return (
    <>
      <Stack direction={"row"} alignItems="center">
        <Typography className="c-pointer" onClick={() => setShowMore(!showMore)}>
          Can't find your artist name? 
        </Typography>
        <Iconify icon="uil:angle-down" width={20}/>
      </Stack>
      {
        showMore &&
        <Stack className="add-spotify-id-section" spacing={3}>  
          <Typography className="badge">
            Things to note
          </Typography>
          <Typography>
            Before linking your Spotify artist id to your account, there are a couple of<br/> 
            things to take note of. 
          </Typography>
          <Typography>
            1) Please make sure that you fill in the correct Spotify artist id. You can find this in the Spotify URL<br/> 
            that leads to your Spotify artist page, right after /artist/.<br/> 
            For example: https://open.spotify.com/artist/<span className="green-text">3NKVm2Jedcf6ibJr6pMUVx</span>
          </Typography>
          <Typography>
            2) If you get a link that contains two codes, separated by ‘?si=‘, your Spotify id is the code that comes directly after /artist/.<br/> 
            For example: https://open.spotify.com/artist/<span className="green-text">69NymgZLeHlWbV4Sh1CO36</span><span className="red-text">?si=6f6593f19f35458b</span>
          </Typography>
          <Typography>
            3) Below you can add your Spotify artist id to your account. Please make sure that you add the correct account. An account can only be added once.
          </Typography>
          <AddIdForm placeholder="Spotify ID" platform={`Spotify`} platformNameId={`spotify`} idType={'add-id'} helper={"Spotify ID should look similar to 3nFkdlSjzX9mRTtwJOzDYB, and can be found at the end of the URL when going to the Spotify artist profile."}/>
        </Stack>
      }
    </>
  )
}