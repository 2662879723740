import PropTypes from "prop-types";
import { Card, CardContent, Stack, Typography, Box, useTheme } from "@mui/material";
import Iconify from "../../../../components/iconify";
import { fShortenNumber } from "../../../../utils/formatNumber";
import useResponsive from "../../../../hooks/useResponsive";

StatRankCard.propTypes = {
  number: PropTypes.number.isRequired,
  prevNumber: PropTypes.number,
  type: PropTypes.string.isRequired,
  ranked: PropTypes.bool.isRequired,
}

export default function StatRankCard({number, prevNumber, type, ranked}) {
  const up = number > prevNumber;
  const down = number < prevNumber;
  const difference = number - prevNumber;
  const hasPrevNumber = typeof prevNumber !== "undefined";
  const theme = useTheme();
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  console.log(isOnSmallScreen)

  return (
    <Card className={`current-stat-item other-stats-card bg-neutral border-neutral ${!isOnSmallScreen && 'custom-border'} ${!hasPrevNumber && 'rank'}`}
      sx={{
        display: {md: "block"},
        py: '15%',
        px: 0,
        boxShadow: "0px 3px 5px rgba(0,0,0,0.16 )",
        textAlign: 'center',
        height: "21vw",
        minHeight: "135px",
        maxHeight: "250px",
        borderBottomLeftRadius: 0,
        backgroundColor: theme.palette.grey[200]
      }}
    >
      <Box className="content-wrapper" sx={{background: theme.palette.grey[200]}}>
        <Box className="content-container">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box className="trend" sx={{ order: { xs: 1, sm: 'unset' } }}>
                {!ranked &&
                  <Stack direction="row" className={`info-stack ${up ? "positive" : down ? "negative" : "neutral"}`}>
                    <Typography className="percentage">
                      <span>
                        {up ?
                          <Iconify icon="iconamoon:trend-up" className="green" width={isOnSmallScreen ? 24 : 40}/>
                        :
                        down ?
                          <Iconify icon="iconamoon:trend-down" className="red" width={isOnSmallScreen ? 24 : 40}/>
                        :
                          <Iconify icon="iconamoon:trend-down" className="red" width={isOnSmallScreen ? 24 : 40}/>
                        }
                      </span>
                      <span className="percentage-number">
                        {difference ? fShortenNumber((Math.abs(difference) / number) * 100) : 0}%
                      </span>
                    </Typography>
                  </Stack>
                }
              </Box>
              {!ranked &&
                <Typography className="small-text" sx={{ order: { xs: 2, sm: 'unset' } }}>
                  Past 30 days
                </Typography>
              }
              <Typography className="number" sx={{ order: { xs: 0, sm: 'unset' } }}>
                {ranked ? `${fShortenNumber(number)}th` : number === 0 ? 0 : fShortenNumber(number)}
              </Typography>
          </Box>
          <CardContent className="content">
            {type}
          </CardContent>
        </Box>
      </Box>
    </Card>
  )
}