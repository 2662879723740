import { useState, React, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
// @mui
import { FormControl, FormGroup, Checkbox, FormHelperText, FormControlLabel, Link, Stack, IconButton, InputAdornment, Alert, Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../auth/Authentication';
import StyledTextField from '../../../theme/artist/overrides/StyledTextField';
import StyledTypography from '../../../theme/artist/overrides/StyledTypography';
import StyledLoadingButton from '../../../theme/artist/overrides/StyledLoadingButton';
import Iconify from '../../../components/iconify';
import { APP_NAME, TITLE_SEPERATOR } from '../../../hooks/globals';
import useConsoleLog from '../../../hooks/useConsoleLog';

// ----------------------------------------------------------------------

export default function ArtistLoginForm(props) {
  const navigate = useNavigate();
  const log = useConsoleLog;

  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState();
  const [isForgettingPassword, setIsForgettingPassword] = useState(false);

  const auth = useAuth();

  const theme = useTheme();
  
  const handleCheckedChange = (e) => {
    const formValuesb = {...formValues};
    formValuesb[e.target.name] = e.target.checked;

    setFormValues(formValuesb);
    log(formValuesb);
  }

  const handleLogin = () => {
    let refresh = false;
    if (props.isOnCheckout)
      refresh = true

    auth.artistLogin(formValues, refresh);
    log(auth.user?.authed);
  };

  const handleRegister = () => {
    auth.registerFree(formValues);
    log(auth.user?.authed);
  };

  const handlePasswordReset = () => {
    auth.passwordReset(formValues);
  }

  const handleValChange = (e) => {
    const formValuesb = {...formValues};
    formValuesb[e.target.name] = e.target.value;

    setFormValues(formValuesb);
  }

  useEffect(() => {    
    const cookies = new Cookies();

    const user = cookies.get('user');


    if (user) {
      auth.getAndUpdateUser();

      if (Boolean(user.subscription)) {
        navigate('/artist/statistics', true);
      } else {
        navigate('/artist/orders', true);
      }
    }
  }, []);

  useEffect(() => {
    auth.setErrors({});
  }, [props.register, isForgettingPassword])


  if (!auth.isAuthed()) {
    return (
      <>
      <Helmet>
        {!props.isOnCheckout &&
          <title>{props.register ? 'Register' : 'Login'} {TITLE_SEPERATOR} {APP_NAME}</title>
        }
      </Helmet>
        <form>
          <Stack spacing={3} sx={{ marginTop: !props.isOnCheckout ? "45px" : 0 }}>
            {props.register &&
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Alert severity='warning'>Please register with the email address used during the order placement at YouGrow.</Alert>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <StyledTypography>First name</StyledTypography>
                    <StyledTextField
                    fullWidth name="first_name" value={formValues?.first_name} onChange={handleValChange} error={auth.errors?.first_name} helperText={auth.errors?.first_name} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <StyledTypography>Last name</StyledTypography>
                    <StyledTextField
                    fullWidth name="last_name" value={formValues?.last_name} onChange={handleValChange} error={auth.errors?.last_name} helperText={auth.errors?.last_name} />
                  </Grid>
                </Grid>
              </Box>
            }
            <Box>
              <StyledTypography>Email</StyledTypography>
              <StyledTextField
              fullWidth name="email" value={formValues?.email} onChange={handleValChange} error={auth.errors?.email} helperText={auth.errors?.email} />
            </Box>

          {!isForgettingPassword &&
          <Box>
            <StyledTypography>Password</StyledTypography>
            <StyledTextField
            fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleValChange}
              error={auth.errors?.password}
              helperText={auth.errors?.password}  />
          </Box> }
          {props.register &&
          <Box>
            <StyledTypography>Password Confirmation</StyledTypography>
            <StyledTextField
            fullWidth
              name="password_confirmation"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleValChange}
              error={auth.errors?.password}
              helperText={auth.errors?.password}  />
          </Box> }
            {auth.errors?.general?.length ? <Alert severity='error'>{auth.errors.general}</Alert> : ''}
        </Stack>
        {!props.register && !isForgettingPassword ?
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel control={<Checkbox value={formValues?.rememberMe || false} name="rememberMe" onChange={handleCheckedChange} />} label={<StyledTypography>Remember me</StyledTypography>} />
            <Link sx={{cursor: 'pointer'}} variant="subtitle2" underline="hover" onClick={() => setIsForgettingPassword(true)}>
              Forgot password?
            </Link>
          </Stack>
          <StyledLoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} loading={auth.loading}>
            Login
          </StyledLoadingButton>
        </> :
        isForgettingPassword ?
        <>
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
            <Link sx={{cursor: 'pointer'}} variant="subtitle2" underline="hover" onClick={() => setIsForgettingPassword(false)}>
              Return to login
            </Link>
          </Stack>
          {auth.passwordResetResponse && <Alert severity={auth.passwordResetResponse.severity}>{auth.passwordResetResponse.message}</Alert>}
          <StyledLoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handlePasswordReset} loading={auth.loading} sx={{mt: 3}}>
            Send password reset link
          </StyledLoadingButton>
        </>
        :
        <>
          <FormControl error={auth.errors?.privacyAccept && true}>
              <FormGroup>
                  <FormControlLabel control={<Checkbox  sx={{color: "#9d9d9d"}} onChange={handleCheckedChange} name="privacyAccept" checked={formValues?.privacyAccept || false} />} label={<StyledTypography>By checking this checkbox, you agree to our <a style={{color: theme.palette.platform.spotify}}  rel="noreferrer" href="https://www.yougrowpromo.com/privacy?utm_source=yougrowplus" target="_blank">privacy policy</a></StyledTypography>} />
              </FormGroup>
              {auth.errors?.privacyAccept &&
                  <FormHelperText>{auth.errors?.privacyAccept}</FormHelperText>
              }
          </FormControl>
          <StyledLoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleRegister} loading={auth.loading} sx={{mt: 3}}>
            Register
          </StyledLoadingButton>
        </>
      }
      </form>
      </>
    );
  }
}
