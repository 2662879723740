import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';

const StyledLoadingButton = styled(LoadingButton)(({ sx, theme }) => ({
    backgroundColor: theme.palette?.platform.spotify || "#1DD263",
    color: "#000",
    fontSize: "20px",
    fontWeight: 400,
    borderRadius: "5px",
    textTransform: "none",
    padding: "10.5px",
    height: "45px",
    "&:hover": {
        backgroundColor: theme.palette.primary.light
    },
    ...sx
}))

export default StyledLoadingButton;