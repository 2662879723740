import { MenuItem } from "@mui/base";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { arrayOf, string } from "prop-types";
import { Link } from "react-router-dom";
import { useAuth } from "../../../auth/Authentication";
import useCheckMatchingArrayValues from "../../../hooks/useCheckMatchingArrayValues";
import LockedMenuItem from "./LockedMenuItem";

SubMenu.propTypes = {
  config: arrayOf({
    title: string,
    path: string
  })
}

export default function SubMenu({config}) {
  const checkMatchingPermissions = useCheckMatchingArrayValues();
  const auth = useAuth();

  return (
    <Box className="sub-menu bg-neutral">
      <Stack spacing={2} justifyContent="start">
        {config?.map((item) => (
          (!item.permissions || (
            auth.user?.subscription?.plan?.permissions && 
            checkMatchingPermissions(auth.user?.subscription?.plan?.permissions, item.permissions)
            && auth.user.subscription.status !== "cancelled"
            )) 
            ?
          <Link to={item.path}>
            <Typography variant="body1" textAlign="left" className="contrast-neutral-bg">
              {item.title}
            </Typography>
          </Link>
          :
          <LockedMenuItem 
            page={item}
            toolTipTitle={item.availableAt}
          />
        ))}
      </Stack>
    </Box>
  )
}