/* eslint-disable camelcase */

import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@mui/material";
import { array, arrayOf, func, shape, string } from "prop-types";
import Scrollbar from "../../../components/scrollbar";

ArtistSearchList.propTypes = {
  data: arrayOf(
    shape({
      avatar: string,
      name:string,
      site_url: string,
      songstats_artist_id: string
    })
  ),
  onSetSelected: func,
  selecterArtist: string
}

export default function ArtistSearchList({data, onSetSelected, selectedArtist})  {
  const theme = useTheme();

  return (
    <List sx={{ width: '100%', background: theme.palette.common.white }} className="artist-search-list">
      <Scrollbar sx={{height: "300px"}}>
        {data.map(({avatar, name, songstats_artist_id}) => (
          <>
            <ListItem alignItems="flex-start" className={`c-pointer ${songstats_artist_id === selectedArtist && 'selected-artist-search-result'}`} 
            onClick={() => onSetSelected(songstats_artist_id)}>
              <ListItemAvatar>
                <Avatar alt={name} src={avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography>{name}</Typography>}
                secondary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {songstats_artist_id === selectedArtist ? 
                          "Selected" 
                        :
                          "Click to select"
                      }
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
        ))}
      </Scrollbar>
    </List>
  )
}