import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
// @mui
import { styled } from '@mui/material/styles';
import { useAuth } from '../../auth/Authentication';
import ArtistThemeProvider from '../../theme/artist';
import useResponsive from '../../hooks/useResponsive';
import ResponsiveAppBar from './nav/ResponsiveAppBar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({theme}) => ({
  minHeight: '100%',
  overflow: 'hidden',
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const auth = useAuth();

  useEffect(() => {    
    if (!auth.isAuthed()) {
      navigate('/login', true);
      window.location.reload(true);
    }
    
    window.scrollTo(0, 0);
  }, [pathname]);

  if (auth?.user?.token) {
    return (
      <ArtistThemeProvider>
        <StyledRoot>
          
          <ResponsiveAppBar/>

          {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}

          <Main>
            <Outlet />
          </Main>

        </StyledRoot>
      </ArtistThemeProvider>
    );
  }
}
