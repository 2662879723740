import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
// @mui
import { styled } from '@mui/material/styles';
import { Snackbar, Alert, Paper, Box, Grid } from '@mui/material';
import ThemeProvider from '../../theme/admin';
//
import Nav from './nav';
import { useSnackHandler } from '../../hooks/useSnackHandler';
import { useAuth } from '../../auth/Authentication';
import useConsoleLog from '../../hooks/useConsoleLog';
import Iconify from '../../components/iconify/Iconify';
import Logo from '../../components/logo/Logo';

// ----------------------------------------------------------------------

const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const cookies = new Cookies();
  const [open, setOpen] = useState(false);
  const [user, ] = useState(cookies.get('user'));
  const navigate = useNavigate();
  const log = useConsoleLog;

  useEffect(() => {    
    log('user');
    log(user);

    if (!user) {
      navigate('/admin/login', true);
    } else if (user?.role === 'artist') {
      navigate('/artist/statistics', true);
    }
  }, []);

  if (user?.token) {
    return (
      <ThemeProvider>
        <Paper sx={{p: 1, width: "100%"}}>
          <Grid container alignItems="center" justifyContent='space-between'>
              <Logo/>
              <Iconify onClick={() => setOpen(!open)} icon="ci:hamburger-lg"/>
          </Grid>
        </Paper>
        <StyledRoot>
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          <Main>
            <Outlet />
          </Main>
        </StyledRoot>
      </ThemeProvider>
    );
  }
}
