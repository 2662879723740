const explainations = [
  {
    heading: 'What is the best way to generate an image?',
    text: 'To generate an image, fill in a prompt in the input-field. To get the best result, make sure to explain the image that you want to generate as elaborate as possible. The more information you provide, the better.',
  },
  {
    heading: 'Am I free to use my generated image in any way I would like?',
    text: 'All images that have been generated by you belong to you. You are free to use them however you like!',
  },
  {
    heading: 'How does the credit system work?',
    text: 'Every month you will get 50 credits, which you need to generate images. Every image that you generate will cost you one credit. If you choose to generate more that 1 image, for example three, this will cost you three credits. If you run out of credits, you can always upgrade your credit amount.',
  },
  {
    heading: 'How do I add images to my personal gallery?',
    text: 'After generating an image, the save option will appear below the image. This option will add the image to your personal gallery.',
  },
  {
    heading: 'How do I make variations of the images that I have generated?',
    text: 'After generating an image, the variations option will appear above the image. With this option, three different variations of the image will be generated.',
  },
  {
    heading: "What are credits on the house?",
    text: "Each image you generate costs one credit. For example, if you create three images, it will deduct three credits from your account. We provide all users with 50 free credits to use for each month, on the house! At the start of each month, this amount will be added to your account. If you need more credits, you can purchase them at a small fee. So, enjoy creating images with your 50 free credits each month as a token of our appreciation for being a part of our community!"
  }
]

export default explainations;