import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Stack, Typography, Grid, Divider, useTheme, Box } from '@mui/material';
import useConsoleLog from '../../hooks/useConsoleLog';
import useResponsive from '../../hooks/useResponsive';
import { useGetFormattedTextDate } from '../../hooks/useGetFormattedDate';
import useGetListing from '../../api/useGetListing';
import DataCard from './activity-feed-sections/DataCard';
import StyledBox from '../../theme/artist/overrides/StyledBox';
import { APP_NAME, TITLE_SEPERATOR } from '../../hooks/globals';
import Footer from '../../layouts/loggedout/Footer';
import useGetSkeletons from '../../hooks/useGetSkeletons';
import { useAuth } from '../../auth/Authentication';
import AddSpotifyId from './stats-sections/AddSpotifyId';
import SearchArtist from './stats-sections/SearchArtist';
import SubscriptionNotice from '../../modals/SubscriptionNotice';

export default function ActivityFeed() {
  const [activityData, setActivityData] = useState([]);
  const { getListing } = useGetListing();
  const theme = useTheme();
  const getFormattedDate = useGetFormattedTextDate();
  const isOnSmallScreen = useResponsive('down', 'md', 'lg');
  const log = useConsoleLog;
  const [isLoading, setIsLoading] = useState(true);
  const [getSkeletons] = useGetSkeletons();
  const auth = useAuth();
  const { isApp } = useAuth();

  function initFeed() {
    getListing('artist/activity-feed')
      .then((response) => {
        log(response);
        const groupedByDate = response.data.data.reduce((result, item) => {
          const date = item.activity_date;
          if (!result[date]) {
            result[date] = [];
          }
          result[date].push(item);

          return result;
        }, {});

        setActivityData(groupedByDate);
      })
      .catch((err) => {
        log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    log('feed');
    initFeed();
  }, []);

  const defaultDividerStyle = {
    width: '3px',
    backgroundColor: theme.palette.primary.main,
    margin: { md: 'auto', xs: 'initial' },
  };

  if (auth.user?.subscription?.plan?.permissions?.includes('can-analyse-music')
      && auth.user?.subscription?.status !== "cancelled") {
    if (!auth.user?.spotify_id) {
      return (
        <StyledBox sx={{ marginTop: { xs: '50px', md: '90px' } }}>
          <Box sx={{ width: { md: '50%', xs: '100%' } }}>
            <SearchArtist />
            <AddSpotifyId />
          </Box>
        </StyledBox>
      );
    }
    return (
      <>
        <StyledBox className="container" sx={{ maxWidth: '1100px' }}>
          <Helmet>
            <title>
              Milestones {TITLE_SEPERATOR} {APP_NAME}
            </title>
          </Helmet>
          <Stack spacing={3}>
            <Typography variant="h1" gutterBottom sx={{ color: theme.palette.common.black }}>
              Your milestones
            </Typography>
            {isLoading
              ? getSkeletons(1, '100%', '500px')
              : Object.entries(activityData).map(([key, value], index) => {
                  return (
                    <Grid container>
                      <Grid item xs={1}>
                        {index === 0 && (
                          <Divider
                            sx={{
                              ...defaultDividerStyle,
                              height: '50px',
                            }}
                          />
                        )}
                        {!isOnSmallScreen && (
                          <Typography
                            variant="h4"
                            textAlign={{ xs: 'left', md: 'center' }}
                            sx={{ background: theme.palette.common.light }}
                          >
                            {getFormattedDate(key)}
                          </Typography>
                        )}
                        <Divider
                          sx={{
                            ...defaultDividerStyle,
                            height: '100%',
                          }}
                        />
                      </Grid>
                      <Grid item xs={11} sx={{ pl: { md: '50px' } }}>
                        <Stack spacing={6.25}>
                          {isOnSmallScreen && (
                            <Typography
                              variant="h4"
                              textAlign={{ xs: 'left', md: 'center' }}
                              sx={{ background: theme.palette.common.light }}
                            >
                              {getFormattedDate(key)}
                            </Typography>
                          )}
                          {value.map((data) => (
                            <DataCard
                              source={data.source}
                              type={data.activity_type}
                              text={data.activity_text}
                              avatar={data.activity_avatar}
                              trackInfo={data.track_info}
                              url={data.activity_url}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  );
                })}
          </Stack>
        </StyledBox>
        <Footer />
      </>
    );
  }

  if (isApp) {
    return <SubscriptionNotice />;
  }

  return <Navigate to={'/checkout'} />;
}
