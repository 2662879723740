import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@mui/material';

RowLayout.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string || PropTypes.node,
}

export default function RowLayout({label, value}) {
  return (
    <>
      <Grid xs={6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid xs={6}>
        <Typography sx={{overflowWrap: "anywhere"}}>{value}</Typography>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{my: 3}} />
      </Grid>
    </>
  )
}