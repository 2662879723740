import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import 'dayjs/locale/nl';
import { Alert, Box, Button, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import useGetListing from "../../../api/useGetListing";
import useConsoleLog from "../../../hooks/useConsoleLog";
import useGetFormattedDate, { useGetFormattedTextDate } from "../../../hooks/useGetFormattedDate";
import { fShortenNumber } from "../../../utils/formatNumber";
import InvoiceListingLayout from "../auth/my-account/invoices/InvoiceListingLayout";
import OverviewGraph from "./sections/OverviewGraph";
import StatRankCard from "./sections/StatRankCard";
import Switchers from "./sections/Switchers";
import SwitchersTwo from "./sections/SwitchersTwo";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import Diff from "./sections/Diff";
import { useAuth } from "../../../auth/Authentication";
import AddIdForm from "../forms/AddIdForm";
import RegisterSocialAccordion from "./sections/register-accounts/Accordion";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import Arrow from "../../../components/arrow-bg/Arrow";

function InsufficientInfo() {
  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography className="badge">
            Things to note
          </Typography>
          <Typography>
            <br/>
            <br/>
            Before linking your Instagram account to your personal dashboard, there are a couple of things to take note of.
            <br/>
            <br/>
            1) Please make sure that your account is set up as a business or creator account.
            <br/>
            <br/>
            2) If this is your first time tracking your data through our dashboard, it could take a couple of days for your data to show up in your dashboard
            <br/>
            <br/>
            3) Below you can add you social media account. Please make sure that you add the correct account. An account can only be added once.
            <br/>
            <br/>
          </Typography>
          <RegisterSocialAccordion
              icon={"insta"}
              name={"Instagram"}
              idName={"instagram"}
            />
        </Grid>
      </Grid>
    </>
  )
}

export default function Instagram() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [labelsMonth, setLabelsMonth] = useState([]);
  const [followers, setfollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [defaultOverview, setDefaultOverview] = useState(true);
  const [changablefollowers, setChangablefollowers] = useState([]);
  const [changableFollowing, setChangableFollowing] = useState([]);
  const [currentFollowers, setCurrentfollowers] = useState(0);
  const [prevFollowers, setPrevfollowers] = useState(0);
  const [currentFollowing, setCurrentFollowing] = useState(0);
  const [prevFollowing, setPrevFollowing] = useState(0);
  const [currentMedia, setCurrentMedia] = useState(0);
  const [prevMedia, setPrevMedia] = useState(0);
  const [currentAverageLikes, setCurrentAverageLikes] = useState(0);
  const [prevAverageLikes, setPrevAverageLikes] = useState(0);
  const [currentAverageComments, setCurrentAverageComments] = useState(0);
  const [prevAverageComments, setPrevAverageComments] = useState(0);
  const [currentGrowthStat, setCurrentGrowthStat] = useState("Followers");
  const [followersMonth, setfollowersMonth] = useState([]);
  const [gainMonth, setGainMonth] = useState([]);
  const [followersGainPerMonth, setfollowersGainPerMonth] = useState([]);
  const [ranks, setRanks] = useState({
    followers: 0,
    following: 0,
    media: 0,
    engagement_rate: 0,
  });
  const [labels, setLabels] = useState([]);
  const [labelsChangable, setLabelsChangable] = useState([]);
  const {getListing} = useGetListing();
  const log = useConsoleLog;
  const getFormattedDate = useGetFormattedDate();
  const formatDate = useGetFormattedTextDate();
  const firstStartDate = new Date();
  firstStartDate.setDate(firstStartDate.getDate() - 30);
  const lastEndDate = new Date();
  firstStartDate.setDate(firstStartDate.getDate() - 30);
  lastEndDate.setDate(lastEndDate.getDate() - 1);
  const [startDate, setStartDate] = useState(getFormattedDate(firstStartDate));
  const [endDate, setEndDate] = useState(getFormattedDate(lastEndDate));
  const auth = useAuth();

  const platform = 'instagram';
  document.documentElement.style.setProperty('--custom-border-color', theme.palette.platform[platform]);

  const changefollowers = (amounts) => {
    setChangablefollowers([{
      name: "Followers",
      data: amounts,
      type: 'area',
      fill: 'gradient',
      stroke: {
        curve: 'smooth',
      }
    }]);
  }

  const changeStartDate = (value, theLables, thefollowers, theFollowing) => {
    log(thefollowers);
    const startDateb = getFormattedDate(new Date(value));
    setStartDate(startDateb);
    const start = theLables.indexOf(startDateb);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedfollowers = thefollowers.slice(end, start);
    changefollowers(changedfollowers);
    setLabelsChangable(changedLabels);
  }

  const changeEndDate = (value, theLables, thefollowers, theFollowing) => {
    log(thefollowers);
    const endDateb = getFormattedDate(new Date(value));
    setEndDate(endDateb);
    const start = theLables.indexOf(startDate);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedfollowers = thefollowers.slice(end, start);
    changefollowers(changedfollowers);
    setLabelsChangable(changedLabels);
  }

  const fetchPlatformData = () => {
    getListing(`artist/social-media/instagram/${auth.user.instagram_id}`)
    .then((res) => {
      log("res");
      log(res);
      const datab = res.data.data.daily;
      const dataWithId = datab.map((item, key) => {
        item = {
          id: key,
          ...item,
          date: formatDate(item.date),
        };

        return item
      });

      const slicedData = datab.slice(0, 30);

      if (slicedData.length >= 30) {
        setData(slicedData.map((theData, index) => {
          let followerDiff = 0;
          let followerDiffClass = 'neutral';
          let mediaDiff = 0;
          let mediaDiffClass = 'neutral';

          if (index + 1 < 30) {
            followerDiff = theData.followers - slicedData[index+1].followers;
            followerDiff = ` ${followerDiff >-1 ? "+" : "-"}${Math.abs(followerDiff)}`;
            followerDiffClass = followerDiff >-1 ? "positive" : "negative";

            mediaDiff = theData.media - slicedData[index+1].media;
            mediaDiff = ` ${mediaDiff >-1 ? "+" : "-"}${Math.abs(mediaDiff)}`;
            mediaDiffClass = mediaDiff >-1 ? "positive" : "negative";
          }

          return {
            id: theData.id,
            date: theData.date,
            followers: <Diff
                          classy={followerDiffClass}
                          content={theData.followers ? theData.followers : 0}
                          diff={followerDiff}
                        />,
            media: <Diff
                          classy={mediaDiffClass}
                          content={theData.media ? theData.media : 0}
                          diff={mediaDiff}
                        />,
          }
        }));
      }

      log("dataWithId");
      log(dataWithId);

      const followersb = datab.map((item) => (item.followers));
      const followingb = datab.map((item) => (item.following));
      const mediab = datab.map((item) => (item.media));
      const averageLikesb = datab.map((item) => (item.avg_likes));
      const averageCommentsb = datab.map((item) => (item.avg_comments));

      const followersbOneMonth = followersb.slice(0, 30);
      const followersbTwoMonths = followersb.slice(0, 60);

      const avgLikesOneMonth = averageLikesb.slice(0, 30);
      const avgLikesTwoMonths = averageLikesb.slice(0, 60);

      const avgCommentsOneMonth = averageCommentsb.slice(0, 30);
      const avgCommentsTwoMonths = averageCommentsb.slice(0, 60);

      const postsOneMonth = mediab.slice(0, 30);
      const postsTwoMonths = mediab.slice(0, 60);

      const followersbGainMonth = followersbOneMonth.map((item, key) => {
        return followersbOneMonth[key] - followersbTwoMonths[key];
      });

      const avgLikesGainMonth = avgLikesOneMonth.map((item, key) => {
        return avgLikesOneMonth[key] - avgLikesTwoMonths[key];
      });

      const avgCommGainMonth = avgCommentsOneMonth.map((item, key) => {
        return avgCommentsOneMonth[key] - avgCommentsTwoMonths[key];
      });

      const avgPostGainMonth = postsOneMonth.map((item, key) => {
        return postsOneMonth[key] - postsTwoMonths[key];
      });

      const monthCount = Math.floor(followersb.length / 30); // Get the total number of complete months in the array
      const followersGainPerMonth = [];

      for (let i = 0; i < monthCount; i+=1) {
        const startIndex = i * 30; // Start index of each month
        const endIndex = (i + 1) * 30; // End index of each month

        const startSubs = followersb[startIndex];
        const endSubs = followersb[endIndex - 1];
        const change = startSubs - endSubs;

        followersGainPerMonth.push(change);
      }

      log("followersGainPerMonth");
      log(followersGainPerMonth);


      changefollowers(followersb);

      log("changedfollowers");
      log(followersb);

      setfollowers([{
        name: "followers",
        data: followersb,
        type: 'area',
        fill: 'gradient',
        stroke: {
          curve: 'smooth',
        }
      }]);

      const gainChartSettings = {
        height: 100,
        type: 'line',
        stroke: {
          curve: 'smooth',
      }
      }

      log("followersbGainMonth");
      log(followersbGainMonth);
      setGainMonth({
        "Followers": {
          name: "Followers gained",
          data: followersbGainMonth,
          ...gainChartSettings
        },
        "Avg. likes": {
          name: "Avgerage likes",
          data: avgLikesGainMonth,
          ...gainChartSettings
        },
        "Avg. comm": {
          name: "Average comments",
          data: avgCommGainMonth,
          ...gainChartSettings
        },
        "Posts": {
          name: "Posts",
          data: avgPostGainMonth,
          ...gainChartSettings
        },
      });

      setfollowersGainPerMonth([
        {
          name: "Followers",
          data: followersGainPerMonth.slice(0, 9),
          type: 'bar',
        },
    ]);


      setfollowersMonth([{
        name: "Followers gained",
        data: followersb,
        type: 'bar',
      }]);

      const labelsb = datab.map((item) => (item.date));

      const theLabelsMonth = [];
      labelsb.forEach((label, key) => {

        if (key % 30 === 0) {
          theLabelsMonth.push(label);
        }
      });

      log("theLabelsMonth");
      log(theLabelsMonth.slice(0, 9));
      setLabelsMonth(theLabelsMonth.slice(0, 9));


      setLabels(labelsb);
      setLabelsChangable(labelsb);
      setCurrentfollowers(followersb[0]);
      localStorage.setItem("ig-followers", followersb[0]);
      auth.setSocialStats({...auth.socialStats, instagram: followersb[0]})
      setPrevfollowers(followersb[1]);
      localStorage.setItem("ig-followers-prev", followersb[1]);
      auth.setPrevSocialStats({...auth.socialStats, instagram: followersb[1]})
      setCurrentFollowing(followingb[0]);
      setPrevFollowing(followingb[1]);
      setCurrentMedia(mediab[0]);
      setPrevMedia(mediab[1]);
      setCurrentAverageLikes(averageLikesb[0]);
      setPrevAverageLikes(averageLikesb[1]);
      setCurrentAverageComments(averageCommentsb[0]);
      setPrevAverageComments(averageCommentsb[1]);
      setRanks(res.data.data.ranks);
      changeStartDate(firstStartDate, labelsb, followersb, followingb);
      changeEndDate(new Date(), labelsb, followersb, followingb);
    })
    .catch((err) => {
      log(err);
    });
  }

  const handleGrowthStatChange = (stat) => {
    setCurrentGrowthStat(stat)
  }



  useEffect(() => {
    fetchPlatformData();
  },[auth.user.instagram_id])

  const rankCardOne = useRef(null);
  const rankCardTwo = useRef(null);


  return (
    <>
      <Helmet>
        <title>Instagram {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
      {auth.user.instagram_id ?
      <>
        <Stack gap={3}>
          <Typography variant="h4">
            Total followers
          </Typography>
          <Arrow platformId={platform}/>
          <StyledPaper sx={{
            px: {md: 5, xs: 0},
            zIndex: 999,
            backgroundColor: theme.palette.grey[200],
            borderRadius: {xs: "0", md: "10px"}}}
          >
            <Switchers
              onChangeStartDate={changeStartDate}
              onChangeEndDate={changeEndDate}
              theStartDate={startDate}
              theEndDate={endDate}
              theSubs={followers}
              theViews={[{data: ""}]}
              theLabels={labels}
            />
            <OverviewGraph
                data={changablefollowers}
                labels={labelsChangable}
                color={theme.palette.primary.main}
            />
          </StyledPaper>
          <Grid container columnSpacing={5} rowSpacing={{ xs: 0, sm: 2, md: 3 }} className="other-stats">
            <Grid item xs={12}>
              <Typography variant="h4">
                Other stats
              </Typography>
            </Grid>
            <Grid ref={rankCardOne} item xs={12} sm={6} lg={3}>
                <StatRankCard
                  type="Followers"
                  number={currentFollowers}
                  prevNumber={prevFollowers}
                />
            </Grid>
            <Grid ref={rankCardTwo} item xs={12} sm={6} lg={3}>
                <StatRankCard
                  type="Posts"
                  number={currentMedia}
                  prevNumber={prevMedia}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <StatRankCard
                  type="Average likes"
                  number={currentAverageLikes}
                  prevNumber={prevAverageLikes}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <StatRankCard
                  type="Avg. comm."
                  number={currentAverageComments}
                  prevNumber={prevAverageComments}
                />
            </Grid>
          </Grid>
        </Stack>
        <Grid container columnSpacing={5} className="stats-wrapper">
          <Grid item xs={12} lg={7} sx={{ position: 'relative', paddingTop: '0' }}>
            <Typography variant="h4">
              Monthly gained
            </Typography>
              {Boolean(followersGainPerMonth[0]?.data?.length > 4) ?
                <>
                  <Arrow platformId={platform}/>
                  <Box className="mt-2 graph custom-border">
                    <OverviewGraph data={followersGainPerMonth} labels={labelsMonth} color={theme.palette.platform.spotify} bg={theme.palette.grey[200]}
                    height={490}/>
                  </Box>
                </>
                :
                <Alert className="mt-2" severity="error">
                  Insufficient data
                </Alert>
              }
          </Grid>
          <Grid item xs={12} lg={5} className="hide-stack table-data" sx={{ marginTop: { xs: '24px', md: 0 } }}>
            <Typography variant="h4">
              Your Growth the past month
            </Typography>
            <Box className="mt-2">
              {Boolean(data?.length) ?
                <InvoiceListingLayout
                    name="Growth"
                    TABLE_HEAD={[
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: 'followers', label: 'Followers', alignRight: false },
                    { id: 'media', label: 'Posts', alignRight: false },
                    ]}
                    data={data}
                    objectKeys={['id']}
                    showCustomBorder
                    platform={platform}
                />
              :
                <Alert severity="error">
                  Insufficient data
                </Alert>
              }
            </Box>
          </Grid>
        </Grid>
      </>
      :
        <InsufficientInfo/>
      }
    </>
  )
}
