import { React, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { TextField, Grid, Switch, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Alert, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useFormHandler} from '../../hooks/useFormHandler';
import Iconify from '../../components/iconify/Iconify';
// components

UpdateArtistForm.propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    apiDone: PropTypes.number.isRequired,
    setApiDone: PropTypes.func.isRequired,
    editClose: PropTypes.func.isRequired
}


export default function UpdateArtistForm({id, data, apiDone, setApiDone, editClose}) {
    const fHandler = useFormHandler(`admin/artists/${id}`);

    useEffect(() => {
        fHandler.setFormValues({...data, first_name: data.firstName, last_name: data.lastName, spotify_id: data.spotifyId});
    }, []);

    useEffect(() => {
        if (fHandler.successMsg) {
            setApiDone(apiDone + 1);
        }
    }, [fHandler.successMsg]);

    return (
    <Grid container spacing={3}>
        <Grid item md={2}>
            <TextField fullWidth name="first_name" label="First name" value={fHandler.formValues?.first_name} onChange={fHandler.handleValChange} error={fHandler.errors?.first_name} helperText={fHandler.errors?.first_name} />
        </Grid>
        <Grid item md={2}>
            <TextField fullWidth name="last_name" label="Last name" value={fHandler.formValues?.last_name} onChange={fHandler.handleValChange} error={fHandler.errors?.last_name} helperText={fHandler.errors?.last_name} />
        </Grid>
        <Grid item md={2}>
            <TextField fullWidth name="email" label="Email" value={fHandler.formValues?.email} onChange={fHandler.handleValChange} error={fHandler.errors?.email} helperText={fHandler.errors?.email} />
        </Grid>
        <Grid item md={2}>
            <TextField
                fullWidth
                name="password"
                label="Password"
                onChange={fHandler.handleValChange} 
                value={fHandler.formValues?.password}
                error={fHandler.errors?.password} 
                helperText={fHandler.errors?.password} />
        </Grid>
        
        <Grid item md={2}>
            <TextField fullWidth name="spotify_id" label="Spotify ID" value={fHandler.formValues?.spotify_id} onChange={fHandler.handleValChange} error={fHandler.errors?.spotify_id} helperText={fHandler.errors?.spotify_id} />
        </Grid>

        <Grid item md={2}>
            <LoadingButton sx={{height: 56, border: 'none'}} fullWidth size="large" type="submit" variant="contained" onClick={() => fHandler.handleUpdateSubmit()} loading={fHandler.isSubmitting}>
                Submit
            </LoadingButton>
        </Grid>

        <Grid item md={12}>
            {fHandler.successMsg && <Alert>{fHandler.successMsg}</Alert>}
            {fHandler.errors?.messageIse && <Alert severity='error'>{fHandler.errors?.messageIse}</Alert>}
        </Grid>
        <Grid item md={12}>
            <IconButton onClick={() => editClose()} sx={{margin:"auto", display: 'block'}} aria-label='Cancel'>
                <Iconify icon="system-uicons:cross-circle"/>
            </IconButton>
        </Grid>
    </Grid>
    )
}