import { Typography } from "@mui/material";
import styled from '@emotion/styled';


const StyledTypography = styled(Typography)(({sx, theme}) => ({
    color: "#FFFDFD",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    "& a": {
        color: theme.palette.platform.spotify,
        "&:hover": {
            textDecoration: "underline",
            color: theme.palette.primary.light
        }
    },
    ...sx
}));

export default StyledTypography;