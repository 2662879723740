import { Box, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../../auth/Authentication';
import AccountsAccordions from '../../social-media/sections/register-accounts/AccountsAccordions';
import SubscriptionNotice from '../../../../modals/SubscriptionNotice';

export default function AddSocials() {
  const auth = useAuth();

  if (auth.user?.subscription?.plan?.permissions?.includes('can-analyse-socials')
  && auth.user?.subscription?.status !== "cancelled") {
    return (
      <>
        <Typography variant="h4">Social media accounts</Typography>
        <Box className="account-social-register bg-neutral">
          <AccountsAccordions />
        </Box>
      </>
    );
  }
}
