import React, { forwardRef } from 'react';
import { Box, Typography } from "@mui/material";
import { string } from "prop-types";

const CampaignStatus = forwardRef(({ status }, ref) => {
  return (
    <Box className="campaign-extra-info-wrapper">
      <Typography className="extra-info-title showmore-title" variant="body1">
        Campaign status
      </Typography>
      <Box className="green-box mt-1" ref={ref}>
        <Typography className="text-black">
          {status}
        </Typography>
      </Box>
    </Box>
  );
});

CampaignStatus.propTypes = {
  status: string.isRequired,
};

export default CampaignStatus;

