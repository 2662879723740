import React, { useEffect, useState, useMemo } from "react";
import { useTheme } from "@emotion/react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  useZoomPanContext,
  ZoomableGroup
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { Tooltip, Box } from "@mui/material";
import useConsoleLog from "../../../../hooks/useConsoleLog";

const geoUrl = "/assets/topo.json";

const SpotifyHeatMap = (props) => {

  const log = useConsoleLog;
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const theme = useTheme();
  const maxScale = 10;

  useEffect(() => {
    if (props.data?.monthly_listeners?.length) {
        // set monthly listeners
        const sortedCities = sortBy(props.data.monthly_listeners, (o) => -o.current_listeners);
        setMaxValue(sortedCities[0].current_listeners);
        setData(sortedCities);
        log(props.data)

    }
  }, [props.data]);

  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0, 24]),
    [maxValue]
  );

  return (
        <>
            <Geographies 
            geography={geoUrl}
            >
                {({ geographies }) =>
                    geographies.map((geo) => (
                        <Geography 
                        key={geo.rsmKey} 
                        geography={geo} 
                        fill={theme.palette.info.main} 
                        />
                    ))
                }
            </Geographies>
            {data.map((city) => {
              if (city.city_lng === null) {
                switch(city.city_name) {
                  case "New York City":
                    city.city_lng = "-75.000000";
                    city.city_lat = "43.000000";
                  break; 
                  case "Stodsbuoye":
                    city.city_lng = "10.01459";
                    city.city_lat = "62.03415";
                  break; 
                  case "Frankfurt am Main":
                    city.city_lng = "8.682127";
                    city.city_lat = "50.110924";
                  break; 
                  case "The Bronx":
                    city.city_lng = "-73.865433";
                    city.city_lat = "40.837048";
                  break; 
                  default: 
                    city.city_lng = "-75.000000";
                    city.city_lat = "43.000000";
                }
              }
                return (
                <Tooltip title={`${city.city_name} | Current listeners: ${city.current_listeners}` }>
                    <Marker key={city.city_name} coordinates={[city.city_lng, city.city_lat]}>
                        <circle fill={popScale(city.current_listeners) > maxScale ? theme.palette.error.main : theme.palette.platform.spotify} style={{opacity: ".46"}} r={Math.min(popScale(city.current_listeners), maxScale)} />
                    </Marker>
                </Tooltip>
                );
            })}
          </>
  );
};

export default SpotifyHeatMap;
