import useResponsive from "../../hooks/useResponsive";

export default function Arrow(props) {
    const isOnSmallScreen = useResponsive("down", "md", "lg");

    if (isOnSmallScreen) {
        return null;
    }

    const platformId = props.platformId || 'spotify';

    return (
        <img
            style={{
                position: "absolute",
                right: "-22px",
                top: "37px",
                width: "297px",
                opacity: "0.4",
            }}
            src={`/assets/icons/platform-arrows/arrow-${platformId}.svg`}
            alt="arrow"
        />
    );
}