import {React, useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet-async';
import axios from 'axios';
import sortBy from "lodash/sortBy";
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Stack, Typography, Button, Grid, Drawer, useTheme, Alert, IconButton, Tooltip} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {Container} from '@mui/system';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import useGetListing from '../../api/useGetListing';
import useGetSkeletons from '../../hooks/useGetSkeletons';
import {API_URL, APP_NAME, TITLE_SEPERATOR} from '../../hooks/globals';
import AddIdForm from './forms/AddIdForm';
import {useAuth} from '../../auth/Authentication';
import useResponsive from '../../hooks/useResponsive';
import useSensableWord from "../../hooks/useSensableWord";
import useConsoleLog from '../../hooks/useConsoleLog';
import CurrentStats from './stats-sections/CurrentStats';
import HistoryStats from './stats-sections/HistoryStats';
import Audience from './stats-sections/Audience';
import CustomAvatar from '../../layouts/artist-dashboard/nav/CustomAvatar';
import Iconify from '../../components/iconify/Iconify';
import AudienceOverview from './stats-sections/AudienceOverview';
import Templates from './stats-sections/Templates';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import TraxsourceIcon from '../../components/traxsource-icon';

import platforms from '../../hooks/platforms';
import TopTracks from './stats-sections/top-tracks';
import '../../scss/songstats.scss';
import AddSpotifyId from './stats-sections/AddSpotifyId';
import ArtistCatalog from './stats-sections/ArtistCatalog';
import SearchArtist from './stats-sections/SearchArtist';
import StyledBox from '../../theme/artist/overrides/StyledBox';
import SubscriptionNotice from '../../modals/SubscriptionNotice';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: {md: 3, xs: 0}}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ArtistStatistics({track}) {
    const auth = useAuth();
    const { isApp } = useAuth();
    const log = useConsoleLog;
    const [value, setValue] = useState(0);
    const [topTracks, setTopTracks] = useState([]);
    const [open, setOpen] = useState(null);
    const [columns, setColumns] = useState([]);
    const [chartData, setChartData] = useState([])
    const [hIsLoading, setHIsLoading] = useState(true);
    const [hErrorMsg, setHErrorMsg] = useState();
    const [currentStats, setCurrentStats] = useState();
    const [audience, setAudience] = useState([]);
    const [audienceLoaded, setAudienceLoaded] = useState(false);
    const [countryCharts, setCountryCharts] = useState([]);
    const [topTracksLoading, setTopTracksLoading] = useState(true);
    const routeParams = useParams();
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const theme = useTheme();
    const navigate = useNavigate();
    const [getSkeletons] = useGetSkeletons();
    const getListing = useGetListing();
    const sensableWord = useSensableWord();
    const [splitHistory, setSplitHistory] = useState(true);
    const [gridsInOrder, setGridsInOrder] = useState({
        historyStats: 0,
        currentStats: 1,
        audienceMap: 2,
        topTracks: 3,
        audienceOverview: 4,
    });
    const [templates, setTemplates] = useState();
    const [templatesLoading, setTemplatesLoading] = useState(true);

    const getTemplates = () => {
        setTemplatesLoading(true);

        getListing.getListing("artist/templates").then((response) => {
            const templateRes = response.data;
            log("templateRes");
            log(templateRes);

            setTemplates(templateRes);
        }).catch((err) => {
            log("err");
            log(err);
        }).finally(() => {
            setTemplatesLoading(false);
        });
    }


    const getTopTracks = (platformId, noCache = "false") => {
        setTopTracksLoading(true);
        getListing.getListing(`artist/top-tracks/${platformId}/${noCache}`)
            .then((res) => {
                log("Toptracksz");
                log(res);
                setTopTracks(res.data.data[0].top_tracks);
            })
            .catch((err) => {
                log(err);
            })
            .finally(() => {
                setTopTracksLoading(false);
            });
    }

    const StyledSideNav = styled(Grid)(({theme}) => ({
        borderBottom: 1,
        borderColor:
            'divider',
        display: isOnSmallScreen ? 'none' : 'block',
    }));

    const StyledInnerNav = styled(Grid)(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        paddingTop: "50px",
        paddingLeft: "0px",
        paddingRight: "0px",
        zIndex: '9999',
        width: "128px",
        position: "fixed",
        height: "100%"
    }));


    const changeTemplate = (templateId) => {
        const template = templates.find((template) => template.id === templateId);

        setSplitHistory(template.splitHistoryStats);
        setGridsInOrder({
            historyStats: template.historyStatsOrder,
            topTracks: template.topTracksOrder,
            currentStats: template.currentStatsOrder,
            audienceMap: template.audienceMapOrder,
            audienceOverview: template.audienceOverviewStatsOrder,
        });
    }


    const getCurrentStatsFromSource = (platformId, noCache = "false") => {
        let url = `${API_URL}artist/current-stats/${platformId}/${noCache}`;

        if (routeParams.id) {
            url = `${API_URL}artist/track-current-stats/${platformId}/${routeParams.id}`;
        }

        const userToken = auth.userToken;

        const config = {
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };
        axios(config).then((response) => {
            // log(response.data.stats[0].data);
            const stats = {...currentStats};
            stats[platformId] = response.data.stats[0].data;
            setCurrentStats(stats);
        }).catch((error) => {
            log("error currentstats");
            log(error);

        }).finally(() => {

        });

    }

    const loadColumns = (history, platformId) => {
        const newColumns = [];
        const newSeperatedColumns = [];
        const newData = {};
        const actualData = [];

        history.map((item) => {
            Object.keys(item).map((objKey, i) => {
                if (objKey === 'date') {
                    return newData;
                }

                newData[objKey] = newData[objKey]?.length ? [...newData[objKey], item[objKey]] : [item[objKey]]
                newSeperatedColumns[objKey] = newSeperatedColumns[objKey]?.length ? [...newSeperatedColumns[objKey], item.date] : [item.date]

                return newData;
            })

            return newColumns.push(item.date);
        });

        Object.keys(newData).map((objKey, i) => {
            actualData.push({
                name: sensableWord.sensableWord(objKey),
                data: newData[objKey],
                key: objKey,
                type: 'area',
                fill: 'gradient',
                stroke: {
                    curve: 'smooth',
                }
            })

            return actualData;
        });

        log("newColumns");
        log(newColumns);
        log(actualData);

        setColumns({
            ...columns,
            [platformId]: newSeperatedColumns
        });

        setChartData({
            ...chartData,
            [platformId]: actualData.reverse()
        });
    }

    function getFormattedDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    const firstStartDate = new Date();
    firstStartDate.setDate(firstStartDate.getDate() - 30);

    const getHistoryStats = (platformId, startDate, endDate, noCache = "false") => {
        let url = `${API_URL}artist/history-stats/${platformId}/${startDate}/${endDate}/${noCache}`;

        if (routeParams.id) {
            url = `${API_URL}artist/track-history-stats/${platformId}/${routeParams.id}/${startDate}/${endDate}`;
        }

        const userToken = auth.userToken;

        const config = {
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        setHIsLoading(true);

        axios(config).then((response) => {
            const stats = response.data.stats[0].data.history;
            log(stats);
            if (stats) {
                loadColumns(stats, platformId);
            } else {
                setHErrorMsg(`historic data not being supported by ${platformId}`);
            }
        }).catch((error) => {
            log(error);
            setHErrorMsg(`We can't load the historic data at the moment. If you just signed up it could take up to a few hours before data is available. If the issue persists, please contact support.`)
        }).finally(() => {
            setHIsLoading(false);
        });
    }


    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);

        handleClose(event)
        log("newvali");
        log(newValue);
        if (event.target?.value && event.target?.value > -1) {
            newValue = event.target.value;
        }
        log("newval");
        log(platforms[newValue].id);

        getTopTracks(platforms[newValue].id);
        getCurrentStatsFromSource(platforms[newValue].id);

        if (!chartData[platforms[newValue].id]) {
            getHistoryStats(platforms[newValue].id, getFormattedDate(firstStartDate), getFormattedDate(new Date()));
        }

        if (platforms[newValue].id === "spotify" || platforms[newValue].id === "yotube" || platforms[newValue].id === "tiktok") {
            getAudience(platforms[newValue].id);
        }

        setValue(newValue);
    };

    const handleReload = () => {
        getTopTracks(platforms[value].id, "true");
        getCurrentStatsFromSource(platforms[value].id, "true");

        getHistoryStats(platforms[value].id, getFormattedDate(firstStartDate), getFormattedDate(new Date()), "true");

        if (platforms[value].id === "spotify" || platforms[value].id === "yotube" || platforms[value].id === "tiktok") {
            getAudience(platforms[value].id, "true");
        }
    }


    const getAudience = (platformId, noCache = "false") => {
        setAudienceLoaded(false);

        const config = {
            method: 'get',
            url: `${API_URL}artist/audience/${platformId}/${noCache}`,
            headers: {
                "Authorization": `Bearer ${auth.userToken}`
            }
        }

        axios(config).then((response) => {
            const theData = response.data.audience[0].data;
            const newAudience = {...audience};

            newAudience[platformId] = theData;

            setAudience(newAudience);

            // set country chart data
            const sortedCountryChartData = sortBy(theData.country_charts, (o) => -o.charts.length);
            setCountryCharts(sortedCountryChartData);
            log('audience');
            log(response);
        }).catch((errors) => {
            log(errors);
        })
            .finally(() => {
                setAudienceLoaded(true);
            });
    }

  useEffect(() => {
    if (!auth.isAuthed()) {
      navigate("/login")
    }

        auth.checkSubscription();
        getTemplates();
        getCurrentStatsFromSource('spotify');
        getHistoryStats('spotify', getFormattedDate(firstStartDate), getFormattedDate(new Date()));
        getAudience('spotify');
        getTopTracks('spotify');
    }, []);

    useEffect(() => {
        if (templates) {
            const activeTemplate = templates.find((template) => template.active);
            if (activeTemplate) {
                changeTemplate(activeTemplate.id);
            }
        }
    }, [templates])


    const platformsWitoutAudiences = ["amazon", "shazam", "itunes", "deezer", "apple_music", "instagram", "soundcloud", "beatport", "traxsource", "tidal", "tracklist"]
    const platformsWitoutMapData = ["tiktok"];

    const [sideMarginTop, setSideMarginTop] = useState(0);

    const handleOpen = (e) => {
        setOpen(e.currentTarget);
    }

    const handleClose = (e) => {
        setOpen(null);
    }

    const handleObjectOrder = (obj) => {
        const sortedObj = Object.entries(obj)
            .sort((a, b) => a[1] - b[1])
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        return sortedObj;
    }

    const handleOrderChange = (change, subject) => {
        let gridsInOrderb = {...gridsInOrder};
        gridsInOrderb[subject] += change;

        const objectKeys = Object.keys(gridsInOrder);
        const index = objectKeys.indexOf(subject);
        const otherSubjChange = (change * -1);
        log("otherSubjChange");
        log(otherSubjChange);

        if (index !== -1 && index < objectKeys.length - 1 && change > 0) {
            const nextKey = objectKeys[index + 1];
            gridsInOrderb[nextKey] += otherSubjChange;
        }

        if (index > 0 && change < 0) {
            const prevKey = objectKeys[index - 1];
            gridsInOrderb[prevKey] += otherSubjChange;
        }

        gridsInOrderb = handleObjectOrder(gridsInOrderb);

        log(gridsInOrderb);

        setGridsInOrder(gridsInOrderb);
    }

    const orderArrows = (subject) => {
        const keys = Object.keys(gridsInOrder);

        if (!isOnSmallScreen) {
            return (
                <Stack direction={"row"}>
                    {keys[0] !== subject && <IconButton sx={{color: theme.palette.platform[platforms[value].id]}}
                                                        onClick={() => handleOrderChange(-1, subject)}><Iconify
                        icon="material-symbols:arrow-circle-left"/></IconButton>}
                    {keys[keys.length - 1] !== subject &&
                        <IconButton sx={{color: theme.palette.platform[platforms[value].id]}}
                                    onClick={() => handleOrderChange(+1, subject)}><Iconify
                            icon="material-symbols:arrow-circle-right"/></IconButton>}
                </Stack>
            )
        }

        return false;
    }

    const handleSplitHistory = () => {
        setSplitHistory(!splitHistory);
    }

    if (auth.user?.subscription?.plan?.permissions?.includes('can-analyse-music')
        && auth.user?.subscription?.status !== "cancelled") {
        if (!auth.user?.spotify_id) {
            return (
                <StyledBox sx={{marginTop: {xs: "50px", md: "90px"}}}>
                    <Box sx={{width: {md: "50%", xs: "100%"}}}>
                        <SearchArtist/>
                        <AddSpotifyId/>
                    </Box>
                </StyledBox>
            )
        }
        console.log(theme.palette.platform)
        return (
            <>
                <Helmet>
                    <title>Analytics {TITLE_SEPERATOR} {APP_NAME}</title>
                </Helmet>
                <Stack gap={3}>
                    {routeParams.id &&
                        <Grid container className='back-to-stats'>
                            <Link to={`/artist/statistics`}><Button variant="contained" color="primary">Back to artist
                                statistics</Button></Link>
                        </Grid>
                    }

                    <Grid container gap={3} sx={{padding: 0, backgroundColor: theme.palette.background.default}}>
                        {!routeParams.id &&
                            <>
                                <Grid md={2} xs={12} item sx={{
                                    borderColor: 'divider',
                                    display: isOnSmallScreen ? 'block' : 'none'
                                }}>
                                    <IconButton onClick={handleOpen}
                                                sx={{color: {md:"#acacac",xs:'black'},
                                                    border: "none!important",
                                                    textAlign: "left",
                                                    justifyContent:'left',
                                                    width: '5em',
                                                    height: '3em',
                                                    borderRadius: {xs:'0% 0% 40% 0%'},
                                                    backgroundColor:{xs:theme.palette.platform[platforms[value].id]}}}>
                                        <Stack alignContent={"center"} alignItems="center">
                                            <NavigateBeforeIcon />
                                            { /* {platforms[value].id !== "traxsource" ? */}
                                            { /*    <Iconify width="25px" icon={platforms[value].icon}/> */}
                                            { /*    : */}
                                            { /*    <TraxsourceIcon size="25px" color={"#acacac"}/> */}
                                            { /* } */}
                                        </Stack>
                                        {/* TODO: Here you need to change the color */}

                                        <Typography sx={{
                                            marginLeft: "2px",
                                            marginBottom: "5px",
                                            marginTop: "7px"
                                        }}>{platforms[value].name}</Typography>
                                    </IconButton>
                                    <Drawer
                                        open={Boolean(open)}
                                        anchor="left"
                                        onClose={handleClose}
                                        PaperProps={{sx: {backgroundColor: theme.palette.background.paper}}}
                                    >
                                        {platforms.map((platform, index) => (
                                            <IconButton
                                                key={platform.id}
                                                sx={{
                                                    marginTop: "19px",
                                                    color: index === value ? theme.palette.platform[platform.id] : "#acacac"
                                                }}
                                                onClick={(e) => {
                                                    handleChange(e, index)
                                                }}>
                                                {platform.id !== "traxsource" ?
                                                    <Iconify width="25px" icon={platform.icon}/>
                                                    :
                                                    <TraxsourceIcon size="25px"
                                                                    color={index === value ? theme.palette.platform[platform.id] : "#acacac"}/>
                                                }
                                            </IconButton>
                                        ))}
                                    </Drawer>
                                </Grid>
                                <StyledSideNav md={1} xs={0} item>
                                    <StyledInnerNav>
                                        <CustomAvatar/>
                                        <Scrollbar sx={{maxHeight: "73vh"}}>
                                            <Tabs
                                                sx={{
                                                    paddingTop: "39px",
                                                    marginTop: `${sideMarginTop}px`,
                                                    transition: "margin 0.5s ease-in-out"
                                                }}
                                                orientation='vertical'
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="platform tabs"
                                                indicatorColor={theme.palette.background.paper}>
                                                {platforms.map((platform, index) => (
                                                    <Tab
                                                        sx={{
                                                            alignItems: 'flex-start',
                                                            padding: 0,
                                                            margin: "auto",
                                                            marginBottom: "20px",
                                                            textTransform: "capitalize"
                                                        }}
                                                        key={index}
                                                        label={
                                                            <Stack alignItems={"center"} alignContent="center" gap={1}
                                                                   sx={{
                                                                       margin: 'auto',
                                                                       color: index === value && theme.palette.platform[platform.id]
                                                                   }}>
                                                                {platform.id !== "traxsource" ?
                                                                    <Iconify sx={{width: "35px", height: "35px"}}
                                                                             icon={platform.icon}/>
                                                                    :
                                                                    <TraxsourceIcon
                                                                        color={index === value ? theme.palette.platform[platform.id] : theme.palette.grey[600]}/>
                                                                }
                                                                <Typography variant="body2">{platform.name}</Typography>
                                                            </Stack>
                                                        }
                                                        {...a11yProps(index)} />
                                                ))}
                                            </Tabs>
                                        </Scrollbar>
                                    </StyledInnerNav>
                                </StyledSideNav>
                            </>
                        }
                        <Grid item md={routeParams.id ? 12 : 10} xs={12}>
                            {platforms.map((platform, index) => (
                                <TabPanel value={value} index={index} key={index}
                                          sx={{padding: '0!important', "& .MuiBox-root": {padding: 0}}}>
                                    {!isOnSmallScreen &&
                                        <Grid container justifyContent="start" className="contained-reloader">
                                            <Tooltip title="Reload data">
                                                <IconButton
                                                    className='reload'
                                                    sx={{
                                                        backgroundColor: theme.palette.platform[platforms[value].id]
                                                    }} onClick={handleReload} value={value}>
                                                    {topTracksLoading ||
                                                    !audienceLoaded ||
                                                    hIsLoading
                                                        ?
                                                        <Iconify icon="eos-icons:loading"/>
                                                        :
                                                        <Iconify icon="mdi:reload"/>
                                                    }
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                    <Grid className='mt-2' container columnSpacing={4} rowGap={6}>
                                        <Grid item xs={12}
                                              order={{
                                                  xs: 0,
                                                  md: gridsInOrder.historyStats
                                              }}>
                                            <HistoryStats
                                                orderArrows={orderArrows("historyStats")}
                                                split={splitHistory} handleSplit={handleSplitHistory}
                                                tabValue={value}
                                                source={platform.id}
                                                platformColor={theme.palette.platform[platform.id]}
                                                chartData={chartData}
                                                columns={columns}
                                                errorMsg={hErrorMsg}
                                                isLoading={hIsLoading}
                                                onGetHistoryStats={getHistoryStats}
                                                track={track}
                                            />
                                        </Grid>
                                        <Grid item xs={12} order={gridsInOrder.currentStats}>
                                            <CurrentStats orderArrows={orderArrows("currentStats")}
                                                          currentStats={currentStats} platformId={platform.id}
                                                          platformColor={theme.palette.platform[platform.id]}/>
                                        </Grid>
                                        {!routeParams.id &&
                                            <>
                                                <Grid item xs={12} md={7}
                                                      order={{
                                                          xs: 3,
                                                          md: gridsInOrder.audienceMap,
                                                      }}>
                                                    {audienceLoaded || platformsWitoutAudiences.includes(platform.id) ?
                                                        !routeParams.id &&
                                                        <Audience orderArrows={orderArrows("audienceMap")}
                                                                  mapData={audience[platform.id]}
                                                                  chartData={countryCharts}
                                                                  platformColor={theme.palette.platform[platform.id]}
                                                                  platformId={platform.id}/>
                                                        :
                                                        getSkeletons(1, "100%", "682.5px")
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={5}
                                                      order={{
                                                          xs: 2,
                                                          md: gridsInOrder.topTracks,
                                                      }}>
                                                    <TopTracks
                                                        orderArrows={orderArrows("topTracks")}
                                                        tracks={topTracks}
                                                        platformColor={theme.palette.platform[platform.id]}
                                                        loading={topTracksLoading}
                                                        platformId={platform.id}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}
                                                      order={{
                                                          xs: 4,
                                                          md: gridsInOrder.audienceOverview
                                                      }}>
                                                    {
                                                        audienceLoaded || platformsWitoutAudiences.includes(platform.id) ?
                                                            !routeParams.id && <AudienceOverview
                                                                orderArrows={orderArrows("audienceOverview")}
                                                                platformId={platform.id} audience={audience[platform.id]}
                                                                platformColor={theme.palette.platform[platform.id]}/>
                                                            :
                                                            getSkeletons(1, "100%", "841px")
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </TabPanel>
                            ))}
                            {!routeParams.id && <ArtistCatalog/>}
                        </Grid>
                    </Grid>
                </Stack>
                {!isOnSmallScreen && !routeParams.id &&
                    <Templates
                        onChangeTemplate={changeTemplate}
                        gridsInOrder={gridsInOrder}
                        isSplitHistory={splitHistory}
                        templates={templates}
                        loading={templatesLoading}
                        onSetTemplates={setTemplates}
                    />
                }
            </>
        );
    }

    if (isApp) {
        return <SubscriptionNotice />;
    }

    return <Navigate to={'/checkout'}/>;

}
