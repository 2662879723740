import { Box } from "@mui/material";

export default function OverlayItem() {
  return (
    <Box sx={{
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#000",
      opacity: ".5",
      top: 0
    }}/>
  )
}