import React from "react";
import { StyledOverlay, StyledProgress } from "./styles";

function Loader(props) {
    return(
        <StyledOverlay>
            <StyledProgress value={props.progress}/>
        </StyledOverlay>
    )
}

export default Loader;