import { Box } from "@mui/material";
import styled from '@emotion/styled';


const StyledShadowBox = styled(Box)(({sx, theme}) => ({
    borderRadius: "5px", 
    boxShadow: "0px 3px 5px rgba(0,0,0,0.25 )",
    backgroundColor: theme.palette.common.white,
    ...sx
}));

export default StyledShadowBox;