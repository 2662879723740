import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTheme, Box, Stack, Grid, Paper, FormControl, Select, Typography, MenuItem, TextField, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify/Iconify";
import { useFormHandler } from "../../../hooks/useFormHandler";
import StyledBox from "../../../theme/artist/overrides/StyledBox";
import { useAuth } from "../../../auth/Authentication";
import { API_URL } from "../../../hooks/globals";
import StyledSelect from "../../../theme/artist/overrides/StyledSelect";
import useGetSkeletons from "../../../hooks/useGetSkeletons";
import useConsoleLog from "../../../hooks/useConsoleLog";

Templates.propTypes = {
    gridsInOrder: PropTypes.object,
    isSplitHistory: PropTypes.bool,
    onChangeTemplate: PropTypes.func,
    templates: PropTypes.object,
    onSetTemplates: PropTypes.func,
    loading: PropTypes.bool,
}

export default function Templates({loading, gridsInOrder, isSplitHistory, onChangeTemplate, templates, onSetTemplates}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentTemplateId, setCurrentTemplateId] = useState(0);
    const [minimalized, setMinimalized] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const auth = useAuth();
    const fHandler = useFormHandler("artist/templates");
    const theme = useTheme();
    const [getSkeletons] = useGetSkeletons();
    const log = useConsoleLog;

    const saveTemplate = () => {
        const url = `${API_URL}artist/templates`;

        setIsSubmitting(true);

        const formValuesb = {
            name: fHandler.formValues.name,
            history_stats_order: gridsInOrder.historyStats,
            top_tracks: gridsInOrder.topTracks,
            current_stats_order: gridsInOrder.currentStats,
            audience_map_order: gridsInOrder.audienceMap,
            audience_overview_order: gridsInOrder.audienceOverview,
            split_history_stats: Boolean(isSplitHistory),
        }


        const config = {
            method: 'post',
            url,
            data: formValuesb,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            fHandler.setFormValues({});
            setErrors({});

            onSetTemplates(response.data);
        })
        .catch((error) => {
            log(error);
            if (errors.response) {
                if (error.response.status === 500) {
                setErrors({messageIse: 'Something went wrong on our side!'})
                } else {
                    setErrors(error.response.data.errors);
                }
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 
    }

    const setAsDefault = () => {
        const url = `${API_URL}artist/templates/${templates[currentIndex].id}/activate`;

        setIsSubmitting(true);

        const config = {
            method: 'get',
            url,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            onSetTemplates(response.data);
        })
        .catch((error) => {
            log(error);
            if (errors.response) {
                if (error.response.status === 500) {
                setErrors({messageIse: 'Something went wrong on our side!'})
                } else {
                    setErrors(error.response.data.errors);
                }
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 

    }

    const deleteCurrent = () => {
        const url = `${API_URL}artist/templates/${templates[currentIndex].id}`;

        setIsSubmitting(true);

        const config = {
            method: 'post',
            url,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            },
            data: {
                "_method": "delete"
            }
        };

        axios(config).then((response) => {
            onSetTemplates(response.data);
        })
        .catch((error) => {
            log(error);
            if (errors.response) {
                if (error.response.status === 500) {
                setErrors({messageIse: 'Something went wrong on our side!'})
                } else {
                    setErrors(error.response.data.errors);
                }
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 

    }

    const toggleMinimalized = () => {
        setMinimalized(!minimalized);
    }

    const realChange = (number) => {
        let theNumber = number; 

        if (theNumber < 0) {
            theNumber = templates.length + theNumber;
        } else if (theNumber >= templates.length) {
            theNumber -= templates.length;
        }

        return theNumber;
    }

    const changeTemplate = (changeVal) => {
        let theChange = realChange(currentIndex + changeVal);

        if (templates.length <= 3) {
            theChange = changeVal;
        }

        setCurrentIndex(theChange);

        setCurrentTemplateId(templates[theChange].id);

        onChangeTemplate(templates[theChange].id);
    }

    const templatesToMap = () => {
        if (templates.length > 3) {
            const theTemplates = [];
            const startIndex = currentIndex - 1;
            const endIndex = templates.length > 3 ? 2 : templates.length - 1;

            for (let i = startIndex; i < currentIndex + endIndex; i+=1) {
                const j = realChange(i);


                theTemplates.push(templates[j]);
            }

            return theTemplates;
        }

        return templates;
    }

    useEffect(() => {
        if (templates) { 
            const activeTemplate = templates.findIndex((template) => template.active);
            log('activeTemplate');
            log(activeTemplate);
            setCurrentIndex(activeTemplate);
            setCurrentTemplateId(templates[activeTemplate]?.id);
        }
    },[templates]);

    return (
        <Grid container justifyContent="end" sx={{zIndex: 999, position: "fixed", bottom: 0, width: !minimalized ? "100%" : {md: "5%", xs: "10%"}, right: 0}}>
            <Grid item sx={{width: {xs: 0, md: "128px"}}}/>
            <Grid item sx={{width: {xs: "100%", md: `${window.outerWidth.toFixed() - 145}px`}}}>
                <Paper sx={{backgroundColor: theme.palette.common.white, borderRadius: 0}}>
                    {!minimalized ?
                        <>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={{lg: 3, md: 0, xs: 3}}>
                                <Grid item xs={12} md={3}>
                                    <Stack direction="row" justifyContent={"start"}>
                                        <TextField name="name" label="New template name" hiddenLabel value={fHandler.formValues?.name || ""} onChange={fHandler.handleValChange} error={fHandler.errors?.name} helperText={fHandler.errors?.name} />
                                        <LoadingButton loading={isSubmitting} onClick={saveTemplate}>Save</LoadingButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {templates?.length ?
                                        <Stack direction="row">
                                            {templates.length > 3 &&
                                                <IconButton color="info" onClick={() => changeTemplate(-1)}>
                                                    <Iconify icon="uil:angle-left-b"/>
                                                </IconButton>}
                                            <Grid container alignItems="center" spacing={2}>
                                                {templatesToMap().map((template, index) => {
                                                    const opacity = currentTemplateId === template.id ? 1 : 0.5;
                                                    const fontSize = currentTemplateId === template.id ? "28px" : "24px";
                                                    const onClickChange = templates.length > 3 ? index-1 : index;

                                                    return (
                                                        <Grid item key={template.id} xs={4} onClick={() => changeTemplate(onClickChange)}>
                                                            <Typography 
                                                            textAlign={"center"}
                                                            sx={{
                                                                color: theme.palette.common.black, 
                                                                opacity, 
                                                                fontSize,
                                                                cursor: "pointer",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden"
                                                            }}>
                                                                {template.name}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            {templates?.length > 3 &&
                                            <IconButton color="info" onClick={() => changeTemplate(1)}>
                                                <Iconify icon="uil:angle-right-b"/>
                                            </IconButton>}
                                        </Stack>
                                        :
                                        loading ? getSkeletons(1, "100%", "59.938px") :
                                            <Typography variant="h4" textAlign="left">You have no templates yet.</Typography>
                                        
                                    }
                                </Grid>
                                <Grid item xs={10} md={2}>
                                {templates?.length ?
                                    <Stack direction="row" spacing={5} justifyContent="start">
                                        <LoadingButton sx={{fontWeight: 400, fontSize: 16}} loading={isSubmitting} onClick={setAsDefault} color="info">
                                            Set as default
                                        </LoadingButton>
                                        <LoadingButton sx={{fontWeight: 400, fontSize: 16}} loading={isSubmitting} onClick={deleteCurrent} color="error">
                                            Delete
                                        </LoadingButton>
                                    </Stack>
                                    :    
                                    loading && getSkeletons(1, "100%", "59.938px") 
                                }
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <IconButton onClick={() => setMinimalized(!minimalized)}><Iconify icon="clarity:window-min-line"/></IconButton>
                                </Grid>
                            </Grid>
                        </>
                    :
                        <IconButton onClick={toggleMinimalized}><Iconify icon="material-symbols:add"/></IconButton>
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}