import { useContext, useState, createContext, useMemo } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth/Authentication";
import { API_URL } from "../../hooks/globals";
import useConsoleLog from "../../hooks/useConsoleLog";

const ColorModeContext = createContext();

export function ColorModeProvider({children}) {
    const auth = useAuth();
    const log = useConsoleLog;
    const localDarkMode = useMemo(() => localStorage.getItem("darkMode") === "true", []);
    const [searchParams] = useSearchParams();
    const [darkMode, setDarkMode] = useState(searchParams.has('darkMode') ? parseInt(searchParams.get('darkMode'), 10) : auth.user?.dark_mode || localDarkMode || false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);

        if (auth.user) {
            const config = {
                method: "post",
                data: {dark_mode: !darkMode},
                url: `${API_URL}user/darkmode`,
                headers: {
                    "Authorization": `Bearer ${auth.userToken}`
                }
            }

            axios(config).then((response) => {
                log(response);
                auth.updateUser(response.data);
            }).catch((err) => {
                log(err);
            });
        } 
        
        localStorage.setItem('darkMode', !darkMode);
    }

    return <ColorModeContext.Provider value={{toggleDarkMode, darkMode}}>{children}</ColorModeContext.Provider>
}

export function useColorMode() {
    return useContext(ColorModeContext);
}