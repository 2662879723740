import { Navigate } from "react-router-dom";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useGetListing from "../../../api/useGetListing";
import { useAuth } from "../../../auth/Authentication";
import useConsoleLog from "../../../hooks/useConsoleLog";
import StyledBox from "../../../theme/artist/overrides/StyledBox";
import "../../../scss/coupons.scss";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";
import useResponsive from "../../../hooks/useResponsive";
import Footer from "../../../layouts/loggedout/Footer";
import SubscriptionNotice from "../../../modals/SubscriptionNotice";

const Intro = () => {
  const theme = useTheme();
  const auth = useAuth();

  return (
    <>
    {!auth.isApp &&
      <Typography variant="h1" sx={{color: theme.palette.common.black}}>
        Your YouGrow Coupon
      </Typography>
      }
      <Box className="coupon-generator-desc">
        <Typography variant="body1">
          At YouGrow, we’re committed to helping artists like you maximize your career potential. That’s why we want to give you a special gift that will give your career a boost.
        </Typography>
      </Box>
    </>
  )
}

export default function CouponGenerator() {
  const auth = useAuth();
  const { isApp } = useAuth();
  const getListing = useGetListing();
  const [data, setData] = useState("");
  const [transf, setTransf] = useState("");
  const [transfC, setTransfC] = useState("");
  const log = useConsoleLog;
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  const useCoupon = () => {
    setTransf(true);

    setTimeout(() => {
      setTransfC(true);
    }, 500)
  }

  useEffect(() => {
    getListing.getListing("artist/coupon")
    .then((res) => {
      setData(res.data);
      log(res);
    })
    .catch((err) => {
      log(err);
    })

    auth.checkSubscription();
  },[])

  if (auth.user?.subscription?.plan?.permissions?.includes('can-get-discounts')
      && auth.user?.subscription?.status !== "cancelled") {
    return (
      <>
        <StyledBox className="coupon-generator-wrapper container">
          <Grid container>
            {isOnSmallScreen &&
              <Grid item md={12}>
                <Intro/>
              </Grid>
            }
            <Grid item md={6} xs={12} order={{md: 0, xs: 1}}>
              <Box className="coupons-box">
                {!isOnSmallScreen &&
                    <Intro/>
                }
                <Box className="coupon-generator-desc-2">
                  <Typography variant="h4" className="coupon-generator-intro-title">
                    Claim your monthly coupon
                  </Typography>
                  <Typography variant="body1">
                    Once a month, you can claim a coupon code for your next YouGrow Promo campaign. This code is only valid for that month and will expire afterward. But don’t worry, you can claim a new code each month. the exciting part: the benefits of using this coupon code often outweigh the cost of the subscription itself. It’s a fantastic opportunity to make the most out of your investment.
                  </Typography>
                </Box>
                <Box className="coupon-generator-desc-3">
                  <Typography variant="h4" className="coupon-generator-intro-title">
                    Grow your music!
                  </Typography>
                  <Typography variant="body1">
                    Use your coupon to kickstart a new campaign with YouGrow Promo and enjoy a 15% discount! YouGrow Promo is dedicated to promoting your songs at a reasonable price. With our vast network of curators and influencers, we ensure your music reaches your target audience, organically growing your music and career. Our platforms include Spotify, YouTube, TikTok, and Instagram. Visit the YouGrow Promo website for more information.
                  </Typography>
                </Box>
              </Box>
              <Box  className="use-coupon-btn">
                <StyledLoadingButton onClick={() => {window.open("https://www.yougrowpromo.com/?utm_source=yougrowplus&utm_medium=coupon_page&utm_campaign=coupon_page_use_your_coupon_now", "_blank")}}>
                  Use your coupon now!
                </StyledLoadingButton>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}  order={{md: 1, xs: 0}}>
              <Box className="coupon-box-img" sx={{width:{xs:"80%"}, margin:{xs:"auto"}}}>
                <Box className="coupon-box-main">
                  <img src="/assets/coupon-box/cap.svg" alt="cap" className={`cap transformable ${transf && "transform"}`}/>
                  <img src="/assets/coupon-box/box.svg" alt="box" className="box"/>
                </Box>
                <Box className={`coupon transformable ${transf && "transform"} ${transfC && "transformc"}`}>
                  <img src="/assets/coupon-box/coupon-15.png" alt="coupon" />
                  <Typography className="coupon-text">
                    {data.code}
                  </Typography>
                </Box>
              </Box>
              <Box  className="claim-coupon-btn" sx={{display: {xs:"flex"} }}>
                <StyledLoadingButton onClick={useCoupon}>
                  Claim coupon
                </StyledLoadingButton>
              </Box>
            </Grid>
          </Grid>
        </StyledBox>
        <Footer/>
      </>
    )
  }

  if (isApp) {
    return <SubscriptionNotice />;
  }


  return <Navigate to={'/checkout'}/>;
}