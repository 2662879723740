import { useNavigate } from "react-router-dom";
import useConsoleLog from "../hooks/useConsoleLog";

const { default: usePost } = require("../api/usePost")

const LogOut = () => {
    const logout = usePost();
    const navigate = useNavigate();
    const log = useConsoleLog;

    logout('logout', []).then((response) => {
        log(response);
    }).catch((err) => {
        log(err);
    }).finally(() => {
        return navigate('login', true);
    });
}

export default LogOut;