const platforms = [
  {
    "id": "spotify",
    "name": "Spotify",
    "icon": "mdi:spotify",
    "searchUrl": "https://open.spotify.com/search/"
  },
  {
    "id": "instagram",
    "name": "Instagram",
    "icon": "mdi:instagram",
  },
  {
    "id": "youtube",
    "name": "YouTube",
    "icon": "akar-icons:youtube-fill",
  },
  {
    "id": "tiktok",
    "name": "TikTok",
    "icon": "ic:baseline-tiktok",
  },
  {
    "id": "apple_music",
    "name": "Apple",
    "icon": "ic:baseline-apple",
  },
  {
    "id": "deezer",
    "name": "Deezer",
    "icon": "tabler:brand-deezer",
  },
  {
    "id": "soundcloud",
    "name": "SoundCloud",
    "icon": "mdi:soundcloud",
    "searchUrl": "https://soundcloud.com/search?q="
  },
  {
    "id": "itunes",
    "name": "iTunes",
    "icon": "ps:itunes",
  },
  {
    "id": "shazam",
    "name": "Shazam",
    "icon": "tabler:brand-shazam",
  },
  {
    "id": "beatport",
    "name": "Beatport",
    "icon": "simple-icons:beatport",
    "searchUrl": "https://www.beatport.com/search?q="
  },
  {
    "id": "traxsource",
    "name": "Traxsource",
    "icon": "mdi:spotify",
  },
  {
    "id": "tidal",
    "name": "Tidal",
    "icon": "cib:tidal",
  },
  {
    "id": "tracklist",
    "name": "1001Tracklists",
    "icon": "simple-icons:1001tracklists",
  },
  {
    "id": "amazon",
    "name": "Amazon",
    "icon": "ant-design:amazon-circle-filled",
    "searchUrl": "https://music.amazon.com.br/search/"
  }
]

export default platforms;