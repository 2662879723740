import { Outlet, useNavigate } from "react-router-dom";
import { Paper, Grid, IconButton } from "@mui/material"
import { Container } from "@mui/system"
import Iconify from "../../../components/iconify/Iconify"



export default function ShowLayout({children}) {
  const navigate = useNavigate();

  return (
    <Container>
      <IconButton onClick={() => navigate(-1)}>
        <Iconify icon="material-symbols:arrow-back"/>
      </IconButton>
      <Paper sx={{p: 5, mt: 3}}>
        <Grid container justifyContent="space-between">
          {children}
        </Grid>
      </Paper>
    </Container>
  )
}