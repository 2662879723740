import { Paper, useTheme } from "@mui/material";

import styled from '@emotion/styled';
import useResponsive from "../../../hooks/useResponsive";

const StyledPaper = styled(Paper)(({platformColor, sx, theme}) => {
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: "#000",
          padding: '20px',
          boxSizing: 'border-box',
          py: 5,
          px: 0,
          filter: "drop-shadow(0px 3px 5px rgba(0,0,0,0.16 ))",
          textAlign: 'center',
          borderRadius: isOnSmallScreen ? 0 : "10px",
          "&:before": {
              content: '""',
              display: "block",
              height: "78%",
              width: "4px",
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: platformColor
          },
          "&:after": {
              content: '""',
              display: "block",
              height: "4px",
              width: "78%",
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: platformColor
          }
        },
      },
    },
  };
});

export default StyledPaper;