import { number, string } from "prop-types"
import { fShortenNumber } from "../../../../utils/formatNumber"

Diff.propTypes = {
  classy: string,
  content: string,
  diff: number
}

export default function Diff({classy, content, diff}) {
  return (
    <>
      <span>{fShortenNumber(content)}</span>
      <span className={classy}>{diff}</span>
    </>
  )
}