import useShowResource from "../../../hooks/useShowResource";
import ShowLayout from "../../../layouts/dashboard/show/ShowLayout";
import RowLayout from "../../../layouts/dashboard/show/RowLayout";
import LoadingLayout from "../../../layouts/dashboard/show/LoadingLayout";


export default function ShowAdmin() {
  const resource = useShowResource('admin/admin-users/');  

  return (
    <ShowLayout>
      {resource?.loading ?
          <LoadingLayout skeletons={3}/>
        :
        <>
          <RowLayout label="E-mail" value={resource?.email}/>
          <RowLayout label="User role" value={resource?.role}/>
          <RowLayout label="Created at" value={resource?.createdAt}/>
        </>
      }
    </ShowLayout>
  )
}