import ListingLayout from '../../../layouts/dashboard/ListingLayout';
import ArtistForm from '../../../forms/ArtistForm';
import UpdateArtistForm from '../../../forms/update/UpdateArtistForm';

// ----------------------------------------------------------------------


export default function Artists() {
  const updateFormWithProps = (id, data, apiDone, setApiDone, handleEditClose) => {
    return <UpdateArtistForm id={id} data={data} apiDone={apiDone} setApiDone={setApiDone} editClose={handleEditClose} />;
  }

  return (
    <>
      <ListingLayout 
      name="Artists" 
      TABLE_HEAD={[
        { id: 'firstName', label: 'First name', alignRight: false },
        { id: 'lastName', label: 'Last name', alignRight: false },
        { id: 'email', label: 'E-mail', alignRight: false },
        { id: 'spotifyId', label: 'Spotify ID', alignRight: false },
        { id: 'createdAt', label: 'Created at', alignRight: false },
        { id: 'Add order' },
        { id: '' },
      ]}
      apiPath="admin/artists"
      pageTitle="Artist"
      form={<ArtistForm/>}
      viewOrders="/admin-artist-orders"
      updateForm={updateFormWithProps}
      updateColspan={6}
      objectKeys={["id", "email"]}
      />
    </>
  );
}
