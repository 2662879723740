import { useEffect, useState } from "react"
import axios from "axios";
import { useTheme } from "@emotion/react";
import { Stack } from "@mui/system";
import { FormControl, Select, MenuItem, Box, Alert } from "@mui/material";
import { API_URL } from "../../../../hooks/globals";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import { useFormHandler } from "../../../../hooks/useFormHandler";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import { useAuth } from "../../../../auth/Authentication";

export default function UpgradePlanSelector() {
    const [plans, setePlans] = useState();
    const [formValues, setFormValues] = useState({
        plan: "",
    });
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const auth = useAuth();
    const theme = useTheme();
    const log = useConsoleLog;

    const getPlans = () => {
        const config = {
            method: "get",
            url: `${API_URL}get-plans-new/plan`,
            headers: {
                "Authorization": `Bearer ${auth.userToken}`
            } 
        }

        axios(config).then((response) => {
            log("All plans");
            log(response.data);
            const plans = response.data.plans;

            setePlans(plans)
            setFormValues({...formValues, plan: plans[plans.length-1].id});
        }).catch((err) => {
            log(err);
            setErrors(true);
        })
    }

    const handleSubmit = () => {
        setIsSubmitting(true);
        setSuccess(false);
        setErrors(false);
        
        const config = {
            method: "post",
            data: {...formValues, _method: "PATCH"},
            url: `${API_URL}artist/update-subscription`,
            headers: {
                "Authorization": `Bearer ${auth.userToken}`
            } 
        }

        axios(config).then((response) => {
            auth.checkSubscription(false);

            setSuccess(true);
        }).catch((err) => {
            log(err);
            setErrors(true);
        }).finally(() => {
            setIsSubmitting(false);
        });
    }
    
    const handleValChange = (e) => {
      const formValuesb = {...formValues};
      formValuesb[e.target.name] = e.target.value;
  
      setFormValues(formValuesb);
      log(formValuesb);
    }

    useEffect(() => {
        setFormValues({plan: 0});
        getPlans();
    }, [success]);
    
    return (
        <Box sx={{mt: "20px"}}>
            <Stack gap={2}>
                <FormControl fullWidth>
                    <Select
                        name="new_plan_id"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formValues?.new_plan_id}
                        onChange={handleValChange}
                        sx={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            textAlign: "left",
                            backgroundColor: theme.palette.common.litUp,
                            borderRadius: "5px",
                            color: theme.palette.common.black,
                            px: "25px",
                            border: `0px solid ${theme.palette.common.litUp}`
                        }}
                    >
                        {plans?.map((plan, index) => (<MenuItem key={plan.id} value={plan.id} sx={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            textAlign: "left",
                            color: theme.palette.common.black
                        }}>{plan.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <StyledLoadingButton loading={isSubmitting} onClick={() => handleSubmit()}>Submit</StyledLoadingButton>
                {errors && <Alert severity="error">Sonething went wrong on our side! Please contact support.</Alert>}
                {success && <Alert severity="success">Successfully updated your subscription</Alert>}
            </Stack>
        </Box>
    )
}