import {Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { bool, string } from "prop-types";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../../components/iconify";
import useResponsive from "../../../../hooks/useResponsive";
import GalleryButton from "../GalleryButton";
import History from "../history";
import Amount from "../images-left/Amount";

Heading.propTypes = {
  title: string.isRequired,
  description: string,
  showHist: bool
}

export default function Heading({title, description, showHist = true}) {
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Grid container className="artwork-heading">
        <Grid item xs={10} md={8}>
          <Stack direction={{md: "row", xs: "column"}}>
            <Typography variant="h1" sx={{color: theme.palette.common.black}} className="intro-title">
              {title}
            </Typography>
          </Stack>
          {description &&
            <Typography variant="body1" className="intro">
              {description}
            </Typography>
          }
        </Grid>
          {isOnSmallScreen &&
            <Grid item xs={2}>
              <Amount/>
              <Stack spacing={2}>
                <History/>
                <GalleryButton/>
              </Stack>
            </Grid>
          }
        </Grid>
    </>
  );
}