import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams, useSearchParams } from "react-router-dom"
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";

export default function SharedArtwork() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  console.log(searchParams);

  return (
    <>
      <Helmet>
        <title>Shared image "{params.prompt}" {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
      <Box className="shared-image-wrapper">
        <img src={searchParams.get("url")} alt={params.prompt} width={250} height={250}/>
        <Typography variant="h5">This album cover was generated by AI</Typography>
        <Typography>Also want an image like this?</Typography>
        <StyledLoadingButton>
          Yes, give me more info!
        </StyledLoadingButton>
      </Box>
    </>
  )
}