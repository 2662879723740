import { useMemo } from 'react';
import PropTypes from 'prop-types';
// @mui
import {useTheme,  Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import useResponsive from '../../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

ItemListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
};

export default function ItemListHead({
  order,
  orderBy,
  headLabel,
}) {
  const theme = useTheme();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const dontHideOnSmallScreen = useMemo(() => ([
    "Order date",
    "Order status",
    "''",
  ]))

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          (!isOnSmallScreen || dontHideOnSmallScreen.includes(headCell.label)) &&
            <TableCell
              sx={{color: theme.palette.grey[600], backgroundColor: theme.palette.grey[100], borderColor: theme.palette.grey[300], fontSize: 17, fontWeight: 500}}
              key={headCell.id}
              align={headCell.alignRight || (isOnSmallScreen && headCell.alignMRight) ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
        ))}
        {isOnSmallScreen &&
        <TableCell
          sx={{color: theme.palette.grey[600], backgroundColor: theme.palette.grey[100], borderColor: theme.palette.grey[300], fontSize: 17, fontWeight: 500}}
        />
        }
      </TableRow>
    </TableHead>
  );
}
