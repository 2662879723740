import { TextField } from "@mui/material";
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)(({theme, sx}) => ({
    "& input": {
        color: "#000", 
        backgroundColor: theme.palette.common.litDown, 
        borderRadius: "5px",
        WebkitTextFillColor: "#000!important"
    },
    borderRadius: "5px",
    ...sx
}));

export default StyledTextField;