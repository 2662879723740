import { React, useEffect } from 'react';
// @mui
import { TextField, FormControl, OutlinedInput, MenuItem, InputLabel, Select, ListItemText, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useFormHandler} from '../hooks/useFormHandler';
// components

// ----------------------------------------------------------------------

export default function ArtistForm() {
  const fHandler = useFormHandler('admin/artists');

  useEffect(() => {
    fHandler.setFormValues({...fHandler.formValues, privacyAccept: 1});
  },[])

  return (
    <>
      <TextField name="first_name" label="First name" value={fHandler.formValues?.first_name} onChange={fHandler.handleValChange} error={fHandler.errors?.first_name} helperText={fHandler.errors?.first_name} />
      <TextField name="last_name" label="Last name" value={fHandler.formValues?.last_name} onChange={fHandler.handleValChange} error={fHandler.errors?.last_name} helperText={fHandler.errors?.last_name} />
      <TextField name="email" label="Email" value={fHandler.formValues?.email} onChange={fHandler.handleValChange} error={fHandler.errors?.email} helperText={fHandler.errors?.email} />

      <TextField
        name="password"
        label="Password"
        onChange={fHandler.handleValChange} 
        value={fHandler.formValues?.password}
        error={fHandler.errors?.password} 
        helperText={fHandler.errors?.password} />

      <TextField
        name="password_confirmation"
        label="Password confirmation"
        onChange={fHandler.handleValChange} 
        value={fHandler.formValues?.password_confirmation}
        error={fHandler.errors?.password_confirmation} 
        helperText={fHandler.errors?.password_confirmation} />

      <TextField 
      name="spotify_id" 
      label="Spotify ID" 
      value={fHandler.formValues?.spotify_id} 
      onChange={fHandler.handleValChange} 
      error={fHandler.errors?.spotify_id} 
      helperText={fHandler.errors?.spotify_id} />

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => {fHandler.handleSubmit('admin/artists')}} loading={fHandler.isSubmitting}>
        Submit
      </LoadingButton>
    </>
  );
}
