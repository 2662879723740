import { forwardRef } from 'react';
import { Card, Box, useTheme, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import UrlTableLayout from './UrlTableLayout';
import UrlTableCellLayout from './UrlTableCelLayout';

const VideoUrls = forwardRef(({playlists, type}, ref) => {
  const theme = useTheme();

  return (
    <Box className="white-box bg-neutral mt-1" ref={ref}>
      <UrlTableLayout className="bg-neutral">
          <TableBody className="bg-neutral">
            {playlists.map((playlist) => (
              playlist &&
              <UrlTableCellLayout className="bg-neutral" url={playlist.VideoLink__c}>
                    {playlist.VideoLink__c}
              </UrlTableCellLayout>
            ))}
          </TableBody>
      </UrlTableLayout>
    </Box>
  )
});

VideoUrls.propTypes = {
  playlists: PropTypes.array,
  type: PropTypes.string
}

export default VideoUrls;