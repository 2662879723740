import {React, useState} from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from "@mui/system";
import { useModal } from "./ModalContext";
import { useDelete } from "../hooks/useDelete";

export default function DeleteFormModal(props) {
  const modal = useModal();
  const deleter = useDelete();

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    

    return (
    <div>
    <Modal
    open={modal.delOpen.open}
    onClose={modal.handleDelClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
      <Stack spacing={3}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
        Are you sure you want to delete {modal.delOpen.name}?
        </Typography>
        <Typography variant="body1">
          This action can't be undone.
        </Typography>
        {deleter.errors && <Alert severity="error">{deleter.errors}</Alert>}
        <Grid container justifyContent='space-between'>
          <Button color="info" onClick={() => {modal.handleDelClose()}} variant="contained">
            Cancel  
          </Button>
          {modal.delOpen.key === 'multiple' ? 
          <LoadingButton color="error" onClick={() => {deleter.handleMultiDelete(modal.delOpen.delId, modal.delOpen.path)}} loading={deleter.isLoading} variant="contained">Delete multiple</LoadingButton> : 
          <LoadingButton color="error" onClick={() => {deleter.handleDelete(modal.delOpen.delId, modal.delOpen.path)}} loading={deleter.isLoading} variant="contained">Delete</LoadingButton>}
        </Grid>
      </Stack>
    </Box>
    </Modal>
    </div>
    )
}