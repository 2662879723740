import SimpleBar from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledOverlay = styled('div')(() => ({
  display: 'block',
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  background: '#000',
  opacity: .7,
  zIndex: 9999,
  top: 0,
  left: 0,
}));

export const StyledProgress = styled(CircularProgress)(({ theme }) => ({
    display: 'block',
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}));
