import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/system';
import { Alert, Button } from '@mui/material';
import Iconify from '../../../../components/iconify';
import useDownloadFIle from '../../../../hooks/useDownloadFile';
import usePost from '../../../../api/usePost';
import ShareModal from '../../trees/ShareModal';
import { APP_URL } from '../../../../hooks/globals';
import useConsoleLog from '../../../../hooks/useConsoleLog';
import useGetListing from '../../../../api/useGetListing';

GeneratedImage.propTypes = {
  image: PropTypes.string,
  prompt: PropTypes.string,
  onGenerateVariations: PropTypes.func
};

export default function GeneratedImage({image, prompt, onGenerateVariations}) {
  const post = usePost();
  const get = useGetListing();
  const download = useDownloadFIle();
  const [open, setOpen] = useState(false);
  const log = useConsoleLog;
  const [success, setSuccess] = useState("");

  const saveToGallery = () => {
    post(`artist/artwork/save`, {url: image})
    .then((res) => {
      setSuccess("Successfully saved image to gallery");
      log(res);
    })
    .catch((err) => {
      log(err);
    });
  }

  const downloadBigSize = () => {
    download(`${image}/big`, `${prompt}.png`);
  }

  return (
    <>
      <Stack className="generated-image" spacing={2.25}>
      <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="contained" className="btn-variations" onClick={onGenerateVariations}><Iconify icon="solar:refresh-bold"/>Variations</Button>
          <Button onClick={downloadBigSize} variant="contained"><Iconify icon="material-symbols:download"/>Download big</Button>
        </Stack>
        <img src={image} alt="someimage" width="100%"/>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="contained" onClick={() => setOpen(true)}><Iconify icon="ph:share-fat"/>Share</Button>
          <Button onClick={saveToGallery} variant="contained"><Iconify icon="material-symbols:bookmark-outline"/>Save</Button>
        </Stack>
        {
          success.length > 0 && 
          <Alert severity='success'>
            {success}. Check out your gallery <Link to={'/artist/artwork/gallery'} className="text-black"> here</Link>
          </Alert>
        }
      </Stack>
      <ShareModal 
        onOpen={open}
        onSetOpen={setOpen}
        shareMessage={"Just generated an album cover with the YouGrowPlus AI image generation tool!"}
        url={`${window.location.origin}/shared-artwork/${prompt}/?url=${image}`}
      />
    </>
  );
};

