import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import OverlayItem from "../../../../components/overlayItem";

Example.propTypes = {
  imgSrc: PropTypes.string,
  text: PropTypes.string,
}

export default function Example({imgSrc, text}) {
  return (
    <Box className="example">
      <img src={`/assets/ai-artwork/examples/${imgSrc}.png`} alt={text}/>
      <OverlayItem/>
      <Typography>{text}</Typography>
    </Box>
  )
}