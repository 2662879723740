import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import 'dayjs/locale/nl';
import { Alert, Box, Stack, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import useGetListing from "../../../api/useGetListing";
import useConsoleLog from "../../../hooks/useConsoleLog";
import useGetFormattedDate, { useGetFormattedTextDate } from "../../../hooks/useGetFormattedDate";
import { fShortenNumber } from "../../../utils/formatNumber";
import InvoiceListingLayout from "../auth/my-account/invoices/InvoiceListingLayout";
import OverviewGraph from "./sections/OverviewGraph";
import StatRankCard from "./sections/StatRankCard";
import Switchers from "./sections/Switchers";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import Diff from "./sections/Diff";
import { useAuth } from "../../../auth/Authentication";
import AddIdForm from "../forms/AddIdForm";
import RegisterSocialAccordion from "./sections/register-accounts/Accordion";
import {AppWebsiteVisits} from "../../../sections/@dashboard/app";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import Arrow from "../../../components/arrow-bg/Arrow";


function InsufficientInfo() {
  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography className="badge">
            Things to note
          </Typography>
          <Typography>
            <br/>
            <br/>
            Before linking your Facebook account to your personal dashboard, there are a couple of things to take note of. 
            <br/>
            <br/>
            1) If this is your first time tracking your data through our dashboard, it could take a couple of days for your data to show up in your dashboard 
            <br/>
            <br/>
            2) Below you can add you social media account. Please make sure that you add the correct account. An account can only be added once.
            <br/>
            <br/>
          </Typography>
          <RegisterSocialAccordion
              icon={"fb"}
              name={"Facebook"}
              idName={"facebook"}
            />
        </Grid>
      </Grid>
    </>
  )
}

export default function Faecbook() {
  const theme = useTheme();
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [likes, setlikes] = useState([]);
  const [talkingAbout, setTalkingAbout] = useState([]);
  const [defaultOverview, setDefaultOverview] = useState(true);
  const [changablelikes, setChangablelikes] = useState([]);
  const [changableTalkingAbout, setChangableTalkingAbout] = useState([]);
  const [currentLikes, setCurrentlikes] = useState(0);
  const [prevLikes, setPrevlikes] = useState(0);
  const [currentTalkingAbout, setCurrentTalkingAbout] = useState(0);
  const [prevTalkingAbout, setPrevTalkingAbout] = useState(0);
  const [likesMonth, setlikesMonth] = useState([]);
  const [labelsMonth, setLabelsMonth] = useState([]);
  const [likesGainMonth, setlikesGainMonth] = useState([]);
  const [likesGainPerMonth, setlikesGainPerMonth] = useState([]);
  const [ranks, setRanks] = useState({
    likes: 0,
    talkingAbout: 0,
  });
  const [labels, setLabels] = useState([]);
  const [labelsChangable, setLabelsChangable] = useState([]);
  const {getListing} = useGetListing();
  const log = useConsoleLog;
  const getFormattedDate = useGetFormattedDate();
  const formatDate = useGetFormattedTextDate();
  const firstStartDate = new Date();
  const lastEndDate = new Date();
  firstStartDate.setDate(firstStartDate.getDate() - 30);
  lastEndDate.setDate(lastEndDate.getDate() - 1);
  const [startDate, setStartDate] = useState(getFormattedDate(firstStartDate));
  const [endDate, setEndDate] = useState(getFormattedDate(lastEndDate));

  const platform = 'facebook';
  document.documentElement.style.setProperty('--custom-border-color', theme.palette.platform[platform]);

  const changelikes = (amounts) => {
    setChangablelikes([{
      name: "Likes",
      data: amounts,
      type: 'area',
      fill: 'gradient',
      stroke: {
        curve: 'smooth',
      }
    }]);
  }
  
  const changeTalkingAbout = (amounts) => {
    setChangableTalkingAbout([{
      name: "Likes",
      data: amounts,
      type: 'area',
      fill: 'gradient',
      stroke: {
        curve: 'smooth',
      }
    }]);
  }

  const changeStartDate = (value, theLables, thelikes, theTalkingAbout) => {
    log(thelikes);
    const startDateb = getFormattedDate(new Date(value));
    setStartDate(startDateb);
    const start = theLables.indexOf(startDateb);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedlikes = thelikes.slice(end, start);
    const changedTalkingAbout = theTalkingAbout.slice(end, start);
    changelikes(changedlikes);
    changeTalkingAbout(changedTalkingAbout);
    setLabelsChangable(changedLabels);
  }

  const changeEndDate = (value, theLables, thelikes, theTalkingAbout) => {
    log(thelikes);
    const endDateb = getFormattedDate(new Date(value));
    setEndDate(endDateb);
    const start = theLables.indexOf(startDate);
    const end = theLables.indexOf(endDate);
    const changedLabels = theLables.slice(end, start);
    const changedlikes = thelikes.slice(end, start);
    const changedTalkingAbout = theTalkingAbout.slice(end, start);
    changelikes(changedlikes);
    changeTalkingAbout(changedTalkingAbout);
    setLabelsChangable(changedLabels);
  }

  const fetchPlatformData = () => {
    getListing(`artist/social-media/facebook/${auth.user.facebook_id}`)
    .then((res) => {
      const datab = res.data.data.daily;
      const dataWithId = datab.map((item, key) => {
        item = {
          id: key,
          date: formatDate(item.date),
          likes: fShortenNumber(item.likes),
          talkingAbout: fShortenNumber(item.talking_about),
        };

        return item
      });
      const slicedData = datab.slice(0, 30);
      if (slicedData.length >= 30) {
        setData(slicedData.map((theData, index) => {
          let likeDiff = 0;
          let likeDiffClass = 'neutral';
          let talkingAboutDiff = 0;
          let talkingAboutDiffClass = 'neutral';

          if (index + 1 < 30) {
            likeDiff = theData.likes - slicedData[index+1].likes;
            likeDiff = ` ${likeDiff >-1 ? "+" : "-"}${Math.abs(likeDiff)}`;
            likeDiffClass = likeDiff >-1 ? "positive" : "negative";

            talkingAboutDiff = theData.talking_about - slicedData[index+1].talking_about;
            talkingAboutDiff = ` ${talkingAboutDiff >-1 ? "+" : "-"}${Math.abs(talkingAboutDiff)}`;
            talkingAboutDiffClass = talkingAboutDiff >-1 ? "positive" : "negative";
          }

          return {
            id: theData.id,
            date: theData.date,
            likes: <Diff
                          classy={likeDiffClass}
                          content={theData.likes ? theData.likes : 0}
                          diff={likeDiff}
                        />,
            talkingAbout: <Diff
                          classy={talkingAboutDiffClass}
                          content={theData.talking_about ? theData.talking_about : 0}
                          diff={talkingAboutDiff}
                        />,
          }
        }));
      }

      log("dataWithId");
      log(dataWithId);
      log("datab");
      log(datab);

      const likesb = datab.map((item) => (item.likes));
      log("likesb");
      log(likesb);
      const talkingAboutb = datab.map((item) => (item.talking_about));
      const likesbOneMonth = likesb.slice(0, 30);
      const likesbTwoMonths = likesb.slice(0, 60);
      const likesbGainMonth = likesbOneMonth.map((item, key) => {
        return likesbOneMonth[key] - likesbTwoMonths[key];
      });


      const monthCount = Math.floor(likesb.length / 30); // Get the total number of complete months in the array
      const likesGainPerMonth = []

      for (let i = 0; i < monthCount; i+=1) {
        const startIndex = i * 30; // Start index of each month
        const endIndex = (i + 1) * 30; // End index of each month

        const startSubs = likesb[startIndex];
        const endSubs = likesb[endIndex - 1];
        const change = startSubs - endSubs;

        likesGainPerMonth.push(change);
      }




      changelikes(likesb);
      log("likesb");
      log(likesb);
      
      setlikes([{
        name: "Likes",
        data: likesb,
        type: 'area',
        fill: 'gradient',
        stroke: {
          curve: 'smooth',
        }
      }]);

      changeTalkingAbout(talkingAboutb);

      setTalkingAbout([{
        name: "Talking about",
        data: talkingAboutb,
        type: 'area',
        fill: 'gradient',
        stroke: {
          curve: 'smooth',
        }
      }]);

      log("talkingAboutb");
      log(talkingAboutb);

      setlikesGainMonth([{
        name: "Likes gained",
        data: likesbGainMonth,
        type: 'line',
        stroke: {
          curve: 'smooth',
        }
      }]);

      log("likesbGainMonth");
      log(likesGainPerMonth.slice(0, 9));

      setlikesGainPerMonth([
        {
          name: "Likes",
          data: likesGainPerMonth.slice(0, 9),
          type: 'bar',
          stroke: {
            curve: 'smooth',
          }
        },
      ]);

      log("likesGainPerMonth");
      log(likesGainPerMonth);

      setlikesMonth([{
        name: "Likes gained",
        data: likesb,
        type: 'bar',
      }]);

      const labelsb = datab.map((item) => (item.date));

      const theLabelsMonth = [];
      labelsb.forEach((label, key) => {

        if (key % 30 === 0) {
          theLabelsMonth.push(label);
        }
      });
      log("theLabelsMonth");
      log(theLabelsMonth.slice(0, 9));
      setLabelsMonth(theLabelsMonth.slice(0, 9));

      log("labelsb");
      log(labelsb);
      setLabels(labelsb);
      setLabelsChangable(labelsb);
      setCurrentlikes(likesb[0]);
      localStorage.setItem("fb-likes", likesb[0]);
      auth.setSocialStats({...auth.socialStats, facebook: likesb[0]})
      setPrevlikes(likesb[1]);
      localStorage.setItem("fb-likes-prev", likesb[1]);
      auth.setPrevSocialStats({...auth.socialStats, facebook: likesb[1]})
      setCurrentTalkingAbout(talkingAboutb[0]);
      setPrevTalkingAbout(talkingAboutb[1]);
      setRanks(res.data.data.ranks);      
      changeStartDate(firstStartDate, labelsb, likesb, talkingAboutb);
      changeEndDate(new Date(), labelsb, likesb, talkingAboutb);
    })
    .catch((err) => {
      log(err);
    });
  }

  useEffect(() => {
    if (auth.user.facebook_id) {
      fetchPlatformData(); 
    }
  },[auth.user.facebook_id])

  return (
    <>
      <Helmet>
        <title>Facebook {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
      {auth.user.facebook_id ? 
      <>
        <Stack gap={3}>
          <Typography variant="h4">
            Overview
          </Typography>
          <Arrow platformId={platform}/>
          <StyledPaper sx={{
            px: {md: 5, xs: 0},
            zIndex: 999,
            backgroundColor: theme.palette.grey[200],
            borderRadius: {xs: "0", md: "10px"}}}
          >
            <Switchers
                onChangeStartDate={changeStartDate}
                onChangeEndDate={changeEndDate}
                theSubs={likes}
                theViews={talkingAbout}
                theStartDate={startDate}
                theEndDate={endDate}
                theLabels={labels}
            />
            <Box className="overview-wrapper mt-2 graph">
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <OverviewGraph
                      title="Likes"
                      data={changablelikes}
                      labels={labelsChangable}
                      color={theme.palette.primary.main}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <OverviewGraph
                      title="Talking about"
                      data={changableTalkingAbout}
                      labels={labelsChangable}
                      color={theme.palette.warning.main}
                  />
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
          <Grid item md={5} xs={12}>
            <Grid container columnSpacing={5} rowSpacing={{ xs: 0, sm: 2, md: 3 }} className="other-stats">
              <Grid item xs={12}>
                <Typography variant="h4">
                  Other stats
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                  <StatRankCard
                    type="Likes"
                    number={currentLikes}
                    prevNumber={prevLikes}
                    ranked={false}
                  />
              </Grid>
              <Grid item xs={12} md={6}>
                  <StatRankCard
                    type="Talking about"
                    number={currentTalkingAbout}
                    prevNumber={prevTalkingAbout}
                    ranked={false}
                  />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Grid container columnSpacing={5} className="stats-wrapper">
          <Grid item xs={12} lg={7} sx={{ position: 'relative', paddingTop: '0' }}>
            <Typography variant="h4">
              Monthly gained
            </Typography>
            {Boolean(likesGainPerMonth[0]?.data?.length > 4) ?
              <>
                <Arrow platformId={platform}/>
                <Box className="mt-2 graph custom-border">
                  <OverviewGraph height={490} data={likesGainPerMonth} labels={labelsMonth} color={theme.palette.platform.spotify} bg={theme.palette.grey[200]}/>
                </Box>
              </>
              :
              <Alert className="mt-2" severity="error">
                Insufficient data
              </Alert>
            }
          </Grid>
          <Grid item xs={12} lg={5} className="hide-stack table-data" sx={{ marginTop: { xs: '24px', md: 0 } }}>
            <Typography variant="h4">
              Your Growth the past month
            </Typography>
            <Box className="mt-2">
              {Boolean(data?.length) ?
                <InvoiceListingLayout
                    name="Growth" 
                    TABLE_HEAD={[
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: 'likes', label: 'Likes', alignRight: false },
                    { id: 'talkingAbout', label: 'Talking about', alignRight: false },
                    ]}
                    data={data}
                    objectKeys={['id']}
                    showCustomBorder
                    platform={platform}
                />
              :
                  <Alert severity="error">
                    Insufficient data
                  </Alert>
              }
            </Box>
          </Grid>
        </Grid>
      </>
      :
      <InsufficientInfo/>
      }
    </>
  )
}