import { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "@mui/system";
import { lowerCase } from "lodash";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Stack, IconButton } from "@mui/material";
import { useFormHandler } from "../../../hooks/useFormHandler"
import { useAuth } from "../../../auth/Authentication";
import { API_URL } from "../../../hooks/globals";
import useConsoleLog from "../../../hooks/useConsoleLog";
import Iconify from "../../../components/iconify";


export default function AddIdForm(props) {
    const [isSubmitting, setIsSubmitting] = useState();
    const [errors, setErrors] = useState();
    const fHandler = useFormHandler();
    const auth = useAuth();
    const log = useConsoleLog;

    const newUrl = props.idType.replace(/\s/g, '');
  
    useEffect(() => { 
        log(auth.userToken);
        fHandler.setFormValues({platform: props.platformNameId, _method: "PATCH"});
    }, []);

    const updateArtistId = (response) => {
        const userb = {...auth.user};
        userb[`${props.platformNameId}_id`] = response.data.platformId;
        if (userb.avatar_url) {
            userb.avatar_url = response.data.avatarUrl;
        }
        auth.updateUser(userb);
    }

    const updateArtistIdNewArtist = () => {
        const userb = {...auth.user};
        userb[`${props.platformNameId}_id`] = fHandler.formValues.platform_id;
        auth.updateUser(userb);
    }

    const handleSubmit = () => {
        const formValues = fHandler.formValues;
        
        setErrors(false);

        const url = `${API_URL}artist/${newUrl}`;

        setIsSubmitting(true);
        const config = {
            method: 'post',
            url,
            data: formValues,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };


        axios(config).then((response) => {
            log(response);
            updateArtistId(response);
            window.location.reload();
        })
        .catch((error) => {
            if (error.response && error.response.status === 302) {
                updateArtistIdNewArtist();
                window.location.reload();
            } else {
                log(error.response.data.errors);
                log(error);
                const resMessage = error.response?.data?.message;
                
                setErrors(resMessage || "Something went wrong, the ID might not exist.");
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 
    }

    return (
        <Stack spacing={{xs: 1, md: 3}}  direction={{xs: "column", md: "row"}} className="add-social-id-form-wrapper">
            <TextField 
                fullWidth 
                name="platform_id" 
                label={props.placeholder || `Username`} 
                value={fHandler.formValues?.platform_id || ''} 
                onChange={fHandler.handleValChange} 
                error={Boolean(errors)} 
                helperText={errors} 
                className="add-socials-input"
                size="small"
            />
            <TextField 
                fullWidth 
                name="platform_id_confirmation" 
                label={`Confirm ${lowerCase(props.placeholder) || 'username'}`} 
                value={fHandler.formValues?.platform_id_confirmation || ''} 
                onChange={fHandler.handleValChange} 
                error={Boolean(errors)} 
                helperText={errors} 
                className="add-socials-input"
                size="small"
            />
            {isSubmitting ? 
                <IconButton 
                className="add-socials-button" 
                >
                    <Iconify icon="eos-icons:loading" width={24}/>
                </IconButton>
            :
                <IconButton 
                    className="add-socials-button" 
                    onClick={handleSubmit}
                >
                    <Iconify icon="foundation:plus" width={24}/>
                </IconButton>
            }
        </Stack>
    )
}