import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";
import StyledBox from "../../theme/artist/overrides/StyledBox";
import ArtistThemeProvider from "../../theme/artist";
import Button from "../../theme/admin/overrides/Button";
import { APP_NAME, TITLE_SEPERATOR } from "../../hooks/globals";

export default function CancelCredits() {
    const redirectTo = () => {
        window.location.replace('/artist/artwork');
    }

    return (
        <>
            <Helmet>
                <title>Order canceled {TITLE_SEPERATOR} {APP_NAME}</title>
            </Helmet>
            <StyledBox>
                <Typography variant="h1" textAlign="center">Order canceled.</Typography>
                <ArtistThemeProvider>
                    <Box sx={{height: '69.5vh', pt: 8}}>
                        <Typography variant="h1" textAlign="center">Thanks for your order, credits will be added to your account</Typography>
                        <Button sx={{display: 'block', margin: 'auto', marginTop: '20px'}} variant="contained" onClick={() => redirectTo()}>Go to artwork</Button>
                    </Box>
                </ArtistThemeProvider>
            </StyledBox>
        </>
    )
}