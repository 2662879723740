import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Container, useTheme } from '@mui/material';
// hooks
import { useParams, Link } from 'react-router-dom';
import { APP_NAME, TITLE_SEPERATOR } from '../../../hooks/globals';
import useResponsive from '../../../hooks/useResponsive';
// sections
import ArtistLoginForm from './ArtistLoginForm';
import ResponsiveAppBar from '../../../layouts/artist-dashboard/nav/ResponsiveAppBar';
import StyledTypography from '../../../theme/artist/overrides/StyledTypography';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(14, 0),
}));

// ----------------------------------------------------------------------

export default function ArtistLoginPage(props) {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
  const params = useParams();

  return (
    <Box sx={{ backgroundColor: "#000" }}>
      <Helmet>
        <title> Login {TITLE_SEPERATOR} {APP_NAME} </title>
      </Helmet>

      <ResponsiveAppBar />

      <StyledRoot sx={{ backgroundImage: "url(/assets/backgrounds/login.png)", backgroundRepeat: "no-repeat", backgroundPosition: "50% 46%", backgroundSize: "800px" }}>

        <Container maxWidth="sm">
          <StyledContent>
            <img style={{ width: "319px", marginLeft: "auto", marginRight: "auto" }} src="/assets/logo-white.png" alt="logo white" />

            <ArtistLoginForm register={props.register} />
            {params.message && <Alert severity="info" sx={{ mt: 2 }}>{params.message}</Alert>}

            {!props.register && <StyledTypography sx={{ marginTop: "20px" }}>If you don’t have an account yet, <Link to={'/register-free'}>register free</Link></StyledTypography>}
          </StyledContent>
        </Container>
      </StyledRoot>
    </Box>
  );
}
