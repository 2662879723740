
import { useEffect, useState } from "react";
import axios from "axios";
import { useModal } from "../modals/ModalContext";
import { useAuth } from "../auth/Authentication";
import { API_URL } from "./globals";
import useConsoleLog from "./useConsoleLog";

export function useDelete(path, snackHandler) {
    const auth = useAuth();
    const log = useConsoleLog;
    const [errors, setErrors] = useState('');
    const [deletedId, setDeletedId] = useState();
    const modal = useModal();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = (id, path) => {
        setIsLoading(true);
        const url = `${API_URL}${path}/${id}`;
        
        const config = {
            method: 'delete',
            url,
            data: id,
            headers: { 
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        setDeletedId(parseInt(id, 10));

        axios(config).then((response) => {
            log(response);
            setErrors('');
            modal.handleDelClose();
        })
        .catch((error) => {
            log(error);
            setErrors("Something went wrong on our side");

            setDeletedId(null);
        })
        .finally(() => {
            setIsLoading(false);
            log(deletedId);
        });
    }

    const handleMultiDelete = (ids, path) => {
        setIsLoading(true);
        log(ids);
        const url = `${API_URL}${path}/delete-multiple`;
        
        const config = {
            method: 'post',
            url,
            data: {delIds: ids},
            headers: { 
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        setDeletedId(ids);

        axios(config).then((response) => {
            log(response);
            setErrors('');
            modal.handleDelClose();
        })
        .catch((error) => {
            log(error);
            setErrors("Something went wrong on our side");

            setDeletedId(null);
        })
        .finally(() => {
            setIsLoading(false);
            log(deletedId);
        });
    }

    useEffect(() => {
        setErrors('');
    }, [modal.delOpen]);

    return {handleDelete, handleMultiDelete, deletedId, errors, isLoading}
}