import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { APP_NAME, TITLE_SEPERATOR } from "../../hooks/globals";

export default function Cancel() {
    return (
        <>
            <Helmet>
                <title>Order canceled {TITLE_SEPERATOR} {APP_NAME}</title>
            </Helmet>
            <Typography variant="h1" textAlign="center">Sorry to see you go, you can always finish your order here</Typography>
        </>
    )
}