import { Box, Stack, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../../../components/iconify";
import { APP_NAME, TITLE_SEPERATOR } from "../../../../hooks/globals";
import useResponsive from "../../../../hooks/useResponsive";
import Explaination from "../explainations/Explaination";
import Heading from "../heading";
import Amount from "../images-left/Amount";
import Layout from "../Layout";
import faqs from "./config";

export default function Faq() {
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  return (
    <Layout>
      <Helmet>
        <title>AI album cover {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
      <Heading
        title="AI album cover"
      />
      <Box sx={{pb: "86px"}}>
        <Stack spacing={3}>
          <Explaination heading={faqs[0].heading} text={faqs[0].text}/>
          <Typography>
          For the best results, make sure that the prompt that you fill in is as detailed as possible. Also detailing the art style that you want the images to be in is important. Make sure that you avoid filling in prompts that visualize text. The AI has difficulties with generating an image that includes text. For example, don’t specifically tell the AI to make an album cover. When this is included in the prompt, the Ai automatically thinks that you will want to have a cover text, which will most often not look completely correct.
          </Typography>
        </Stack>
      </Box>
      <Box sx={{pb: "56px"}}>
        <Stack spacing={3}>
          <Explaination key={faqs[1].heading} heading={faqs[1].heading} text={faqs[1].text}/>
          <Explaination key={faqs[2].heading} heading={faqs[2].heading} text={faqs[2].text}/>
        </Stack>
      </Box>
      <Box>
        <Stack spacing={3}>
          <Explaination key={faqs[3].heading} heading={faqs[3].heading} text={faqs[3].text}/>
          <Explaination key={faqs[4].heading} heading={faqs[4].heading} text={faqs[4].text}/>
        </Stack>
      </Box>
    </Layout>
  )
}