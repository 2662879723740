import { Box, Typography, Grid } from "@mui/material";
import OverlayItem from "../../../../components/overlayItem";
import Footer from "../../../../layouts/loggedout/Footer";
import Iconify from "../../../../components/iconify";

export default function LoadingScreen() {
  return (
    <>
    <Box className="loading-screen-wrapper bg-neutral">
      <Box className="loading-screen-text contrast-neutral-bg">
        <Typography variant="h4">
          Tip
        </Typography>
        <Typography variant="body1">
          Try to be as specific as possible. The more information the better!
        </Typography>
      </Box>
      <Box className="loading-icon contrast-neutral-bg">
        <Iconify icon="eos-icons:loading" width={50}/>
      </Box>
      <Box sx={{width: "300px"}} className="loading-screen">
        <img width="300px" src="/assets/ai-artwork/gallery-examples/cats.png" alt="monsmonkey"/>
        <OverlayItem/>
        <Typography variant="body1">
        A photo of two cats watching a movie together, television in the background
        </Typography>
      </Box>
    </Box>
      <Footer/>
    </>
  )
}