// @mui
import PropTypes from 'prop-types';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

ArtistAppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function ArtistAppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  const theme = useTheme();
 
  const lastWords = (title, lastWords = false) => {
    const titleArr = title.split(" ");
    const firstWord = titleArr.shift(); // get the first word

    if (lastWords) {
      return titleArr.map((titlePiece) => (`${titlePiece} `));
    }

    return firstWord;
  }
  
  return (
    <>
      <Card
        sx={{
          display: {xs: "none", md: "block"},
          py: '15%',
          px: 0,
          boxShadow: "0px 3px 5px rgba(0,0,0,0.16 )",
          textAlign: 'center',
          bgcolor: (theme) => theme.palette.grey[200],
          ...sx,
          borderRadius: "5px",
          height: "14vw",
          "&:before": {
            content: '""',
            display: "block",
            height: "78%",
            width: "4px",
            position: "absolute",
            bottom: 0,
            backgroundColor: color
          },
          "&:after": {
            content: '""',
            display: "block",
            height: "4px",
            width: "78%",
            position: "absolute",
            bottom: 0,
            backgroundColor: color
          },
        }}
        {...other}
      >
        <Typography variant="subtitle2" sx={{ 
        fontSize: {lg: "1.4rem", md: "1.2rem"},
          fontWeight: 400,
          color: theme.palette.common.black
        }}>
          {lastWords(title)}
        </Typography>

        <Typography variant="h3" sx={{fontSize: {lg: "2rem", md: "1.2rem"}, marginTop: '14%', fontWeight: "bold", color}}>{fShortenNumber(total)}</Typography>

        <Typography variant="subtitle2" sx={{ 
          fontSize: "1rem",
          fontWeight: 400,
          color: theme.palette.common.black,
          marginTop: "6%"
        }}>
          {lastWords(title, true)}
        </Typography>
      </Card>
      <Grid alignItems={"center"} justifyContent={"space-between"} container sx={{paddingLeft: "30px", paddingRight: "30px", display: {md: "none", xs: "flex"}}}>
          <Typography variant="subtitle2" sx={{ 
              fontSize: "20px",
              fontWeight: 400,
              color: theme.palette.common.black,
              marginTop: "16px"
            }}>
              {title}
          </Typography>
          <Typography variant="h3" sx={{fontSize: "25px", fontWeight: "bold", color}}>{fShortenNumber(total)}</Typography>
          <Box sx={{marginTop: "28px", marginBottom: "27px", height: "1px", width: "100%", backgroundColor: theme.palette.grey[300] }}/>
      </Grid>
    </>
  );
}
