import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetListing from "../api/useGetListing";
import useConsoleLog from "./useConsoleLog";

export default function useShowResource(path) {
  const getListing = useGetListing();
  const params = useParams();
  const [resource, setReource] = useState({loading: true});
  const [errors, setErrors] = useState();
  const log = useConsoleLog;

  useEffect(() => {
    const request = getListing.getListing(`${path}${params.id}`)

    request.then((response) => {
      setReource(response.data);
      log(errors);
      log(response.data);
    }).catch((errors) => {
      setErrors(errors);
      log(errors);
    });
  }, []);

  return resource;
}