import useShowResource from "../../../hooks/useShowResource";
import ShowLayout from "../../../layouts/dashboard/show/ShowLayout";
import RowLayout from "../../../layouts/dashboard/show/RowLayout";
import LoadingLayout from "../../../layouts/dashboard/show/LoadingLayout";

export default function ShowArtist() {
  const resource = useShowResource('admin/artists/');

  return (
    <ShowLayout>
      {resource?.loading ?
          <LoadingLayout skeletons={5}/>
        :
          <>
            <RowLayout label="First name" value={resource?.firstName}/>
            <RowLayout label="Last name" value={resource?.lastName}/>
            <RowLayout label="E-mail" value={resource?.email}/>
            <RowLayout label="Spotify ID" value={resource?.spotifyId}/>
            <RowLayout label="Created at" value={resource?.createdAt}/>
          </>
      }
    </ShowLayout>
  )
}