import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { APP_NAME, TITLE_SEPERATOR } from "../../hooks/globals";
import ArtistThemeProvider from "../../theme/artist";
import { useAuth } from "../../auth/Authentication";
import usePost from "../../api/usePost";
import useGetListing from "../../api/useGetListing";
import StyledBox from "../../theme/artist/overrides/StyledBox";
import useConsoleLog from "../../hooks/useConsoleLog";

export default function ThankYouCredits() {
    const log = useConsoleLog;
    const geListing = useGetListing();
    const auth = useAuth();
    const [searchParams] = useSearchParams();

    const redirectTo = () => {
        window.location.replace('/artist/artwork');
    }

    useEffect(() => {
        log(searchParams.get('id'));
        geListing.getListing(`artist/artwork/check-credit-payment-new/${searchParams.get('id')}`)
        .then((res) => {
            const userb = {...auth.user, ...res.data};
            auth.updateUser(userb)
            log(res)
        }).catch((err) => {
            log(err);
        });
    }, []);

    return (
        <StyledBox>
            <Helmet>
                <title>Thank you {TITLE_SEPERATOR} {APP_NAME}</title>
            </Helmet>
            <ArtistThemeProvider>
                <Box sx={{height: '69.5vh', pt: 8}}>
                        <Typography variant="h1" textAlign="center">Thanks for your order, credits will be added to your account</Typography>
                        <Button sx={{display: 'block', margin: 'auto', marginTop: '20px'}} variant="contained" onClick={() => redirectTo()}>Go to artwork</Button>
                </Box>
            </ArtistThemeProvider>
        </StyledBox>
    )
}