import React from "react";
import { Snackbar, Alert } from "@mui/material";

const Snacks = (props) => {                  
    const {snack} = props;

    return (
    <>
        <Snackbar open={snack.success} autoHideDuration={6000} onClose={snack.handleSClose}>
            <Alert onClose={snack.handleSClose} severity="success" sx={{ width: '100%' }}>
            Success!
            </Alert>
        </Snackbar>
        
        <Snackbar open={snack.error} autoHideDuration={6000} onClose={snack.handleEClose}>
            <Alert onClose={snack.handleEClose} severity="error" sx={{ width: '100%' }}>
            Something went wrong!
            </Alert>
        </Snackbar>
    </>
    )
}

export default Snacks;