import { useTheme } from "@emotion/react";
import { Typography, Grid, Stack } from "@mui/material";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";
import Arrow from "../../../components/arrow-bg/Arrow";
import ArtistItemPage from "../../../layouts/artist-dashboard/listing-layout/ArtistListingLayout";

export default function AudienceOverview(props) {
    const theme = useTheme();
    const platformsWitoutAudiences = ["amazon", "shazam", "itunes", "deezer", "apple_music", "instagram", "soundcloud", "beatport", "traxsource", "tidal", "tracklist"]

    return (
        <Stack gap={3} position="relative">
            <Grid container justifyContent="space-between">
                <Typography textAlign="left" variant="h4" sx={{fontSize: "30px", paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>Audience overview</Typography>
                {props.orderArrows}
            </Grid>
            <Arrow platformId={props.platformId}/>
            <StyledPaper
             className="audience-overview"
            sx={
                {
                    zIndex: 999,
                    borderRadius: {md: "10px", xs: "0"}, 
                    backgroundColor: theme.palette.grey[200],
                    "&:before": {
                        backgroundColor: {xs: "transparent", md: props.platformColor}
                    },
                    "&:after": {
                        backgroundColor: {xs: "transparent", md: props.platformColor}
                    },
            }}>
                {
                    platformsWitoutAudiences.includes(props.platformId) ?
                    <Typography className="no-data">
                        Audience overview is not supported for this platform.
                    </Typography> 
                    :
                    props.audience?.monthly_listeners?.length ?
                    props.platformId === "spotify" &&
                    <ArtistItemPage
                        name="Audience" 
                        TABLE_HEAD={[
                            { id: 'city_name', label: 'Location', alignRight: false },
                            { id: 'current_listeners', label: 'Monthly listeners', alignRight: false },
                            { id: 'peak_listeners', label: 'Peak monthly listeners', alignRight: false },
                        ]}
                        objectKeys={["id", "city_name"]}
                        itemList={props.audience?.monthly_listeners}
                        platformColor={props.platformColor}
                        peakType="peak_listeners"
                        currentType="current_listeners"
                        platformId={props.platformId}
                    />
                :
                    props.platformId === "spotify" &&
                    <Typography
                        className="no-data"
                    >
                        No data found
                    </Typography>
                }
                {
                    props.audience?.views?.length ? 
                    props.platformId === "youtube" &&
                    <ArtistItemPage
                        name="Audience" 
                        TABLE_HEAD={[
                            { id: 'city_name', label: 'Location', alignRight: false },
                            { id: 'current_views', label: 'Monthly views', alignRight: false },
                            { id: 'peak_views', label: 'Peak monthly views', alignRight: false },
                        ]}
                        objectKeys={["id", "city_name"]}
                        itemList={props.audience?.views}
                        platformColor={props.platformColor}
                        peakType="peak_views"
                        currentType="current_views"
                        platformId={props.platformId}
                    />
                :
                    props.platformId === "youtube" &&
                    <Typography
                        className="no-data"
                    >
                        No data found
                    </Typography>
                }
                {
                props.audience?.creators?.length ? 
                props.platformId === "tiktok" && <ArtistItemPage
                    name="Audience" 
                    TABLE_HEAD={[
                        { id: 'city_name', label: 'Location', alignRight: false },
                        { id: 'creators_total', label: 'Total creators', alignRight: false },
                        { id: 'creator_reach_total', label: 'Total creator reach', alignRight: false },
                    ]}
                    objectKeys={["id", "city_name"]}
                    itemList={props.audience?.creators}
                    platformColor={props.platformColor}
                    peakType="creator_reach_total"
                    currentType="creators_total"
                    platformId={props.platformId}
                />
                :
                    props.platformId === "tiktok" &&
                    <Typography
                        className="no-data"
                    >
                        No data found
                    </Typography>
                }
            </StyledPaper>
        </Stack>
    )
}