import { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Typography, Stack, Box, Grid, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import StyledShadowBox from "../../../../theme/artist/overrides/StyledShadowBox";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import StyledTypography from "../../../../theme/artist/overrides/StyledTypography";
import StyledTextField from "../../../../theme/artist/overrides/StyledTextField";
import { useFormHandler } from "../../../../hooks/useFormHandler";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import { API_URL } from "../../../../hooks/globals";
import UpgradePlanSelector from "./UpgradePlanSelector";
import PlanFeatures from "../../../../layouts/artist-dashboard/PlanFeatures";
import CancelCancellation from "./CancelCancellation";

SubscriptionDetails.propTypes = {
    auth: PropTypes.object
}

export default function SubscriptionDetails({auth}) {
    const theme = useTheme();
    const [startCancel, setStartCancel] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpgrading, setIsUpgrading] = useState(false);
    const [errors, setErrors] = useState();
    const [successMsg, setSuccessMsg] = useState()
    const fHandler = useFormHandler("artist/update-info");
    const log = useConsoleLog;

    

    const cancelSubscription = () => {
        const url = `${API_URL}artist/cancel-subscription`;
        
        log(fHandler.formValues);

        setIsSubmitting(true);
        setSuccessMsg('');
        setErrors({});

        const config = {
            method: 'post',
            url,
            data: fHandler.formValues,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            auth.checkSubscription(false);
            setSuccessMsg("Successfully canceled!");
            setStartCancel(false);
        })
        .catch((error) => {
            log(error);
            auth.handleUnauthorized(error);
            
            if (error.response.status === 500) {
                setErrors({messageIse: 'Something went wrong on our side!'})
            } else {
                setErrors(error.response.data.errors);
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 
    }

    return (
        <>
            <Stack gap={{md: 5, xs: 2}} sx={{maxWidth: "450px", marginTop: {md: 0, xs: 2}, ml: {xs: "0", md: "auto"}}}>
                <Typography variant="h4">Subscription details</Typography>
                <StyledShadowBox sx={{padding: "50px", backgroundColor: theme.palette.grey[200]}}>
                {auth.user.subscription?.id && auth.user.subscription.status !== "cancelled" ? 
                auth.user.subscription?.type === "revcat" ?
                    <Typography>
                Since you subscribed through our mobile app, management of your subscription is exclusively available via the app.</Typography>
                :
                    <Grid container spacing={3}> 
                        <Grid item md={8} order={{md: 0, xs: 1}}>
                            <Typography sx={{
                                fontSize: "17px",
                                fontWeight: "bold",
                                color: theme.palette.common.black,
                            }}>Current plan</Typography>
                            <Typography sx={{fontSize: "17px", mt: "32px"}}>{auth.user.subscription.plan.name}</Typography>
                            <Box sx={{marginBottom: "39px", height: "2px", backgroundColor: theme.palette.platform.spotify}}/>
                            <PlanFeatures features={auth.user.subscription.plan.permissionNames} spacing={2.5}/>
                        </Grid>
                        <Grid item md={4} order={{md: 1, xs: 0}}>
                            <Typography sx={{color: theme.palette.platform.spotify, fontSize: "40px", fontWeight: "bold"}}>${auth.user.subscription.plan.price}</Typography>
                        </Grid>
                        <Grid item md={12} order={2}>
                            {auth.user.subscription.cancelledAt && !auth.user.subscription.nextBillingAt ? 
                                <><CancelCancellation onSetCancelSuccessMsg={setSuccessMsg}/></> 
                            :
                                <>
                                    <Typography sx={{mt: "56px"}}>Next payment<br/>{auth.user.subscription.nextBillingAt || "None"}</Typography>
                                    {!auth.user.subscription.plan.id.includes("Advanced") &&
                                    <>
                                        <StyledLoadingButton sx={{mt:"22px", width: "172px"}} onClick={() => setIsUpgrading(!isUpgrading)}>Upgrade plan</StyledLoadingButton>
                                        
                                        {isUpgrading && <UpgradePlanSelector/>}
                                    </>
                                    }
                                    
                                    <Typography sx={{textDecoration: "underline", mt: "27px", cursor: "pointer"}} onClick={() => setStartCancel(!startCancel)}>Cancel subscription</Typography>
                                    {startCancel && 
                                    <Box sx={{mt: "20px"}}>
                                        <Typography>What is your reason for canceling?</Typography>
                                        <StyledTextField multiline name="reason" fullWidth hiddenLabel value={fHandler.formValues?.reason} onChange={fHandler.handleValChange} error={errors?.reason} helperText={errors?.reason}/>
                                        <StyledLoadingButton sx={{mt: "10px"}} loading={isSubmitting} onClick={() => cancelSubscription()}>Submit and cancel</StyledLoadingButton>
                                    </Box>
                                    }
                                </>
                            }
                            {errors?.messageIse && <Alert severity="error">{errors?.messageIse}</Alert>}
                            {successMsg && <Alert className="mt-2" severity="success">{successMsg}</Alert>}
                        </Grid>
                    </Grid> :
                    <Typography>You have no subscription yet, get one <Link to="/checkout">here</Link>.</Typography>
                    }
                </StyledShadowBox>
            </Stack>
        </>
    )
}