import { Card, Typography, Box, Stack } from "@mui/material";
import { func, number } from "prop-types";
import { useEffect, useState } from "react";
import useConsoleLog from "../../../hooks/useConsoleLog";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";

IndividualContribution.propTypes = {
  planted: number,
  onSetOpen: func
}

export default function IndividualContribution({planted, onSetOpen}) {

  return (
    <Stack spacing={2}>
    <Typography className="contibution-title">
      Your contribution
    </Typography>
    <Card className="tree-collective-card">
      <img src="/assets/trees/treei.svg" alt="trees" className="trees-img-indi"/>
      <Typography className="planted-title-indi">
        Trees planted
      </Typography>
      <Typography className="planted-indi">
        {planted}
      </Typography>
    </Card>
  </Stack>
  )
}