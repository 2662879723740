import { Link } from "react-router-dom";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";
import Iconify from "../../../components/iconify";
import useResponsive from "../../../hooks/useResponsive";

export default function GalleryButton() {
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  return (
    <Link className="gallery-link" to={'/artist/artwork/gallery'}>
      <StyledLoadingButton variant="contained" className="gallery-btn">
        {!isOnSmallScreen && "Your gallery"} <Iconify icon="ph:image"/>
      </StyledLoadingButton>
    </Link>
  )
}