import { Helmet } from 'react-helmet-async';
import ReactCountryFlag from 'react-country-flag';
import { filter, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
// @mui
import {
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import lookup from 'country-code-lookup';
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Scrollbar from '../../../components/scrollbar';
// sections
import ArtistItemListHead from './ArtistItemListHead';
import ArtistItemListToolbar from './ArtistItemListToolbar';
import useResponsive from '../../../hooks/useResponsive';
import useConsoleLog from '../../../hooks/useConsoleLog';

// ----------------------------------------------------------------------


export default function ArtistItemPage(props) {
  const theme = useTheme();
  const countryOnlyPlatforms = ["tiktok"];
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const log = useConsoleLog;

  function descendingComparator(a, b, orderBy) {
    let theA = a[orderBy];
    let theB = b[orderBy];
    
    if (orderBy === 'createdAt') {
      theA = new Date(a[orderBy]?.split("-").reverse().join("-"));
      theB = new Date(b[orderBy]?.split("-").reverse().join("-"));
    }
  
    if (theB < theA) {
      return -1;
    }
    if (theB > theA) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    let filterValues = [];

    if (countryOnlyPlatforms.includes(props.platformId)) {
      filterValues = array?.map((el) => {
        const flag = <ReactCountryFlag key={el.country_code} style={{height: "33.4px", width: "44.5px"}} svg countryCode={el.country_code}/>
        
        return {
          city_name: [flag, ` ${lookup.byIso(el.country_code).country}`], 
          [props.currentType]: el[props.currentType],
          [props.peakType]: el[props.peakType]
        }
      });
    }

    if (locationType === "city" && !countryOnlyPlatforms.includes(props.platformId)) {
      filterValues = array?.map((el) => {
        const flag = <ReactCountryFlag style={{height: "33.4px", width: "44.5px"}} svg countryCode={el.country_code}/>
        
        return {
          city_name: [flag, ` ${el.city_name}`], 
          [props.currentType]: el[props.currentType],
          [props.peakType]: el[props.peakType]
        }
      });
    } else {
      filterValues = array?.reduce((acc, el) => {
        const countryCode = el.country_code;
        const countryName = lookup.byIso(countryCode);
        const flag = <ReactCountryFlag style={{height: "33.4px", width: "44.5px"}} svg countryCode={countryCode}/>;
      
        const existing = acc.find(item => isEqual(item.city_name[1].trim(), countryName.country.trim()));
      
        if (existing) {
          existing[props.currentType] += el[props.currentType];
          existing[props.peakType] += el[props.peakType];
        } else {
          acc.push({
            city_name: [flag, ` ${countryName.country}`],
            [props.currentType]: el[props.currentType],
            [props.peakType]: el[props.peakType]
          });
        }
      
        return acc;
      }, []);
    }

    const stabilizedThis = filterValues?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(filterValues, (_item) => {
        return _item[props.objectKeys[1]][1]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
      });
        
    }
    return stabilizedThis?.map((el) => el[0]);
  }
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState(props.peakType);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filteredItems, setFilteredItems] = useState();

  const [locationType, setLocationType] = useState("city");


  //  end of order status change


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  useEffect(() => {
    setFilteredItems(applySortFilter(props.itemList, getComparator(order, orderBy), filterName));
  }, [filterName, order, orderBy, props.itemList, locationType]);

  return (
    <>
      <ArtistItemListToolbar countryOnly={countryOnlyPlatforms.includes(props.platformId)} platformColor={props.platformColor} onHandleLocationType={setLocationType} locationType={locationType} key={1} filterName={filterName} onFilterName={handleFilterByName} />

        <TableContainer sx={{ paddingTop: {md: "53.1px", xs: 0}, paddingRight: {xs: 0, md: "112px"}, paddingLeft: {xs: 0, md: "112px"} }}>
          <Table>
          {!isOnSmallScreen &&
            <ArtistItemListHead
              order={order}
              orderBy={orderBy}
              rowCount={props.itemList?.length}
              onRequestSort={handleRequestSort}
              headLabel={props.TABLE_HEAD}
            />}
            <TableBody>
                
              {filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowKey) => {
                const { id } = row;
                const cells = Object.values(row);
                const cellKeys = Object.keys(row);
                log(row);

                return (
                  <>
                  <TableRow sx={{maxWidth: "100%", paddingBottom: "11px"}} hover key={`row-${rowKey}-${id}-${row[Object.keys(row)[1]]}`} tabIndex={-1}>
                    {isOnSmallScreen ?
                    <TableCell colSpan={1} sx={{fontSize: "20px", fontWeight: 400, color: theme.palette.common.black, paddingLeft: 0, borderColor: theme.palette.grey[300], py: "22px"}}>
                      <Grid container justifyContent={"space-between"} spacing={3}>
                        <Grid item xs={12}>
                          {cells[0]}
                        </Grid>
                        <Grid item xs={6}>
                          <Stack>
                            <Typography sx={{"&:first-letter": {textTransform: "uppercase"}}}>
                              {cellKeys[1].replace("_", " ")}
                            </Typography>
                            <Typography variant="h3" sx={{fontSize: "17px", fontWeight: "bold", color: props.platformColor}}>
                              {fShortenNumber(cells[1])}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack>
                            <Typography textAlign={"right"}  sx={{"&:first-letter": {textTransform: "uppercase"}}}>
                              {cellKeys[2].replace("_", " ")}
                            </Typography>
                            <Typography textAlign={"right"} variant="h3" sx={{fontSize: "17px", fontWeight: "bold", color: props.platformColor}}>
                              {fShortenNumber(cells[2])}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </TableCell>
                    :
                    cells.map((value, key) =>
                         (
                        <TableCell colSpan={1} key={`ztablecell-${key}-${value}`} align="left" size="small" sx={{fontSize: "20px", fontWeight: 400, color: theme.palette.common.black, paddingLeft: 0, borderColor: theme.palette.grey[300], py: "12px"}}>
                            {typeof value === "number" ? fShortenNumber(value) : value}
                        </TableCell>
                        )
                    )}
                  </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
                      
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredItems?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
