import { Typography, Box, Stack, useTheme } from "@mui/material";
import Iconify from "../../components/iconify/Iconify";
import useCheckMatchingArrayValues from "../../hooks/useCheckMatchingArrayValues";
import useConsoleLog from "../../hooks/useConsoleLog";

export default function PlanFeatures(props) {
    const theme = useTheme();
    const log = useConsoleLog;

    return (
        <>
            {props.features.map((feature) => {

                return (
                <Stack direction="row" spacing={1} key={`${feature}-included`} sx={{marginTop: props.spacing || 0}}>
                    <Box sx={{width: "10%"}}>
                        <Iconify style={{color: theme.palette.platform.spotify}} icon="material-symbols:check-circle" width="22px"/>
                    </Box>
                    <Box sx={{width: "90%"}}>
                        <Typography variant="body1" sx={{fontSize: "16px"}}>{feature}</Typography>
                    </Box>
                </Stack> )
                
                })}
            {props.allFeatures?.map((feature) => {
                if (!props.features.includes(feature)) {

                    return (
                    <Stack direction="row" spacing={1} key={`${feature}-excluded`} sx={{marginTop: props.spacing || 0}}>
                        <Box sx={{width: "10%"}}>
                            <Iconify style={{color: "#CCCCCC"}} icon="gridicons:cross-circle" width="22px"/>
                        </Box>
                        <Box sx={{width: "90%"}}>
                            <Typography variant="body1" sx={{fontSize: "16px"}}>{feature}</Typography>
                        </Box>
                    </Stack>  
                    ) 
                }

                return false;
                })}
        </>
    )
}