const faqs = [
  {
    heading: 'How to use the image generator',
    text: `With the AI image generator, you will have the opportunity to generate your next album cover through the help of artificial intelligence. The first thing that you would need to do, is to fill in a prompt. The AI will turn this prompt into three different images.`,
  },
  {
    heading: 'Variations',
    text: 'When an image has been generated which you particularly like, you can choose to make three different variations of this image. This will cost three image tokens.',
  },
  {
    heading: 'The point system',
    text: 'There is a limit to how many images you can generate. This amount is capped to … images per month. To keep track of this limit, a point system is used. Each generated image will cost one point and three images are generated for each prompt. This means that each prompt will use three points. There is always an option to add more points to your account. This way you can generate as much images as you would like.',
  },
  {
    heading: 'Your personal gallery',
    text: 'To keep track of all your generated images, you have access to your personal image gallery. All your generated images will be shown in here, so you will never lose track of your previous work.',
  },
  {
    heading: 'The watermark',
    text: 'Our AI works on the software of DALL-E 2. The images generated through our website contain a small watermark in the bottom right-hand corner. You can recognize this watermark by the five colored squares. You are completely free to remove this watermark. This will have no consequences and you are free to use the image for without it.',
  },
]

export default faqs;