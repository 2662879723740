import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Alert } from '@mui/material';
// components
import Iconify from '../../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';
import { APP_NAME, API_URL, TITLE_SEPERATOR } from '../../hooks/globals';
import useGetListing from '../../api/useGetListing';
import useConsoleLog from '../../hooks/useConsoleLog';
import useGetSkeletons from '../../hooks/useGetSkeletons';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [getSkeletons] = useGetSkeletons();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const getListing = useGetListing();
  const log = useConsoleLog;
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getListing.getListing(`admin/user-stats`).then((response) => {
      log(response);
      const data = response.data;
  
      setChartData(data.map((element) => (element.count)));
      setChartLabels(data.map((element) => (element.week_start)));
     

      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      setError('Something went wrong loading the data.');
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet> 

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            
            <Typography variant="h4" sx={{ mb: 5 }}>
              Weekly sign ups
            </Typography>
            {loading ?
              getSkeletons(1, '100%', '379px')
              :
              error ? 
              <Alert severity='error'>
                {error}
              </Alert> 
              :
              <AppWebsiteVisits
                title="Weekly signups"
                chartLabels={chartLabels}
                chartData={[
                  {
                    name: 'YouGrow sign ups',
                    type: 'area',
                    fill: 'gradient',
                    data: chartData,
                  },
                ]}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
