import { useState } from "react";
import InvoiceListingLayout from "./InvoiceListingLayout";

export default function Invoices() {
    return (
        <InvoiceListingLayout
            name="Invoices" 
            TABLE_HEAD={[
            { id: 'date', label: 'Order date', alignRight: false },
            { id: 'plan', label: 'Name of package', alignRight: false },
            { id: 'price', label: 'Price', alignRight: false },
            { id: 'invoice', label: 'Invoice', alignRight: false },
            ]}
            apiPath="artist/get-invoices-new"
            pageTitle="Your payment history"
            objectKeys={['id', 'name']}
        />
    )
}