import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Typography, useTheme, Grid, Box, RadioGroup, FormControl, Stack, FormControlLabel, Radio, Alert } from "@mui/material";
import Package from "./Package";
import MostPopular from "./MostPopular";
import Heading from "../heading";
import { APP_NAME, TITLE_SEPERATOR } from "../../../../hooks/globals";
import useGetListing from "../../../../api/useGetListing";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import useGetSkeletons from "../../../../hooks/useGetSkeletons";
import Layout from "../Layout";
import CheckoutModal from "../../../../auth/register-checkout/CheckoutModal";
import usePost from "../../../../api/usePost";

export default function AddCredits() {
  const theme = useTheme();
  const post = usePost();
  const [isLoading, setIsLoading] = useState(false);
  const [rendering, setRendering] = useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [error, setError] = useState(false);
  const [plans, setPlans] = useState([]);
  const getListing = useGetListing();
  const log = useConsoleLog;
  const [skeletons] = useGetSkeletons();



  const getPlans = () => {
    setRendering(true);
    
    getListing.getListing('get-plans-new/charge')
    .then((response) => {
      setPlans(response.data.plans);
      log(response);
    })
    .catch((err) => {
      log(err);
    }).finally(() => {
      setRendering(false);
    })
  }

  useEffect(() => {
    getPlans();
  }, [])

  
  const buy = (id) => {
    log(id);
    setIsLoading(true);

    post(`checkout-new-authed-credits/${id}`).then((res) => {
      log(res);
      if (res.data.url) {
        setCheckoutUrl(res.data.url);
        setCheckoutModalOpen(true);
      } else {
        setError(true);
      }
    }).catch((err) => {
      setError(true);
      log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Layout>
      <Box className="package-container">
        <Helmet>
          <title>Get artwork credits {TITLE_SEPERATOR} {APP_NAME}</title>
        </Helmet>
        <Heading
          title="Get artwork credits"
        />
        
        <Grid container spacing={{md: 4.5, xs: 0}} rowSpacing={4} className="packages" sx={{
          backgroundColor: theme.palette.common.white
        }}>
          {!rendering ?
          plans.map((plan, index) => (
            <Grid item md={4} xs={12}>
            {index === 1 && <MostPopular/> }
              <Package 
                id={plan.id} 
                price={plan.price}
                name={plan.externalName} 
                loading={isLoading} 
                setLoading={setIsLoading}
                paymentMethod={selectedPaymentMethod}
                onIsLoading={isLoading}
                onSetIsLoading={setIsLoading}
                onBuy={buy}
                />
            </Grid>
          )):
          skeletons(3, "100%", "311.1px").map((skeleton) => (
            <Grid item md={4} xs={12}>
              {skeleton}
            </Grid>
          ))
          }
          <Grid item xs={12}>
            {error &&
              <Alert severity="error">Something went wrong, please contact support</Alert>
            }
          </Grid>
        </Grid>
      </Box>
      <CheckoutModal
        onOpen={checkoutModalOpen}
        checkoutUrl={checkoutUrl}
        onHandleClose={() => setCheckoutModalOpen(false)}
        />
    </Layout>
  )
}