import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";

const CTABox = () => {
  return (
    <Box className="white-box cta-wrapper bg-neutral">
      <Typography className="cta-title">
        Want to start a new campaign?
      </Typography>
      <Typography className="cta-description">
        Ready to amplify your music? Kickstart your success with 
        our tailored promotional campaigns for artists and start a 
        new campaign today!
      </Typography>
      <StyledLoadingButton
        className="mt-2"
        onClick={() => {window.open('https://www.yougrowpromo.com/?utm_source=yougrowplus&utm_medium=order_dashboard&utm_campaign=order_dashboard_want_to_start_a_new_campaign', '_blank')}}
      >
        Start promotion
      </StyledLoadingButton>
    </Box>
  )
}

export default CTABox;