import { Skeleton } from "@mui/material";

export default function useGetSkeletons() {
    const getSkeletons = (amount, width, height) => {
        const skeletonList = [];

        for (let i = 0; i < amount; i+=1) {
            skeletonList.push(
                <Skeleton variant="rounded" width={width} height={height} />
            );
        }

        return skeletonList;
    }

    return [getSkeletons];
}