import ListingLayout from '../../../layouts/dashboard/ListingLayout';
import CouponForm from '../../../forms/CouponFrom';
import UpdateCouponForm from '../../../forms/update/UpdateCouponFrom';
// ----------------------------------------------------------------------


export default function Coupons() {
  const updateFormWithProps = (couponId, data, apiDone, setApiDone, handleEditClose) => {
    return <UpdateCouponForm couponId={couponId} data={data} apiDone={apiDone} setApiDone={setApiDone} editClose={handleEditClose} />;
  }

  return (
    <>
      <ListingLayout 
      name="Coupons" 
      TABLE_HEAD={[
        { id: 'code', label: 'Code', alignRight: false },
        { id: 'createdAt', label: 'Created at', alignRight: false },
        { id: '' },
      ]}
      apiPath="admin/coupons"
      pageTitle="Coupon"
      objectKeys={["id", "code"]}
      form={<CouponForm/>}
      updateForm={updateFormWithProps}
      updateColspan={3}
      />
    </>
  );
}
