import { useEffect, useState } from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { any, bool, func, number, string } from "prop-types";
import { Alert, Box, Button, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import { useFormHandler } from "../../../../hooks/useFormHandler";
import usePost from "../../../../api/usePost";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import useResponsive from "../../../../hooks/useResponsive";
import Iconify from "../../../../components/iconify";
import { useAuth } from "../../../../auth/Authentication";

Form.propTypes = {
  direction: string.isRequired,
  spacing: number.isRequired,
  onSetIsLoading: func.isRequired,
  onSetErrors: func.isRequired,
  onErrors: any
}

function Form({direction, spacing, onSetIsLoading, onSetErrors, onErrors}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const post = usePost();
  const [errors, setErrors] = useState();
  const log = useConsoleLog;
  const fHandler = useFormHandler();
  const auth = useAuth();
  const [searchParams, ] = useSearchParams();
  const isOnSmallScreen = useResponsive("down", "md", "lg");

  const generateImage = (e) => {
    e.preventDefault();
    log(onSetIsLoading);
    onSetIsLoading(true);
    log(fHandler.formValues);
    window.scrollTo(0, 0);

    post("artist/artwork/generate", fHandler.formValues)
    .then((res) => {
      log(res);
      auth.updateUser(res.data.user);
      navigate(`/artist/artwork/generated/${res.data.artwork.id}`);
    }).catch((err) => {
      log(err);
      if (err.response?.status === 500) {
        if (err.response?.data?.response?.error?.message) {
          log(err.response?.data?.response?.error?.message);
          onSetErrors({messageIse: err.response?.data?.response?.error?.message});
        } else {
          onSetErrors({messageIse: 'Something went wrong on our side!'});
        }
      } else {
        onSetErrors(err.response?.data.errors);
      }
    }).finally(() => {
      onSetIsLoading(false);
    })
  }

  useEffect(() => {
    fHandler.setFormValues({
      prompt: "",
      n: 1
    });
  }, []);

  if (auth.user?.artwork_credits || auth.user?.artwork_credits_on_the_house) {
    return (
      <>
          <form onSubmit={generateImage}>
            <Stack direction={{xs: "column", md: "row"}} spacing={2.5}>
              <Box>
                <Box className="qty-btns">
                  <Typography className="n-text">
                    Number of images
                  </Typography>
                  <Stack direction="row" alignItems={"center"} className="generate-wrapper number-buttons" spacing={1.25}>
                    <Button 
                      variant="contained"
                      className={fHandler.formValues?.n === 1 ? 'active' : 'paper-bg'} 
                      onClick={() => fHandler.setFormValues({...fHandler.formValues, n: 1})}>
                      1
                    </Button>
                    <Button 
                      variant="contained"
                      className={fHandler.formValues?.n === 2 ? 'active' : 'paper-bg'} 
                      onClick={() => fHandler.setFormValues({...fHandler.formValues, n: 2})}>
                      2
                    </Button>
                    <Button 
                      variant="contained"
                      className={fHandler.formValues?.n === 3 ? 'active' : 'paper-bg'} 
                      onClick={() => fHandler.setFormValues({...fHandler.formValues, n: 3})}>
                      3
                    </Button>
                  </Stack>
                </Box>
              </Box>
              <Box className="generator-column">
                <Typography className="n-text">
                  Image description
                </Typography>
                <Stack direction={direction} alignItems={"center"} className="generate-wrapper gen-wrapper-field" spacing={spacing}>
                  <TextField
                    className="generator"
                    placeholder={`"A 3d render of an 80's rock band containing a monkey that plays guitar"`}
                    sx={{
                      backgroundColor: theme.palette.common.white,
                    }}
                    name="prompt"
                    value={fHandler.formValues?.prompt}
                    onChange={fHandler.handleValChange}
                    error={onErrors?.messageIse} 
                    helperText={onErrors?.messageIse}
                    multiline={isOnSmallScreen}
                  />
                  <StyledLoadingButton className="generate-btn" type="submit">Generate</StyledLoadingButton>
                </Stack>
                {searchParams.message &&
                  <Alert severity="success">
                    Successfully generated "{searchParams.message}"!
                  </Alert>
                }
              </Box>
            </Stack>
          </form>
      </>
    )
  }

  return (
    <Typography 
      variant="h3"
      textAlign={"center"}
    >
      You have no credits left, please <Link to={'/artist/artwork/add-credits'}>top up</Link> to generate more images
    </Typography>
  )
}

Generator.propTypes = {
  onSetIsLoading: func.isRequired,
  onSetErrors: func.isRequired,
  onErrors: any
}

export default function Generator({onSetIsLoading, onSetErrors, onErrors}) {
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const navigate = useNavigate();

  return (
    <Stack className="responsive-generator">
      {isOnSmallScreen ? 
        <>
          <Form direction="column" spacing={3.55} 
            onSetIsLoading={onSetIsLoading}
            onSetErrors={onSetErrors}
            onErrors={onErrors}
          />
        </>
      :
        <Form direction="row" spacing={0}
        onErrors={onErrors}
        onSetErrors={onSetErrors}
        onSetIsLoading={onSetIsLoading}/>
      }
      
    </Stack>
  )
}