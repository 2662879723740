import { Box, Paper, Grid, Stack, useTheme } from "@mui/material";
import { useAuth } from "../../auth/Authentication";
import StyledTypography from '../../theme/artist/overrides/StyledTypography';
import StyledBox from '../../theme/artist/overrides/StyledBox';

export default function Footer() {
    const theme = useTheme();
    const auth = useAuth();
    const currentYear = new Date().getFullYear();

    if (!auth.isApp) {
        return (
            <Paper sx={{paddingTop: "75px", marginTop: "185px", backgroundColor: theme.palette.darkGreen, paddingBottom: "108px", bottom: "0", width: "100%", borderRadius: 0}}>
                <StyledBox>
                    <Grid container gap={1}>
                        <Grid item md={6} xs={12}>
                            <Box className={'footer-contact'} sx={{maxWidth: "229px", margin: "auto"}}>
                                <StyledTypography color="white">Questions? Contact us via:</StyledTypography>
                                <a href="mailto:contact@yougrowpromo.com" rel="noreferrer"><StyledTypography>Contact@yougrowpromo.com</StyledTypography></a>
                                <Box sx={{marginTop: "20px"}}>
                                    <StyledTypography color="white">Copyright © {currentYear} YouGrow B.V.</StyledTypography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <a rel="noreferrer" href="https://www.yougrowpromo.com/cookies" target="_blank"><StyledTypography>Cookie policy</StyledTypography></a>
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <a rel="noreferrer" href="https://www.yougrowpromo.com/privacy" target="_blank"><StyledTypography>Privacy policy</StyledTypography></a>
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <a rel="noreferrer" href="https://www.yougrowpromo.com/refund-policy" target="_blank"><StyledTypography>Refund policy</StyledTypography></a>
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <a rel="noreferrer" href="https://www.yougrowpromo.com/terms" target="_blank"><StyledTypography>Terms of Service</StyledTypography></a>
                        </Grid>
                    </Grid>
                </StyledBox>
            </Paper>
        )
    }
    return (<></>);
}