import { Stack, Grid } from "@mui/material";
import useGetSkeletons from "../../../hooks/useGetSkeletons"

export default function LoadingLayout({skeletons}) {
  const [getSkeletons] = useGetSkeletons();

  return (
    <Grid item xs={12}>
      <Stack spacing={3}>
        {getSkeletons(skeletons, "100%", "50px")} 
      </Stack>
    </Grid>
  )
}