import { Link, useNavigate } from "react-router-dom";
import { Stack, Card, Typography, useTheme } from "@mui/material";
import { useAuth } from "../../../../auth/Authentication";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import useResponsive from "../../../../hooks/useResponsive";

export default function Amount() {
  const auth = useAuth();
  const theme = useTheme();
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const navigate = useNavigate();

  const goToAddCredits = () => {
    if (isOnSmallScreen && !auth.isApp) {
      navigate('/artist/artwork/add-credits');
    }
  }

  return (
    <Stack spacing={{xs: 2}} direction="column" justifyContent="space-between" className="credit-stack">
      <Stack columnGap={2} direction={{xs: "column", md:"row"}} alignItems="center" className="ai-credits" onClick={goToAddCredits}>
        <Card className="credits-left-card">
          <Typography className="credits-left">{auth.user?.artwork_credits_on_the_house || 0}</Typography>
        </Card>
        {!isOnSmallScreen &&
          <Typography>On the house</Typography>
        }
      </Stack>
      {!auth.isApp &&
        <Stack direction={{xs: "column", md:"row"}} alignItems="center" className="ai-credits" onClick={goToAddCredits}>
          <Card className="credits-left-card">
            <Typography className="credits-left">{auth.user?.artwork_credits || 0}</Typography>
          </Card>
          {!isOnSmallScreen &&
            <Link to={'/artist/artwork/add-credits'} style={{color: theme.palette.common.black}}>Add more</Link>
          }
        </Stack>
      }
    </Stack>
  )
}