import React, { forwardRef } from 'react';
import { string } from 'prop-types';
import { Box, Typography } from "@mui/material";
import Iconify from "../../../../components/iconify";

const ManagerUpdate = forwardRef(({ update }, ref) => {
  return (
    <Box className="cm-update-wrapper">
      <Typography className="cm-update-title showmore-title">
        Update from your campaign manager <span className="badge noti-bell"><Iconify className="text-white noti p-0" width={"15px"} icon="mdi:bell"/></span>
      </Typography>
      <Box className="cm-update-box white-box mt-1 bg-neutral" ref={ref}>
        <Typography>
          {update || "No update yet"}
        </Typography>
      </Box>
    </Box>
  );
});

ManagerUpdate.propTypes = {
  update: string,
};

export default ManagerUpdate;
