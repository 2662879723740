import { useState } from "react"

export function useSnackHandler() {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const showSuccessSnack = () => {
        setSuccess(true);
    }

    const showErrorSnack = () => {
        setError(true);
    }

    const handleSClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSuccess(false);
    };

    const handleEClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setError(false);
    };

    return {success, error, showSuccessSnack, showErrorSnack, handleSClose, handleEClose}
}