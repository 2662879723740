import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Divider, Typography, useTheme, IconButton } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import platforms from '../../../hooks/platforms';
import Iconify from '../../../components/iconify/Iconify';
import DataCardContent from './DataCardContent';
import useConsoleLog from '../../../hooks/useConsoleLog';

DataCard.propTypes = {
  source: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  avatar: PropTypes.string,
  trackInfo: PropTypes.object,
  url: PropTypes.string
};

export default function DataCard({ source, type, text, avatar, trackInfo, url }) {
  const log = useConsoleLog;
  const platform = platforms.find((platform) => source === platform.id);
  const theme = useTheme();
  const platformColor = theme.palette.platform[platform.id];
  const isOnSmallScreen = useResponsive("down", "md", "lg");
  const artists = trackInfo?.artists?.map((artist) => `${artist.name} `);
  const search = platform?.searchUrl && platform?.searchUrl + artists + trackInfo?.title;
  log(platform?.searchUrl);
  return (
    <>
      <Card sx={{ textAlign: 'left', py: {xs: "10px", md: "20px"} }}>
        <CardContent sx={{py: {xs: "10px", md: "20px"}, px: {xs: "10px", md: "20px"}}}>
          <Grid container spacing={3} alignItems="center">
            {!isOnSmallScreen &&
            <Grid md={1.5} item sx={{textAlign: "center"}}>
              <Iconify sx={{ color: platformColor }} width="50.6px" icon={platform?.icon} />
              <Typography sx={{ color: platformColor }}>{platform?.name}</Typography>
            </Grid>}
            <Grid md={4.5} item>
              <DataCardContent avatar={avatar} title={type} text={text} platform={platform} platformColor={platformColor} songUrl={url} />
            </Grid>
            <Grid item md={0.5} xs={12}>
              <Divider sx={{
                width: {md: "1px", xs: "100%"},
                height: {md: "100px", xs: "1px"},
                mt: "-10px",
                backgroundColor: theme.palette.grey[1000]
              }}/>
            </Grid>
            <Grid md={4.5} item>
                <DataCardContent avatar={trackInfo?.avatar} title={trackInfo?.title} songUrl={search} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
