import {useEffect, useState} from "react";
import axios from "axios";
import {Grid, Stack, InputAdornment, Typography, Paper, IconButton, Alert, Button} from "@mui/material";
import PropTypes from "prop-types";
import {useTheme} from "@emotion/react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StyledTypography from "../../../../theme/artist/overrides/StyledTypography";
import StyledTextField from "../../../../theme/artist/overrides/StyledTextField";
import Iconify from "../../../../components/iconify/Iconify";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import {useFormHandler} from "../../../../hooks/useFormHandler";
import {API_URL} from "../../../../hooks/globals";
import useResponsive from "../../../../hooks/useResponsive";
import useConsoleLog from "../../../../hooks/useConsoleLog";
import ChangePassword from "./ChangePassword";


PersonalDetails.propTypes = {
    auth: PropTypes.object
}

export default function PersonalDetails({auth}) {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [errors, setErrors] = useState();
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const [successMsg, setSuccessMsg] = useState();
    const fHandler = useFormHandler("artist/update-info");
    const log = useConsoleLog;

    useEffect(() => {
        fHandler.setFormValues({
            first_name: auth.user.first_name,
            last_name: auth.user.last_name,
            email: auth.user.email,
            phone_number: auth.user.phone_number,
            spotify_id: auth.user.spotify_id,
            _method: "PUT"
        });
    }, [])


    const updateCurrentArtist = () => {
        const url = `${API_URL}artist/update-info`;

        log(fHandler.formValues);

        setIsSubmitting(true);
        setSuccessMsg('');
        setErrors({});

        const config = {
            method: 'post',
            url,
            data: fHandler.formValues,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`,
            },
            credentials: 'include'
        };

        axios(config).then((response) => {
            log(response);
            auth.updateUser(response.data);
            setSuccessMsg("Successfully updated!");
        })
            .catch((error) => {
                log(error);
                auth.handleUnauthorized(error);

                if (error.response.status === 500) {
                    setErrors({messageIse: 'Something went wrong on our side!'})
                } else {
                    setErrors(error.response.data.errors);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <Stack gap={{md: 5, xs: 2}}>
            <Typography variant="h4">Personal details</Typography>
            <Paper sx={{
                backgroundColor: theme.palette.grey[200],
                px: {md: "50px", xs: "20px"},
                paddingTop: "35px",
                paddingBottom: "60px"
            }}>
                {(!changePassword) ?
                    (<Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <StyledTypography sx={{color: theme.palette.common.black}}>First name</StyledTypography>
                            <StyledTextField name="first_name" fullWidth hiddenLabel
                                             value={fHandler.formValues?.first_name}
                                             onChange={fHandler.handleValChange} error={errors?.first_name}
                                             helperText={errors?.first_name}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <StyledTypography sx={{color: theme.palette.common.black}}>Last name</StyledTypography>
                            <StyledTextField name="last_name" fullWidth hiddenLabel
                                             value={fHandler.formValues?.last_name}
                                             onChange={fHandler.handleValChange} error={errors?.last_name}
                                             helperText={errors?.last_name}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <StyledTypography sx={{color: theme.palette.common.black}}>Email address</StyledTypography>
                            <StyledTextField name="email" fullWidth hiddenLabel value={fHandler.formValues?.email}
                                             onChange={fHandler.handleValChange} error={errors?.email}
                                             helperText={errors?.email}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <StyledTypography sx={{color: theme.palette.common.black}}>Phone number</StyledTypography>
                            <StyledTextField name="phone_number" fullWidth hiddenLabel
                                             value={fHandler.formValues?.phone_number}
                                             onChange={fHandler.handleValChange} error={errors?.phone_number}
                                             helperText={errors?.phone_number}/>
                        </Grid>
                        {auth.user?.subscription?.plan?.permissions &&
                            <Grid item xs={12}>
                                <StyledTypography sx={{color: theme.palette.common.black}}>Spotify artist
                                    id</StyledTypography>
                                <StyledTextField className="spotify-id-filled" disabled fullWidth hiddenLabel
                                                 value={auth.user.spotify_id} onChange={fHandler.handleValChange}
                                                 error={errors?.spotify_id}
                                                 helperText={<span style={{
                                                     display: "flex",
                                                     marginLeft: 0,
                                                     justifyContent: "left",
                                                     color: "gray"
                                                 }}>
                                                 <InfoOutlinedIcon
                                                     sx={{marginLeft: "0px", marginRight: '5px', fontSize: "1rem"}}/>
                                                 you can <a style={{paddingLeft: '5px', paddingRight: '5px'}}
                                                            href={'mailto:mark@yougrowpromo.com'}> contact </a>  support to adjust this.</span>}/>
                            </Grid>
                        }
                        <Grid item md={6} xs={12} sx={{ textAlign: 'left' }}>
                            <StyledTypography sx={{color: theme.palette.common.black}}>Password</StyledTypography>
                            <a href="#"  style={{color: "#1dd263", cursor: "pointer"}} onClick={()=> setChangePassword(()=>true)}>Click to Change
                                Password</a>
                        </Grid>
                        <Grid item md={12}>
                            <Stack gap={3}>
                                <StyledLoadingButton loading={isSubmitting}
                                                     sx={{display: "block", lineHeight: 1.5, ml: "auto"}}
                                                     onClick={() => updateCurrentArtist()}>Save
                                    changes</StyledLoadingButton>
                                {errors?.messageIse && <Alert severity="error">{errors?.messageIse}</Alert>}
                                {successMsg && <Alert severity="success">{successMsg}</Alert>}
                            </Stack>
                        </Grid>
                    </Grid>) : <ChangePassword  setChangePassword={setChangePassword}/> }
            </Paper>
        </Stack>
    )
}