import { memo, useMemo } from "react";
import { PropTypes } from "prop-types";
import { Tooltip, IconButton, Avatar } from "@mui/material";
import { useAuth } from "../../../auth/Authentication";

CustomAvatar.propTypes = {
    openUserMenu: PropTypes.func,
}

export default function CustomAvatar({openUserMenu}) {
    const auth = useAuth();

    function stringToColor(string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
    
    function stringAvatar(name) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: name?.[0],
      };
    }
    const CurrentAvatar = memo(() => {
      function avatarSrc() {
        if (auth.user.avatar_url) {
          return {
            src: auth.user.avatar_url
          }
        }
  
        return {};
      }
  
      return <Avatar sx={{color: "common.white"}} {...stringAvatar(auth.user.first_name || auth.user.email)} {...avatarSrc()} />
    })



    return (
      <Tooltip title="Open settings">
        <IconButton onClick={openUserMenu} sx={{ p: 0, display: "block", margin: "auto" }}>
          <CurrentAvatar/>
        </IconButton>
      </Tooltip>
    )
}