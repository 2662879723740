import { useTheme } from "@emotion/react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from "next-share";
import { bool, func, string } from "prop-types";
import { useState } from "react";
import { useColorMode } from "../../../theme/artist/ColorModeContext";

ShareModal.propTypes = {
    onOpen: bool,
    onSetOpen: func,
    shareMessage: string,
    url: string,
    hashTag: string
}

export default function ShareModal({onOpen, onSetOpen, shareMessage, url, hashTag}) {
    const theme = useTheme();
    const [encodedUrl, ] = useState(encodeURI(url));
    const colorMode = useColorMode();

    return (
        <>
            <Modal
            className={colorMode.darkMode && 'dark-mode'}
            open={onOpen}
            onClose={() => onSetOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box
                className="trees-share-modal"
                sx={{backgroundColor: theme.palette.common.white}}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2" className="contrast-neutral-bg">
                    Share
                </Typography>
                <Grid container className="share-icons">
                    <Grid item md={4}>
                        <FacebookShareButton
                            url={encodedUrl}
                            hashtag={hashTag}
                            quote={shareMessage}
                        > 
                            <FacebookIcon
                                size={32}
                                round
                            />
                        </FacebookShareButton>
                    </Grid>
                    <Grid item md={4}>
                        <LinkedinShareButton
                            url={encodedUrl}
                            summary={shareMessage}
                        > 
                            <LinkedinIcon
                                size={32}
                                round
                            />
                        </LinkedinShareButton>
                    </Grid>
                    <Grid item md={4}>
                        <TwitterShareButton
                            url={encodedUrl}
                            title={shareMessage}
                        >
                            <TwitterIcon
                                size={32}
                                round
                            />
                        </TwitterShareButton>
                    </Grid>
                </Grid>
            </Box>
            </Modal>
        </>
    )
}