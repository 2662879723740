import UpdateAdminForm from '../../../forms/update/UpdateCouponFrom';
import AdminForm from '../../../forms/AdminFrom';
import ListingLayout from '../../../layouts/dashboard/ListingLayout';
// ----------------------------------------------------------------------


export default function Admins() {
  const updateFormWithProps = (adminId, data, apiDone, setApiDone, handleEditClose) => {
    return <UpdateAdminForm adminId={adminId} data={data} apiDone={apiDone} setApiDone={setApiDone} editClose={handleEditClose} />;
  }

  return (
    <>
      <ListingLayout 
      name="Admin users" 
      TABLE_HEAD={[
        { id: 'email', label: 'E-mail', alignRight: false },
        { id: 'role', label: 'Role', alignRight: false },
        { id: 'createdAt', label: 'Created at', alignRight: false },
        { id: '' },
      ]}
      apiPath="admin/admin-users"
      pageTitle="Admin user"
      objectKeys={["id", "email"]}
      form={<AdminForm/>}
      updateForm={updateFormWithProps}
      updateColspan={5}
      />
    </>
  );
}
