import { Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../../auth/Authentication";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import "../../../scss/socials.scss";

export default function SocialMedia() {
  const auth = useAuth();

  useEffect(() => {
    auth.checkSubscription();
  },[])


  return (
    <>
      <Helmet>
        <title>Choose platform {TITLE_SEPERATOR} {APP_NAME}</title>
      </Helmet>
                  
      <Typography variant="h1" textAlign="center" className="choose-text">
        Choose a platform
      </Typography>
    </>
  )
}