import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {Alert, Box } from "@mui/material";
import Generator from "./generator";
import Examples from "./examples";
import Explainations from "./explainations";
import Heading from "./heading";
import "../../../scss/artwork.scss";
import { APP_NAME, TITLE_SEPERATOR } from "../../../hooks/globals";
import useResponsive from "../../../hooks/useResponsive";
import LoadingScreen from "./loading-screen";
import Layout from "./Layout";
import Tips from "./Tips";
import { useAuth } from "../../../auth/Authentication";

export default function AIArtworkMain() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();

  const auth = useAuth();

  useEffect(() => {
    auth.checkSubscription();
  },[])

  if (!isLoading) {
    return (
      <Layout>
        <Helmet>
          <title>Album cover gallery {TITLE_SEPERATOR} {APP_NAME}</title>
        </Helmet>
        <Box>
        <Heading
        title="Album cover gallery"
        description="Chances are that you are not a professional visual artist. Because of this reason, we give you the opportunity to make use of our ai artwork generator. Type in the perfect prompt and generate an album cover that best fits you."
        showHist={false}
        />
        <Generator
          onSetIsLoading={setIsLoading}
          onSetErrors={setErrors}
          onErrors={errors}
        />
        <Tips/>
        <Examples/>
        <Explainations/>
      </Box>
      </Layout>
    )
  }

  return <LoadingScreen/>
}