import { Card, useTheme, Table } from '@mui/material';
import PropTypes from 'prop-types';

UrlTableLayout.propTypes = {
  chidlren: PropTypes.element,
}

export default function UrlTableLayout({children}) {
  const theme = useTheme();

  return (
  <>
    <Card
      className="url-table-layout">
      <Table>
        {children}
      </Table>
    </Card>
  </>
  )
}