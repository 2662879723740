import {useEffect, useState} from 'react';
import axios from 'axios';
import { Sidetab } from '@typeform/embed-react';
import {useTheme} from '@emotion/react';
import {Stack} from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {IconButton} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { Link, useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import navConfig from './config';
import navConfigLoggedOut from './configLoggedOut';
import ResponsiveNav from './ResponsiveNav';
import useResponsive from "../../../hooks/useResponsive";
import DesktopMenu from './DesktopMenu';
import CustomAvatar from './CustomAvatar';
import {useAuth} from '../../../auth/Authentication';
import StyledLoadingButton from '../../../theme/artist/overrides/StyledLoadingButton';
import StyledBox from '../../../theme/artist/overrides/StyledBox';
import {useColorMode} from '../../../theme/artist/ColorModeContext';
import Iconify from '../../../components/iconify/Iconify';
import {API_URL} from '../../../hooks/globals';
import useConsoleLog from '../../../hooks/useConsoleLog';
import UnpaidAlert from "./UnpaidAlert";

const settings = [{title: 'Account', link: "/my-account"}, {title: 'Logout', link: "/logout"}];


const StyledAppBar = styled(AppBar)(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: "none",
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0
}));

function ResponsiveAppBar() {
    const [pages, setPages] = useState([]);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [resent, setResent] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const auth = useAuth();
    const theme = useTheme();
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const colorMode = useColorMode();
    const log = useConsoleLog;
    const location = useLocation();
    const path = location.pathname;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const resendVerification = () => {
        setIsResending(true);

        const config = {
            method: "get",
            url: `${API_URL}email/resend`,
            headers: {
                "Authorization": `Bearer ${auth.userToken}`
            }
        }

        axios(config).then(() => {
            setResent(true);
            setIsResending(false);
        }).catch((err) => {
            log(err);
        });
    };

    useEffect(() => {
        if (auth.user) {
            setPages(navConfig)
        } else {
            setPages(navConfigLoggedOut)
        }
    }, [auth.user]);

  

  if (!auth.isApp) {
      return (
          <>
              <Box sx={{height: "90.5px"}}>
                  <StyledAppBar position="fixed" className='nav-bar'>
                      <StyledBox>
                          <Toolbar disableGutters>
                              <Link to="/artist/statistics" className={'app-logo'}>
                                  {colorMode.darkMode ?
                                      <img style={{width: "111px"}} src="/assets/logo-white.png" alt="logo white"/>
                                      :
                                      <img src="/assets/logo.png" alt="logo" style={{width: "140px"}}/>
                                  }
                              </Link>
                              {/* Desktop menu */}
                              {auth?.user?.token && !isOnSmallScreen &&
                                <DesktopMenu pages={pages} closeNavMenu={handleCloseNavMenu}/>}
                              {/* End desktop menu */}
                              {!isOnSmallScreen &&
                                  <Box sx={{flexGrow: 0, marginLeft: !auth?.user?.token && "auto"}}>
                                      <Stack direction={"flex-column"} gap={3}>
                                          <IconButton onClick={colorMode.toggleDarkMode}>{colorMode.darkMode ?
                                            <Iconify icon={'icon-park-solid:dark-mode'}/> :
                                            <Iconify icon={'ic:sharp-light-mode'}/>}</IconButton>
                                          {auth?.user?.token ? <CustomAvatar openUserMenu={handleOpenUserMenu}/> :
                                            <Link to="/login"><StyledLoadingButton sx={{
                                                height: "38px",
                                                width: "162px"
                                            }}>Login</StyledLoadingButton></Link>}
                                          {auth?.user?.token && <Menu
                                              PaperProps={{sx: {backgroundColor: theme.palette.common.white}}}
                                              sx={{mt: '45px'}}
                                              id="menu-appbar"
                                              anchorEl={anchorElUser}
                                              anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right',
                                              }}
                                              keepMounted
                                              transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right',
                                              }}
                                              open={Boolean(anchorElUser)}
                                              onClose={handleCloseUserMenu}
                                          >
                                              <MenuItem onClick={handleCloseUserMenu}>
                                                  <Link to={"/my-account"} color={theme.palette.common.black}>
                                                      <Typography textAlign="center"
                                                                sx={{color: theme.palette.common.black}}>My
                                                        account</Typography>
                                                  </Link>
                                              </MenuItem>
                                              <MenuItem onClick={() => auth.logout("/login")}>
                                                  <Link to={"#"} color={theme.palette.common.black}>
                                                      <Typography textAlign="center"
                                                                sx={{color: theme.palette.common.black}}>Logout</Typography>
                                                  </Link>
                                              </MenuItem>
                                          </Menu>}
                                      </Stack>
                                  </Box>
                              }
                              {/* Mobile menu */}
                              <ResponsiveNav pages={pages} anchorElNav={anchorElNav} openNavMenu={handleOpenNavMenu}
                                           closeNavMenu={handleCloseNavMenu}/>
                              {/* End mobile menu */}
                          </Toolbar>
                      </StyledBox>
                      <UnpaidAlert/>

                    {auth.user && !auth.user.email_verified && auth.user.role !== 'super-admin' && auth.user.role !== 'admin' && !resent &&
                          <Box sx={{backgroundColor: theme.palette.error.main}}>
                              {isResending ? <Typography>Resending email</Typography> :
                                  <Typography textAlign={"center"}>
                                      Your email hasn't been verified yet. Please check your email or click <Typography
                                    onClick={resendVerification} sx={{
                                    display: "inline",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}>here</Typography> to resend the verification email.
                                  </Typography>
                              }
                          </Box>
                      }                
  
                </StyledAppBar>
              </Box>
        </>
      );
  }
    return (<></>);
}

export default ResponsiveAppBar;