import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication";
import useGetListing from "../api/useGetListing";
import { useModal } from "../modals/ModalContext";
import { API_URL } from "./globals";
import useConsoleLog from "./useConsoleLog";

export function useFormHandler(path) {   
    const modal = useModal()
    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState();
    const auth = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetSelect, setResetSelect] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const navigate = useNavigate();
    const log = useConsoleLog;

    const setRoles = (e) => {
        setSuperAdmin(!superAdmin);
    }

    const handleValChange = (e) => {
        const formValuesb = {...formValues};
        log(typeof e);

        if (typeof e === "string") {
            formValuesb.color_code = e;
            // use this for colors only nog
        } else if (e.target.type === 'file') {
            formValuesb[e.target.name] = e.target.files[0];
        } else {
            formValuesb[e.target.name] = e.target.value;
        }

        log(formValuesb);

        setFormValues(formValuesb);
    }

    const handleDateValChange = (value, fieldName) => {
        const formValuesb = {...formValues};

        formValuesb[fieldName] = value;

        setFormValues(formValuesb);
    }

    const handleCheckedChange = (e) => {
        const formValuesb = {...formValues};
        formValuesb[e.target.name] = e.target.checked;

        setFormValues(formValuesb);
        log(formValuesb);
    }

    const handleSubmit = (snackHandler = false, redirect = false) => {
        log(formValues);

        const url = `${API_URL}${path}`;
        
        log(formValues);

        setIsSubmitting(true);
        setResetSelect(false);

        const config = {
            method: 'post',
            url,
            data: formValues,
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            modal.handleClose()
            setFormValues({});
            setErrors({});
            setResetSelect(true);
            
            log(`${redirect}${response.data.id}`);

            modal.setNewItem(response.data);

            return redirect ? navigate(`/${redirect}${response.data.id}`) : false;
        })
        .catch((error) => {
            log(error)
            if (error.response.status === 500) {
                setErrors({messageIse: 'Something went wrong on our side!'})
            } else {
                setErrors(error.response.data.errors);
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 
    }

    const handleUpdateSubmit = () => {
        log(formValues);

        const url = `${API_URL}${path}`;
        
        log(formValues);

        setIsSubmitting(true);
        setResetSelect(false);
        setSuccessMsg('');

        const config = {
            method: 'post',
            url,
            data: {...formValues, _method: 'put'},
            headers: { 
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            setResetSelect(true);
            setSuccessMsg("Successfully updated!");
            setErrors({});
        })
        .catch((error) => {
            log(error.response);
            const status = error.response.status;
            if (status === 500 || status === 405) {
                setErrors({messageIse: 'Something went wrong on our side!'})
            } else {
                setErrors(error.response.data.errors);
            }
        })
        .finally(() => {
            setIsSubmitting(false);
        }); 
    }

    return {successMsg, handleCheckedChange, handleUpdateSubmit, handleDateValChange, handleValChange, handleSubmit, setFormValues, formValues, isSubmitting, errors, resetSelect};
}

export function useSelect(formValues, setFormValues, resetSelect = false) {
    const [errMsg, setErrMsg] = useState('');
    const [sItems, setsItems] = useState([]);
    const [itemSelect, setItemSelect] = useState([]);
    const [itemSelectorField, setItemSelectorField] = useState([]);
    const [currentMSResource, setCurrentMSResource] = useState(false);
    const selectorItems = useGetListing();
    const log = useConsoleLog;

    const getMultiSelectItems = (resource, namedObject, selectorField) => {
        setCurrentMSResource(namedObject);
        selectorItems.getListing(resource).then((response) => {
            setItemSelect(response.data);
            setItemSelectorField(selectorField);
        }).catch((error) => {
            setErrMsg(error.response.data.message);
            log(error);
        });
    }

    const handleMultiSelectChange = (event) => {
        const {
        target: { value },
        } = event;

        setsItems(typeof value === 'string' ? value.split(',') : value,);
    };

    const handleSingleSelectChange = (event) => {
        setsItems(event.target.value);
    };

    useEffect(() => {
        if (resetSelect) {
            setsItems([]);
        }
    }, [resetSelect]);

    useEffect(() => {
        setsItems([]);
    }, []);

    useEffect(() => {
        setMultiSelectedIds(itemSelect);
    }, [currentMSResource, sItems]);

    const setMultiSelectedIds = (itemSelecta) => {
        log(itemSelecta)
        const selectIds = itemSelecta.map((u) => {
            if (sItems.includes(u[itemSelectorField])) {
            return u.id;
            }

            return null;
        });

        const theIds = selectIds.filter(n=>n);

        if (currentMSResource) {
            const formValuesb = {...formValues};
            formValuesb[currentMSResource] = theIds;

            log(formValuesb);

            setFormValues(formValuesb);
        }
    }

    return {handleMultiSelectChange, handleSingleSelectChange, getMultiSelectItems, itemSelect, itemSelectorField, sItems, errMsg}
}