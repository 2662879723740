import { useNavigate } from "react-router-dom";
import { Typography, useTheme, Box, Stack } from "@mui/material";
import StyledBox from "../../../../../theme/artist/overrides/StyledBox";
import "../../../../../scss/socials.scss";
import AccountsAccordions from "./AccountsAccordions";
import StyledLoadingButton from "../../../../../theme/artist/overrides/StyledLoadingButton";

export default function RegisterSocialAccount() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledBox className="container social-media-container register-social-wrapper">
      <Box className="social-register-wrapper">
        <Typography variant="h1" sx={{color: theme.palette.common.black}}>
          Your social media
        </Typography>
        <Box className="register-social-desc">
          <Typography variant="h4" className="coupon-generator-intro-title">
            Your social media analytics
          </Typography>
          <Typography variant="body1">
            Before you can keep track of your social media accounts analytics, we need some additional information. Please share with us the usernames you use for each social media platform. Please double check if you are using the correct username!
          </Typography>
        </Box>
        <AccountsAccordions/>
        <StyledLoadingButton className="mt-2" onClick={() => navigate('/artist/social-media/home')}>
          Continue to Social media analytics
        </StyledLoadingButton>
      </Box>

    </StyledBox>
  )
  
}