import { useState } from "react";
import { bool, string } from "prop-types"
import { Stack, TextField, styled, Typography, AccordionSummary, AccordionDetails, Accordion, IconButton } from "@mui/material";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { lowerCase } from "lodash";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useAuth } from "../../../../../auth/Authentication"
import Iconify from "../../../../../components/iconify";
import AddIdForm from "../../../forms/AddIdForm";

RegisterSocialAccordion.propTypes = {
  icon: string,
  name: string,
  idName: string
}


export default function RegisterSocialAccordion({
  icon, 
  name, 
  idName,
}) {
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const isSet = Boolean(auth.user[`${idName}_id`]);
  const userName = auth.user[`${idName}_id`];

  const toggleOpen = (e) => {
    if (!isSet) {
      setOpen(!open);
    } else {
      e.preventDefault();
    }
  }


  return (
    <Stack>
      <Accordion className='social-register-accordion-wrapper' 
        disabled={isSet} onClick={toggleOpen}
        expanded={!isSet}
      >
        <AccordionSummary  className='accordion-summary'
          expandIcon={isSet ?  
            <IconButton className="add-socials-button">
              <Iconify icon="mdi:check-bold" width={24}/>
            </IconButton> 
          :
            <Iconify height={25} width={40} icon="pepicons-pop:angle-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <img
        className="social-icon-register"
        src={`/assets/icons/socials/${icon}.svg`} alt={name} height="24px" />
        <Typography variant="h5" className="register-socials-name">
          {name}
        </Typography>
        <Typography className={`no-acc-text ${isSet && "text-center m-auto text-underline"}`}>
          {userName || "No account connected yet"}
        </Typography>
          
        </AccordionSummary>
        <AccordionDetails className='accordion-details'>
            <Stack spacing={2}>
              <AddIdForm
              idType={`add-social-id/${lowerCase(name)}`}
              platform={name} 
              platformNameId={idName} 
              helper={`Name of your ${name} page.`}/>
            </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}