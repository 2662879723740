import { Box, Stack, Typography } from "@mui/material";

export default function Intro() {
  return (
    <Box className="tree-intro">
      <Stack gap={2}>
        <Typography className="intro-title">
          Plant a tree
        </Typography>
        <Typography className="intro-text">
          For every order that you place, we will plant one tree. This way we can all work towards a better future, while doing something we all love. To keep track of your personal contribution to this goal, you can see the trees that you have personally helped plant in this overview.
        </Typography>
      </Stack>
    </Box>
  )
}