import { Outlet } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import Footer from "./Footer";
import ResponsiveAppBar from "../artist-dashboard/nav/ResponsiveAppBar";
import ArtistThemeProvider from "../../theme/artist";
import { useColorMode } from "../../theme/artist/ColorModeContext";

export default function LoggedOutLayout() {
    const theme = useTheme();
    const colorMode = useColorMode();

    return (
        <ArtistThemeProvider>
            <Box sx={{
                backgroundColor: colorMode.darkMode ? "#242424" : "#f7f7f7"}}>
                <ResponsiveAppBar/>
                    <Outlet/>
                <Footer/>
            </Box>
        </ArtistThemeProvider>
    )
}