import {useEffect, useState} from "react";
import {useTheme} from "@emotion/react";
import {Alert, Button, Grid, IconButton, InputAdornment, Stack} from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios from "axios";
import useResponsive from "../../../../hooks/useResponsive";
import {useAuth} from "../../../../auth/Authentication";
import {useFormHandler} from "../../../../hooks/useFormHandler";
import Iconify from "../../../../components/iconify/index";
import StyledTypography from "../../../../theme/artist/overrides/StyledTypography";
import StyledTextField from "../../../../theme/artist/overrides/StyledTextField";
import StyledLoadingButton from "../../../../theme/artist/overrides/StyledLoadingButton";
import {API_URL} from "../../../../hooks/globals";

const ChangePassword = ({setChangePassword}) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const fHandler = useFormHandler("artist/update-info");
    const [errors, setErrors] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const [success, setSuccess] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        fHandler.setFormValues({
            currentPassword: '',
            newPassword: '',
            _method: "POST"
        });
    }, [])
    const handleSubmit = () => {
        const url = `${API_URL}password/change`;
        console.log(fHandler.formValues);
        const config = {
            method: 'POST',
            url,
            data: fHandler.formValues,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${auth.userToken}`,
            },
            credentials: 'include'
        };
        setIsSubmitting(true);
        setSuccess(false);
        setErrors(false);
        axios(config).then((response) => {
            if (response.data.success)
                setSuccess(true);
            else
                setErrors(true)
            console.log(response)
        })
        .catch(() => {
            setErrors(true)
        })
        .finally(()=>setIsSubmitting(false))
    }

    return (
        <Grid container md={12} xs={12} spacing={3}>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
                <p>
                    <a href="#" style={{color: "#1dd263", cursor: "pointer",}}
                            onClick={() => setChangePassword(() => false)}>
                        Cancel
                    </a>
                </p>
            </Grid>
            <Grid item md={12} xs={12}>
                <StyledTypography sx={{color: theme.palette.common.black}}>Current Password</StyledTypography>
                <StyledTextField
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} name="currentPassword" fullWidth hiddenLabel value={fHandler.formValues?.password}
                    onChange={fHandler.handleValChange} error={errors?.password} helperText={errors?.password}/>
            </Grid>

            <Grid item md={12} xs={12}>
                <StyledTypography sx={{color: theme.palette.common.black}}>New Password</StyledTypography>
                <StyledTextField
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} name="newPassword" fullWidth hiddenLabel value={fHandler.formValues?.password}
                    onChange={fHandler.handleValChange} error={errors?.password} helperText={errors?.password}/>
            </Grid>
            <Grid item md={12}>
                <StyledLoadingButton loading={isSubmitting}
                                        sx={{display: "block", lineHeight: 1.5, ml: "auto"}}
                                        onClick={() => handleSubmit()}>Save
                    changes</StyledLoadingButton>
            </Grid>
            <Grid item md={12}>
                {errors && <Alert severity="error">Current password mismatch.</Alert>}
                {success && <Alert severity="success">Successfully updated password.</Alert>}
            </Grid>
        </Grid>
    )
}
export default ChangePassword