import { useState, React, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/iconify';
import { useAuth } from '../Authentication';
import useConsoleLog from '../../hooks/useConsoleLog';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const log = useConsoleLog;
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState();

  const auth = useAuth();

  const handleLogin = () => {
    auth.login(formValues);
    log(auth.user?.authed);
  };

  const handleValChange = (e) => {
    const formValuesb = {...formValues};
    formValuesb[e.target.name] = e.target.value;

    setFormValues(formValuesb);
  }

  useEffect(() => {    
    const cookies = new Cookies();

    const user = cookies.get('user');
    auth.setErrors({});

    if (user?.authed) {
      navigate('/admin/dashboard', true);
      window.location.reload(true);
    }
  }, []);


  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email" value={formValues?.email} onChange={handleValChange} error={auth.errors?.email} helperText={auth.errors?.email} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleValChange}
          error={auth.errors?.password} 
          helperText={auth.errors?.password}  />
          
          {auth.errors?.general?.length ? <Alert severity='error'>{auth.errors.general}</Alert> : ''}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
        <Link to={'/forgot-password'} onClick={() => auth.setErrors({})}>
          Forgot password?
        </Link>
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} loading={auth.loading}>
        Login
      </LoadingButton>
    </>
  );
}
