// routes
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { AuthProvider } from './auth/Authentication';
import Router from './routes';
import { ColorModeProvider } from './theme/artist/ColorModeContext';
import './scss/general.scss';
import { deviceChecker } from './helpers/checkDeviceHelpers';
// theme

// ----------------------------------------------------------------------
const HyrosScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://frippie.yougrowpromo.com/v1/lst/universal-script?ph=b8e672dd6278a1ca454c15eb8660b7466881cf0fe5ce3b019b7deb460e350efc&tag=!clicked&ref_url=${encodeURI(
      document.URL
    )}`;
    document.head.appendChild(script);

    // Cleanup function
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default function App() {
  const [showAndroid, setShowAndroid] = useState(true);
  const [showIOS, setShowIOS] = useState(true);
  const [searchParams] = useSearchParams();
  const [isApp] = useState(searchParams.has('isApp'));

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: 'G-L2LHXY9TB7'
      },
      {
        trackingId: 'AW-10937809005'
      },
    ]);
  }, []);

  const redirectToPlayStore = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.yougrow.plus';
  };

  const redirectToAppStore = () => {
    window.location.href = 'https://apps.apple.com/nl/app/yougrow/id6476023192';
  };

  const handleCloseAndroid = (e) => {
    e.stopPropagation();
    setShowAndroid(false);
  };

  const handleCloseIOS = (e) => {
    e.stopPropagation();
    setShowIOS(false);
  };

  return (
    <>
      <HyrosScript />
      <AuthProvider>
        <ColorModeProvider>
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link
              href="https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Router />

          {!isApp && deviceChecker.isAndroid() && showAndroid && (
            <Box sx={{ position: 'fixed', left: '0px', right: '0px', bottom: '0px', color: 'black', zIndex: 1000 }}>
              <Box sx={{ position: 'relative' }}>
                <CloseIcon
                  onClick={handleCloseAndroid}
                  sx={{ color: 'black', position: 'absolute', right: '13px', top: '13px', cursor: 'pointer' }}
                />
                <Box
                  onClick={redirectToPlayStore}
                  sx={{
                    color: 'black',
                    bgcolor: '#1dd263',
                    padding: '12px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  Open in App{' '}
                </Box>
              </Box>
            </Box>
          )}
          {!isApp && deviceChecker.isIOS() && showIOS && (
            <Box sx={{ position: 'fixed', left: '0px', right: '0px', bottom: '0px', color: 'black', zIndex: 1000 }}>
              <Box sx={{ position: 'relative' }}>
                <CloseIcon
                  onClick={handleCloseIOS}
                  sx={{ color: 'black', position: 'absolute', right: '13px', top: '13px', cursor: 'pointer' }}
                />
                <Box
                  onClick={redirectToAppStore}
                  sx={{
                    color: 'black',
                    bgcolor: '#1dd263',
                    padding: '12px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  Open in App{' '}
                </Box>
              </Box>
            </Box>
          )}

        </ColorModeProvider>
      </AuthProvider>
    </>
  );
}
