import { useState, React, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactFbq from 'react-fbq';
// @mui
import {
  useTheme,
  Select,
  MenuItem,
  Radio,
  FormControl,
  RadioGroup,
  Typography,
  Card,
  Grid,
  Box,
  Stack,
  TextField,
  Checkbox,
  Alert,
  CardContent,
  Divider,
  FormHelperText, Link,
} from '@mui/material'
// components
import SubscriptionNotice from '../../modals/SubscriptionNotice';
import StyledTypography from '../../theme/artist/overrides/StyledTypography';
import StyledBox from '../../theme/artist/overrides/StyledBox';
import StyledTextField from '../../theme/artist/overrides/StyledTextField';
import StyledLoadingButton from '../../theme/artist/overrides/StyledLoadingButton';
import Iconify from '../../components/iconify';
import { useAuth } from '../Authentication';
import useResponsive from '../../hooks/useResponsive';
import { API_URL, APP_NAME, TITLE_SEPERATOR } from '../../hooks/globals';
import PricingPlans from './PricingPlans';
import StyledSelect from '../../theme/artist/overrides/StyledSelect';
import { useColorMode } from '../../theme/artist/ColorModeContext';
import useConsoleLog from '../../hooks/useConsoleLog';
import usePost from '../../api/usePost';
import useGetListing from '../../api/useGetListing';
import CheckoutModal from './CheckoutModal';
import useGetSkeletons from '../../hooks/useGetSkeletons';
import {ArtistLoginForm} from '../../pages/artist/auth'

export default function CheckoutForm() {
  const params = useParams();
  const log = useConsoleLog;
  const navigate = useNavigate();
  const [getSkeletons] = useGetSkeletons();
  const [formValues, setFormValues] = useState({
    payment_method: 'Stripe',
    plan: '',
  });
  const colorMode = useColorMode();
  const [plans, setPlans] = useState();
  const [allFeatures, setAllFeatures] = useState();
  const [selectOpen, setSelectOpen] = useState(false);
  const theme = useTheme();
  const auth = useAuth();
  const isOnSmallScreen = useResponsive('down', 'md', 'lg');
  const dataProtectionText = (
    <>
      Your personal data will be used to process your order, support your experience throughout this website, and for
      other purposes described in our{' '}
      <a
        style={{ color: theme.palette.platform.spotify }}
        rel="noreferrer"
        href="https://www.yougrowpromo.com/privacy?utm_source=yougrowplus"
        target="_blank"
      >
        privacy policy
      </a>
      .
    </>
  );
  const post = usePost();
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isPlansLoading, setIsPlansLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  const handleRegister = () => {
    log('register');
    setIsLoading(true);
    setErrors([]);

    const config = {
      method: 'post',
      url: `${API_URL}register-with-subscription`,
      data: formValues,
    };

    axios(config)
      .then((res) => {
        const userb = { ...res.data.data, authed: true, token: res.data.token };

        auth.updateUser(userb);
        setCheckoutUrl(res.data.payment_link.url);
        setCheckoutModalOpen(true);
        log(res);
      })
      .catch((error) => {
        log(error);
        setErrors(error.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckout = () => {
    setIsLoading(true);
    setErrors([]);
    post(`checkout-new-authed/${formValues.plan}`, formValues)
      .then((res) => {
        log(res);
        setCheckoutUrl(res.data.url);
        setCheckoutModalOpen(true);
        ReactFbq.track({ title: 'InitiateCheckout' });
      })
      .catch((err) => {
        log(err.response.data);
        setErrors(err.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpgrade = () => {
    setIsLoading(true);
    setErrors([]);
    post(`artist/update-subscription`, { new_plan_id: formValues.plan }, 'PATCH')
      .then((res) => {
        log(res);
        setCheckoutUrl(res.data.url);
        setCheckoutModalOpen(true);
        navigate('/thank-you');
      })
      .catch((err) => {
        log(err.response.data);
        setErrors(err.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleValChange = (e) => {
    const formValuesb = { ...formValues };
    formValuesb[e.target.name] = e.target.value;

    setFormValues(formValuesb);
    log(formValuesb);
  };

  const handleCheckedChange = (e) => {
    const formValuesb = { ...formValues };
    formValuesb[e.target.name] = e.target.checked;

    setFormValues(formValuesb);
    log(formValuesb);
  };

  const getPlans = () => {
    const config = {
      method: 'get',
      url: `${API_URL}get-plans-new/plan`,
    };

    setIsPlansLoading(true);

    axios(config)
      .then((response) => {
        const theFeatures = response.data.allFeatures;
        const thePlans = response.data.plans;
        log(response);

        if (!params?.planIndex) {
          const formValuesb = { ...formValues, plan: thePlans[0].id };

          setFormValues(formValuesb);
        }

        setAllFeatures(theFeatures);
        setPlans(thePlans);
      })
      .catch((err) => {
        log(err);
      })
      .finally(() => {
        setIsPlansLoading(false);
        setIsLoading(false);
      });
  };

  const handleSOpen = () => {
    setSelectOpen(true);
  };

  const handleSClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    const formValuesb = { ...formValues };

    if (params?.planIndex) {
      formValuesb.plan = params.planIndex;
    } else {
      formValuesb.plan = 0;
    }

    setFormValues(formValuesb);
    getPlans();
  }, []);

  const StyledDivider = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    height: '1px',
  }));

  const convertInterval = (interval) => {
    let text = interval;

    switch (interval) {
      case 'monthly':
        text = 'month';
        break;
      case 'daily':
        text = 'day';
        break;
      case 'weekly':
        text = 'week';
        break;
      case 'yearly':
        text = 'year';
        break;
      default:
        text = interval;
    }

    return text;
  };

  const getSelectedPlan = () => {
    return plans?.find((plan) => plan.id === formValues?.plan);
  };

  const dfbs = useMemo(() => '0px 3px 3px rgba(0,0,0,0.1 )', []);

  const isSubbed = auth.user?.subscription?.id && auth.user.subscription.status !== 'cancelled';

  if (auth?.user?.subscription?.plan?.id?.includes('Advanced') && auth.user.subscription.status !== 'cancelled') {
    return <Navigate to={'/my-account'} />;
  }

  return (
    <>
      <Helmet>
        <title>
          Checkout {TITLE_SEPERATOR} {APP_NAME}
        </title>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      </Helmet>
      <CheckoutModal
        onOpen={checkoutModalOpen}
        checkoutUrl={checkoutUrl}
        onHandleClose={() => setCheckoutModalOpen(false)}
      />
      <StyledBox className="container">
        <Grid container spacing={8.5} justifyContent={'space-between'}>
          <Grid item md={12}>
            <Typography variant="h1" sx={{ color: theme.palette.common.black }}>
              Checkout
            </Typography>
          </Grid>
          <Grid item md={6.65} xs={12}>
            <Stack spacing={3}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  fontSize: '40px',
                }}
              >
                Your products
              </Typography>
              <Card
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  paddingLeft: { md: '44px' },
                  paddingRight: { md: '44px' },
                  paddingTop: { md: '17px' },
                  paddingBottom: { md: '3px' },
                  padding: { xs: '15px' },
                  maxWidth: '667px',
                  boxShadow: dfbs,
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Grid container justifyContent={'space-between'} alignItems="center">
                    {isPlansLoading ? (
                      getSkeletons(1, '100%', '60px')
                    ) : (
                      <>
                        <FormControl fullWidth sx={{ maxWidth: { md: '340px', xs: '195px' } }}>
                          <StyledSelect
                            open={selectOpen}
                            onClose={handleSClose}
                            onOpen={handleSOpen}
                            IconComponent={() => (
                              <Iconify
                                onClick={handleSOpen}
                                width="32px"
                                sx={{ color: '#707070', cursor: 'pointer' }}
                                icon="pepicons-pop:angle-down"
                              />
                            )}
                            name="plan"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formValues?.plan}
                            onChange={handleValChange}
                          >
                            {plans?.map((plan, index) => (
                              <MenuItem
                                key={plan.id}
                                value={plan.id}
                                sx={{
                                  fontSize: '25px',
                                  fontWeight: 'bold',
                                  textAlign: 'left',
                                  color: theme.palette.common.black,
                                }}
                              >
                                {plan.name}
                              </MenuItem>
                            ))}
                          </StyledSelect>
                        </FormControl>
                        <Typography
                          variant="h5"
                          sx={{
                            fontSize: { md: '30px', xs: '14px' },
                            marginTop: { xs: '10px', lg: 0 },
                            fontWeight: { md: 400, xs: 700 },
                            color: theme.palette.common.loDark,
                          }}
                        >
                          ${parseInt(getSelectedPlan()?.price, 10)}/{getSelectedPlan()?.periodUnit}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Typography textAlign={'left'}>
                      <span style={{ color: '#1DD263' }}>Try our services risk-free with a 3-day trial.</span> If you
                      decide to continue, the subscription cost will apply after the trial period.
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
            {!isOnSmallScreen && (
              <>
                {isPlansLoading ? (
                  getSkeletons(1, '100%', '500px')
                ) : (
                  <PricingPlans allFeatures={allFeatures} plans={plans} loading={isPlansLoading} />
                )}
                <Card
                  sx={{
                    marginTop: 14,
                    backgroundColor: theme.palette.common.white,
                    paddingLeft: { md: '44px' },
                    paddingRight: { md: '44px' },
                    paddingTop: { md: '17px' },
                    paddingBottom: { md: '53px' },
                    padding: { xs: '15px' },
                    boxShadow: dfbs,
                  }}
                >
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8912dec7e10d38f59f36"
                    data-businessunit-id="6018054a33fc5e0001b2942a"
                    data-style-height="140px"
                    data-style-width="600"
                    data-theme={{ display: colorMode.darkMode ? 'dark' : 'light' }}
                    data-stars="3,4,5"
                    data-review-languages="en"
                  >
                    <a href="https://www.trustpilot.com/review/yougrowpromo.com" target="_blank" rel="noreferrer">
                      Trustpilot
                    </a>
                  </div>
                </Card>
              </>
            )}
          </Grid>
          <Grid item md={5.35}>
            <Box
              sx={{
                paddingLeft: '43px',
                paddingRight: { lg: '50px', xs: '43px' },
                paddingTop: '57px',
                paddingBottom: !showLoginForm ? '165px' : '57px',
                boxShadow: '0px 3px 3px rgba(0,0,0,0.5 )',
                backgroundColor: theme.palette.darkGreen,
                borderRadius: '5px',
              }}
            >
              <Stack gap={1}>
                {!auth?.user ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography
                            fontSize={'30px'}
                            variant="h3"
                            color={theme.palette.primary.contrastText}
                            sx={{
                              fontSize: '30px',
                              fontWeight: 400,
                            }}
                        >
                          Personal information
                        </Typography>
                        <Link sx={{cursor: 'pointer'}} variant="subtitle2" underline="hover" onClick={() => setShowLoginForm(!showLoginForm)}>
                          {!showLoginForm ? 'Already have an account?' : 'Register an account'}
                        </Link>
                      </Stack>
                    </Grid>
                    {!showLoginForm ? (
                      <>
                        <Grid item sm={6} xs={12}>
                          <StyledTypography>First name</StyledTypography>
                          <StyledTextField
                            name="first_name"
                            fullWidth
                            hiddenLabel
                            value={formValues?.first_name || ''}
                            onChange={handleValChange}
                            error={errors?.first_name}
                            helperText={errors?.first_name}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <StyledTypography>Last name</StyledTypography>
                          <StyledTextField
                            name="last_name"
                            fullWidth
                            hiddenLabel
                            value={formValues?.last_name || ''}
                            onChange={handleValChange}
                            error={errors?.last_name}
                            helperText={errors?.last_name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTypography>Email address</StyledTypography>
                          <StyledTextField
                            name="email"
                            fullWidth
                            hiddenLabel
                            value={formValues?.email || ''}
                            onChange={handleValChange}
                            error={errors?.email}
                            helperText={errors?.email}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <StyledTypography>Password</StyledTypography>
                          <StyledTextField
                            name="password"
                            type="password"
                            fullWidth
                            value={formValues?.password || ''}
                            onChange={handleValChange}
                            error={errors?.password}
                            helperText={errors?.password}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <StyledTypography>Confirm password</StyledTypography>
                          <StyledTextField
                            fullWidth
                            name="password_confirmation"
                            type="password"
                            value={formValues?.password_confirmation || ''}
                            onChange={handleValChange}
                            error={errors?.password_confirmation}
                            helperText={errors?.password_confirmation}
                          />
                        </Grid>
                      </>
                      ) : (
                          <Grid item xs={12}>
                            <ArtistLoginForm isOnCheckout/>
                          </Grid>
                      )
                    }
                    {errors?.general?.length ? <Alert severity="error">{errors.general}</Alert> : ''}
                  </Grid>
                ) : (
                  <Stack spacing={3}>
                    <StyledTypography variant="h4">
                      Hello {auth.user.first_name}, you can upgrade your account here, and enjoy all extra YouGrow+
                      features.
                    </StyledTypography>
                  </Stack>
                )}
                {isPlansLoading ? (
                  getSkeletons(1, '100%', '300px')
                ) : !showLoginForm && (
                  <>
                    <Grid container sx={{ justifyContent: 'space-between', paddingTop: '20px' }}>
                      <StyledTypography variant="body2">Subtotal</StyledTypography>
                      <StyledTypography variant="body2">${getSelectedPlan()?.price}</StyledTypography>
                    </Grid>
                    <StyledDivider />
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                      <StyledTypography variant="body2">Tax</StyledTypography>
                      <StyledTypography variant="body2">
                        ${parseFloat(parseInt(getSelectedPlan()?.price, 10) * 0.21).toFixed(2)}
                      </StyledTypography>
                    </Grid>
                    <StyledDivider />
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                      <StyledTypography variant="h6">Total</StyledTypography>
                      <StyledTypography variant="h6">${getSelectedPlan()?.price}</StyledTypography>
                    </Grid>
                    <StyledDivider />
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                      <StyledTypography variant="h6">Recurring totals</StyledTypography>
                    </Grid>
                    <StyledDivider />
                    <Grid container sx={{ justifyContent: 'space-between', paddingLeft: '22px' }}>
                      <StyledTypography variant="h6">Subtotal</StyledTypography>
                      <StyledTypography variant="h6">
                        ${getSelectedPlan()?.price}/{convertInterval(getSelectedPlan()?.periodUnit)}
                      </StyledTypography>
                    </Grid>
                    <StyledDivider sx={{ paddingLeft: '22px', width: '95%', ml: 'auto' }} />
                    <Grid container sx={{ justifyContent: 'space-between', paddingLeft: '22px' }}>
                      <StyledTypography variant="h6">Tax</StyledTypography>
                      <StyledTypography variant="h6">
                        ${parseFloat(parseInt(getSelectedPlan()?.price, 10) * 0.21).toFixed(2)}/
                        {convertInterval(getSelectedPlan()?.periodUnit)}
                      </StyledTypography>
                    </Grid>
                    <StyledDivider sx={{ paddingLeft: '22px', width: '95%', ml: 'auto' }} />
                    <Grid container sx={{ justifyContent: 'space-between', paddingLeft: '22px' }}>
                      <StyledTypography variant="h6">Recurring total</StyledTypography>
                      <StyledTypography variant="h6">
                        ${getSelectedPlan()?.price}/{getSelectedPlan()?.periodUnit}
                      </StyledTypography>
                    </Grid>
                  </>
                )}
                {!isSubbed && !showLoginForm && (
                  <Box sx={{ marginTop: '20px' }}>
                    <Stack>
                      <StyledTypography>{dataProtectionText}</StyledTypography>
                      <FormGroup sx={{ marginTop: '13px' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ color: '#9d9d9d' }}
                              onChange={handleCheckedChange}
                              name="newsLetterAccept"
                              checked={formValues?.newsLetterAccept || false}
                            />
                          }
                          label={
                            <StyledTypography>I want to receive updates about products and promotion</StyledTypography>
                          }
                        />
                      </FormGroup>
                      <FormControl error={errors?.privacyAccept && true}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ color: '#9d9d9d' }}
                                onChange={handleCheckedChange}
                                name="privacyAccept"
                                checked={formValues?.privacyAccept || false}
                              />
                            }
                            label={
                              <StyledTypography>
                                By checking this checkbox, you agree to our{' '}
                                <a
                                  style={{ color: theme.palette.platform.spotify }}
                                  rel="noreferrer"
                                  href="https://www.yougrowpromo.com/privacy?utm_source=yougrowplus"
                                  target="_blank"
                                >
                                  privacy policy
                                </a>
                              </StyledTypography>
                            }
                          />
                        </FormGroup>
                        {errors?.privacyAccept && <FormHelperText>{errors?.privacyAccept}</FormHelperText>}
                      </FormControl>
                    </Stack>
                  </Box>
                )}
                {!showLoginForm &&
                  <Box sx={{ marginTop: '15px' }}>
                    {!auth.user ? (
                      <StyledLoadingButton
                        fullWidth
                        onClick={() => {
                          handleRegister();
                        }}
                        loading={isLoading}
                      >
                        Place order now
                      </StyledLoadingButton>
                    ) : isSubbed ? (
                      <StyledLoadingButton
                        fullWidth
                        onClick={() => {
                          handleUpgrade();
                        }}
                        loading={isLoading}
                      >
                        Upgrade
                      </StyledLoadingButton>
                    ) : (
                      <StyledLoadingButton
                        onClick={() => {
                          handleCheckout();
                        }}
                        loading={isLoading}
                      >
                        Place order now
                      </StyledLoadingButton>
                    )}
                  </Box>
                  }
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </StyledBox>
    </>
  );
}
