import { useState, useRef, useEffect} from "react";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import 'dayjs/locale/nl';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Stack, TextField, Typography, Button, Paper, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../auth/Authentication";
import { AppWebsiteVisits } from "../../../sections/@dashboard/app";
import { API_URL } from "../../../hooks/globals";
import useGetSkeletons from "../../../hooks/useGetSkeletons";
import useConsoleLog from "../../../hooks/useConsoleLog";
import useResponsive from "../../../hooks/useResponsive";
import useGetFormattedDate from "../../../hooks/useGetFormattedDate";
import Arrow from "../../../components/arrow-bg/Arrow";
import StyledPaper from "../../../theme/artist/overrides/StyledPaper";

export default function HistoryStats(props) {
    const [getSkeletons] = useGetSkeletons();
    const getFormattedDate = useGetFormattedDate();
    const log = useConsoleLog;

    const firstStartDate = new Date();
    firstStartDate.setDate(firstStartDate.getDate() - 30);

    const [showSettings, setShowSettings] = useState(false);
    const [reversedChartData, setReversedChartData] = useState([]);
    const [startDate, setStartDate] = useState(getFormattedDate(firstStartDate));
    const [endDate, setEndDate] = useState(getFormattedDate(new Date()));
    const isOnSmallerScreen = useResponsive("down", "sm", "lg");

    const theme = useTheme();

    const colors = [
        theme.palette.primary.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.error.main,
        theme.palette.success.main,
        theme.palette.warning.dark,
        theme.palette.success.darker,
        theme.palette.info.dark,
        theme.palette.info.darker,
    ];

    log("props.chartData");
    log(props.chartData);
    log(props.source);

 
    if (props.chartData[props.source]?.length && !props.errorMsg && !props.isLoading) {
        return (
        <Stack gap={3} position="relative">    
            <Grid container justifyContent="space-between">   
                <Typography variant="h4" sx={{paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>{(props.track)? 'Song':'Artist'} performance</Typography>
                {props.orderArrows}
            </Grid>
            <Arrow platformId={props.source}/>
            <StyledPaper sx={{
                px: {md: 5, xs: 0},
                zIndex: 999,
                backgroundColor: theme.palette.grey[200], borderRadius: {xs: "0", md: "10px"}, "&:before": { md: {backgroundColor: props.platformColor} }, "&:after": { md: {backgroundColor: props.platformColor} }}}>
                <Stack gap={3} position="relative">    
                    {isOnSmallerScreen && <Button sx={{display: "block", ml: "auto", color: props.platformColor}} onClick={() => setShowSettings(!showSettings)}>{showSettings ? "Hide" :  "Show"} settings</Button>}
                    {
                    (!isOnSmallerScreen || showSettings) && 
                    <Grid container justifyContent={`start`} sx={{px: {md: 'auto', xs: 1}}}>
                        <Grid item xs={12} md={'auto'} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
                                <DatePicker
                                label="Start date"
                                value={startDate}
                                onChange={(newValue) => {
                                    if (new Date(newValue) < new Date(endDate)) {
                                        setStartDate(getFormattedDate(new Date(newValue)));
                                    }
                                }}
                                renderInput={(params) => <TextField sx={{width: isOnSmallerScreen ? '100%' : 'auto'}} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={'auto'} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
                                <DatePicker
                                label="End date"
                                value={endDate}
                                onChange={(newValue) => {
                                    if (new Date(newValue) > new Date(startDate)) {
                                        setEndDate(getFormattedDate(new Date(newValue)));
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    sx={{marginLeft: !isOnSmallerScreen && 2, marginTop: isOnSmallerScreen && 2, width: isOnSmallerScreen ? '100%' : 'auto'}} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={'auto'} sm={4}>
                            <Grid container justifyContent="space-between">
                                <LoadingButton loading={props.isLoading} size="large" sx={{color: props.platformColor, height: "56px", marginLeft: !isOnSmallerScreen && 2, marginTop: isOnSmallerScreen && 2,  width: isOnSmallerScreen && '100%'}} onClick={() => props.onGetHistoryStats(props.source, startDate, endDate)}>Load</LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    }              
                    <Grid container spacing={3}> {
                        props.chartData[props.source].map((dataPoint, index) => (
                            <Grid item xs={12} md={6}>
                                <AppWebsiteVisits
                                color={colors[index]}
                                title={dataPoint.name}
                                chartLabels={props.columns[props.source][dataPoint.key]}
                                chartData={[dataPoint]}
                                />
                            </Grid>
                            ))
                        } 
                    </Grid>
                </Stack>
            </StyledPaper>
        </Stack>
        )
    }

    if (!props.chartData[props.source]?.length && props.errorMsg && !props.isLoading) {
        return (<Alert severity="error">{props.errorMsg}</Alert>)
    }

    if (!props.chartData[props.source]?.length && !props.errorMsg && !props.isLoading) {
        return (
            <Stack gap={3} position="relative">    
                <Grid container justifyContent="space-between">   
                    <Typography variant="h4" sx={{paddingLeft: {md: 0, xs: "30px"}, paddingRight: {md: 0, xs: "30px"} }}>Artist performance</Typography>            
                    {props.orderArrows}
                </Grid>
                <Arrow platformId={props.source}/>
                <StyledPaper
                className="no-history-data" sx={{
                    px: {md: 5, xs: 0},
                    zIndex: 999,
                    backgroundColor: theme.palette.grey[200], borderRadius: {xs: "0", md: "10px"}, "&:before": { md: {backgroundColor: props.platformColor} }, "&:after": { md: {backgroundColor: props.platformColor} }}}
                    >
                        <Typography className="no-data">
                            No data found
                        </Typography>
                </StyledPaper>
            </Stack>
        )
    }

    return (
        <Stack gap={3}>
            {getSkeletons(1, '50%', 56)}
            {getSkeletons(1, '100%', 910)}
        </Stack>
    );
}