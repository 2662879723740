import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FaTrash } from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../auth/Authentication';
import { API_URL } from '../../../hooks/globals';
import { StyledScrollbar } from '../../../components/scrollbar/styles';

export default (props) => {
  const { refreshOrders, loading, reset, setCurrentEmail } = props;
  const [showModal, setShowModal] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [secondaryEmails, setSecondaryEmails] = useState([]);
  const [currentHoverEmail, setCurrentHoverEmail] = useState('');
  const [selectedDeleteEmail, setSelectedDeleteEmail] = useState('');
  const [activeMail, setActiveMail] = useState('');
  const [refetch, setRefetch] = useState(0);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const user = useAuth();
  const [notification, setNotification] = useState({
    visible: false,
    type: 'success',
    message: '',
  });
  const [email, setEmail] = useState('');
  const auth = useAuth();

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };
  const addSecondaryEmail = (event) => {
    event.preventDefault();
    const config = {
      method: 'post',
      url: `${API_URL}secondary_email`,
      headers: {
        Authorization: `Bearer ${auth.userToken}`,
      },
      data: {
        emails: [email],
      },
    };
    setLoadingSubmit(() => true);

    axios(config)
      .then((res) => {
        if (res.data.success) {
          setNotification({
            ...notification,
            visible: true,
            type: 'success',
            message: 'Verification email has been sent.',
          });
          setShowModal(false);
          const secondaryEmailsWithIds = [];
          let count = 1;
          // eslint-disable-next-line no-restricted-syntax
          for (const i of res?.data?.emails) {
            secondaryEmailsWithIds.push({ ...i, id: count });
            count += 1;
          }
          setSecondaryEmails(secondaryEmailsWithIds);
          const selectedEmail = secondaryEmailsWithIds?.filter((item) => item.included && item?.validated);
          setActiveMail(selectedEmail[0]);

          reset(selectedEmail[0]);
          refreshOrders(selectedEmail[0]);
        } else {
          setErrors(() => res.data.errors);
          setShowErrors(() => true);
        }
      })
      .finally(() => {
        setEmail(() => '');
        setLoadingSubmit(() => false);
      });
  };

  function sleep(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  const toggleIncludedEmail = async (emailObj) => {
    if (!emailObj?.validated) {
      setNotification({
        ...notification,
        visible: true,
        type: 'warning',
        message: 'Please validate your secondary email address.',
      });
      return;
    }
    setActiveMail(emailObj);

    loading(() => true);
    reset(emailObj.email);

    const config = {
      method: 'patch',
      url: `${API_URL}secondary_email/${emailObj.email}`,
      headers: {
        Authorization: `Bearer ${auth.userToken}`,
      },
      params: {
        include: !emailObj.included,
        artistId: userId,
      },
    };
    axios(config).then((res) => {
      if (res.data.success) {
        const secondaryEmailsWithIds = [];
        let count = 1;
        // eslint-disable-next-line no-restricted-syntax
        for (const i of res?.data?.emails) {
          secondaryEmailsWithIds.push({ ...i, id: count });
          count += 1;
        }
        setSecondaryEmails(secondaryEmailsWithIds);
        const selectedEmail = secondaryEmailsWithIds?.filter((item) => item.included && item?.validated);
        setActiveMail(selectedEmail[0]);

        reset(selectedEmail[0]);
        refreshOrders(selectedEmail[0]);
      }
    });
  };
  useEffect(() => {
    const url = userId ? `${API_URL}secondary_email/${userId}` : `${API_URL}secondary_email`;
    const config = {
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${auth.userToken}`,
      },
    };
    axios(config).then((res) => {
      if (res.data.success) {
        const secondaryEmailsWithIds = [];
        let count = 1;
        // eslint-disable-next-line no-restricted-syntax
        for (const i of res?.data?.emails) {
          secondaryEmailsWithIds.push({ ...i, id: count });
          count += 1;
        }
        setSecondaryEmails(secondaryEmailsWithIds);
        const selectedEmail = secondaryEmailsWithIds?.filter((item) => item.included && item?.validated);
        reset(selectedEmail[0]);
        refreshOrders(selectedEmail[0]);
        setActiveMail(selectedEmail[0]);
      }
    });
  }, [refetch]);
  useEffect(() => {
    if (notification.visible)
      setTimeout(() => {
        setNotification({ ...notification, visible: false, message: '' });
      }, 3000);
  }, [notification]);
  const { userId, userEmail } = useParams();
  useEffect(() => {
    console.log(userId, userEmail);
  }, [userId]);

  const handleDeleteSecondaryEmail = async () => {
    await axios
      .delete(`${API_URL}secondary-email/${selectedDeleteEmail}`, {
        headers: {
          Authorization: `Bearer ${auth.userToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.success) {
          setNotification({
            ...notification,
            visible: true,
            type: 'success',
            message: res?.data?.message,
          });
          setSelectedDeleteEmail('');
          setRefetch(refetch + 1);
          setDeleteModal(false);
        } else {
          setNotification({
            ...notification,
            visible: true,
            type: 'warning',
            message: res?.data?.message,
          });
          setSelectedDeleteEmail('');
        }
      })
      .catch((err) => {
        setNotification({
          ...notification,
          visible: true,
          type: 'warning',
          message: err?.message,
        });
      });
  };

  const handleDeleteConfirm = (email, event) => {
    event.stopPropagation();
    setDeleteModal(true);
    setSelectedDeleteEmail(email);
  };

  const handleHovered = (emailObj) => {
    if (emailObj?.email === user?.user?.email) {
      let count = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const i of secondaryEmails) {
        if (i?.email === user?.user?.email) {
          count += 1;
        }
        if (count > 1) {
          setCurrentHoverEmail(emailObj);
        }
      }
    } else {
      setCurrentHoverEmail(emailObj);
    }
  };

  const getIdOfPrimaryEmails = () => {
    const primaryCount = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < secondaryEmails?.length; i++) {
      if (secondaryEmails[i].email === user?.user?.email) {
        primaryCount.push(i);
      }
    }
    return primaryCount;
  };

  return (
    <>
      <div className={'row'}>
        <div className={'col emails-view'}>
          <StyledScrollbar
            forceVisible="y"
            autoHide={false}
            hidden={false}
            sx={{ height: 80, display: { md: 'none' } }}
          >
            {secondaryEmails.map((emailObj, index) => (
              <div
                key={index}
                role="button"
                tabIndex={index}
                className={`email ${activeMail?.id === emailObj?.id && 'selected'} ${
                  !emailObj.validated && 'disabled'
                }`}
                onClick={() => toggleIncludedEmail(emailObj)}
                onMouseOver={() => handleHovered(emailObj)}
                onFocus={() => handleHovered(emailObj)}
                onMouseLeave={() => setCurrentHoverEmail('')}
                onKeyUp={() => ''}
              >
                <div className="secondary-email-div">
                  <span tabIndex={index} className="name-icon">
                    {emailObj.email.substring(0, 1).toUpperCase()}
                  </span>
                  {emailObj.email}
                  {user?.user?.email === emailObj?.email ? (
                    <>
                      {getIdOfPrimaryEmails()[0] === index ? (
                        <></>
                      ) : (
                        <>
                          {activeMail?.id !== emailObj?.id && (
                            <button
                              onClick={(event) => handleDeleteConfirm(emailObj?.email, event)}
                              className="remove-email"
                            >
                              <CloseIcon className="remove-email-icon" />
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {activeMail?.id !== emailObj?.id && (
                        <button
                          onClick={(event) => handleDeleteConfirm(emailObj?.email, event)}
                          className="remove-email"
                        >
                          <CloseIcon className="remove-email-icon" />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </StyledScrollbar>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {secondaryEmails.map((emailObj, index) => (
              <div
                key={index}
                role="button"
                tabIndex={index}
                className={`email ${activeMail?.id === emailObj?.id && 'selected'} ${
                  !emailObj.validated && 'disabled'
                }`}
                onClick={() => toggleIncludedEmail(emailObj)}
                onMouseOver={() => handleHovered(emailObj)}
                onFocus={() => handleHovered(emailObj)}
                onMouseLeave={() => setCurrentHoverEmail('')}
                onKeyUp={() => ''}
              >
                <div className="secondary-email-div">
                  <span tabIndex={index} className="name-icon">
                    {emailObj.email.substring(0, 1).toUpperCase()}
                  </span>
                  {emailObj.email}
                  {currentHoverEmail?.email === emailObj?.email &&
                    currentHoverEmail?.id === emailObj?.id &&
                    activeMail?.id !== emailObj?.id && (
                      <button onClick={(event) => handleDeleteConfirm(emailObj?.email, event)} className="remove-email">
                        <CloseIcon className="remove-email-icon" />
                      </button>
                    )}
                </div>
              </div>
            ))}
          </Box>
        </div>
        {!userId && (
          <div className={'col import-order'}>
            <button onClick={openModal}>
              <span className="plus-icon">+</span>
              <a className={'import-order-btn'}>Import Orders</a>
            </button>
          </div>
        )}
      </div>
      <EmailModal show={showModal} handleClose={closeModal}>
        <Box
          className={'title'}
          sx={{
            ml: { xs: 1, md: '4rem' },
            mr: { xs: 1, md: '4rem' },
          }}
        >
          <Box
            className={'main'}
            sx={{
              ml: { xs: 1, md: '4rem' },
              mr: { xs: 1, md: '4rem' },
            }}
          >
            <h2
              style={{
                marginBottom: 'auto',
                marginTop: '15px',
                textAlign: 'center',
                fontSize: { xs: '21px', md: '50px' },
                fontWeight: 400,
              }}
            >
              Import orders
            </h2>
            <hr
              style={{
                width: '35%',
                border: 'none',
                height: '2px',
                margin: 'auto',
                backgroundColor: 'lightgreen',
              }}
            />
            <h3 style={{ margin: { md: '15px 20% 0px 20%' }, textAlign: 'center' }}>
              Combine the orders from different email addresses into one dashboard
            </h3>
            <h3 style={{ textAlign: 'center' }}>You will receive an email to verify your email address</h3>
          </Box>
        </Box>
        <Box
          className={'body'}
          sx={{
            ml: { xs: '1px', md: '4rem' },
            mr: { xs: '1px', md: '4rem' },
          }}
        >
          <div className={'email-form'}>
            <input
              className={'email-input'}
              placeholder={'Email address'}
              type={'email'}
              id={'email'}
              name={'email'}
              onChange={(event) =>
                setEmail(() => {
                  setShowErrors(() => false);
                  return event.target.value;
                })
              }
              value={email}
            />
          </div>
        </Box>
        <div
          className={'errors'}
          style={{
            display: showErrors ? 'inline-block' : 'none',
          }}
        >
          Email address invalid, or already added to your account.
        </div>
        <Box
          className={'footer'}
          sx={{
            ml: { xs: '1px', md: '4rem' },
            mr: { xs: '1px', md: '4rem' },
          }}
        >
          <LoadingButton className="submit-btn" loading={loadingSubmit} variant="contained" onClick={addSecondaryEmail}>
            Import
          </LoadingButton>
        </Box>
      </EmailModal>

      <DeleteConfirmModal
        handleDeleteSecondaryEmail={handleDeleteSecondaryEmail}
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
      />
      <Notification message={notification.message} type={notification.type} visible={notification.visible} />
    </>
  );
};
const EmailModal = (props) => {
  const { show, handleClose } = props;

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ padding: 10 }}>
      <Box
        className="modal-content"
        sx={{
          margin: { xs: '92px 5px 5px 5px', md: '10% auto' },
          padding: { xs: '5px', md: '20px' },
          width: { xs: '95%', md: '50%' },
        }}
      >
        <div className={'header'}>
          <button className="close" onClick={handleClose}>
            &times;
          </button>
        </div>
        {props.children}
      </Box>
    </div>
  );
};

const DeleteConfirmModal = (props) => {
  const { show, handleClose, handleDeleteSecondaryEmail } = props;
  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ padding: 10 }}>
      <Box
        className="modal-content"
        sx={{
          margin: { xs: '92px 5px 5px 5px', md: '10% auto' },
          padding: { xs: '5px', md: '20px' },
          width: { xs: '95%', md: '50%' },
        }}
      >
        <div className={'header'}>
          <button className="close" onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className="modal-body title">
          <div className="main">
            <h3 style={{ margin: { md: '15px 20% 0px 20%' }, textAlign: 'center' }}>
              Are you sure you want to delete this email address?
            </h3>
          </div>
          <div className="delete-modal-btn">
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
            {/* desktop */}
            <button className="delete-button" onClick={handleDeleteSecondaryEmail}>
              Confirm
            </button>

            {/* mobile */}
            <button onClick={handleDeleteSecondaryEmail} className="delete-button-mobile">
              Confirm
            </button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const Notification = (props) => {
  const { message, type, visible } = props;

  return visible ? <div className={`notification alert alert-${type}`}>{message}</div> : null;
};
