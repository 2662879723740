import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Alert,
} from '@mui/material';
import axios from 'axios';
// components
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/loader';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ItemListHead, ItemListToolbar } from '../../sections/@dashboard/item';
import { useModal } from '../../modals/ModalContext';
import FormModal from '../../modals/FormModal';
import DeleteFormModal from '../../modals/DeleteFormModal';
import Snacks from '../../components/snacks';
import { useSnackHandler } from '../../hooks/useSnackHandler';
import { API_URL, APP_NAME, TITLE_SEPERATOR } from '../../hooks/globals';
import { useAuth } from '../../auth/Authentication';
import useForceRerender from '../../hooks/useForceRerender';
import useConsoleLog from '../../hooks/useConsoleLog';
// ----------------------------------------------------------------------


export default function ItemPage(props) {
  function descendingComparator(a, b, orderBy) {
    let theA = a[orderBy];
    let theB = b[orderBy];
    
    if (orderBy === 'createdAt') {
      theA = new Date(a[orderBy]?.split("-").reverse().join("-"));
      theB = new Date(b[orderBy]?.split("-").reverse().join("-"));
    }
  
    if (theB < theA) {
      return -1;
    }
    if (theB > theA) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_item) => {
        return _item[props.objectKeys[1]]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
      });
        
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const [axiosError, setAxiosError] = useState('');

  const log = useConsoleLog;

  const [open, setOpen] = useState(null);

  const [isEditing, setIsEditing] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState({});
  const [selectedForMultiDelete, setSelectedForMultiDelete] = useState([]);

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [itemList, setItemList] = useState([]);

  const [emptyRows, setEmptyRows] = useState(0);

  const [filteredItems, setFilteredItems] = useState();

  const [isNotFound, setIsNotFound] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [apiRequestsDone, setApiRequestsDone] = useState(0);

  const snackHandler = useSnackHandler();

  const modal = useModal();

  const location = useLocation();

  const forceRerender = useForceRerender();

  // order statuses options start, only if on orders page
  const [availableSatuses, setAvailableStatuses] = useState([]);
  const [status, setStatus] = useState(props.status);
  const [startChange, setStartChange] = useState();
  const [orderId, setOrderId] = useState(); 


  const initChangeOrderStatus = (id) => {
      setStartChange(id);
  }

  const changeOrderStatus = () => {
      const config = {
          method: 'post',
          url: `${API_URL}admin/orders/${orderId}/status-update`,
          data: {status: startChange},
          headers: {
              "Authorization": `Bearer ${auth.userToken}` 
          }
      }

      axios(config).then((response) => {
          log(response.data);
          setApiRequestsDone(apiRequestsDone + 1);
          setStatus(startChange);
      }).catch((error) => {
          log(error);
      }).finally(() => {
          setStartChange(0);
          setApiRequestsDone(apiRequestsDone + 1);
      });
  }

  useEffect(() => {
    if (location.pathname.includes('admin/orders')) {
      const config = {
          method: 'get',
          url: `${API_URL}admin/order-statuses`,
          headers: {
              "Authorization": `Bearer ${auth.userToken}` 
          }
      }

      axios(config).then((response) => {
          setAvailableStatuses(response.data);
          log(response.data);
      }).catch((error) => {
          log(error);
      }).finally(() => {
      });
    }
  },[])
  //  end of order status change

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditClose = () => {
    setIsEditing(null);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (Object.keys(selected)?.length > 0) {
      setSelected({});
      setSelectedForMultiDelete([])
    } else {
      const newSelecteds = {};
      const multiDelIds = [];

      filteredItems.map((item, i) => {
        newSelecteds[i] = true;
        multiDelIds.push(item.id)
        return newSelecteds;
      });

      log('multideldi');
      log(multiDelIds);

      setSelectedForMultiDelete(multiDelIds);
      
      setSelected(newSelecteds);      
    }
  };

  const handleClick = (event, name) => {
    log(name);
    const newSelected = {...selected};
    let multiDelIds = [...selectedForMultiDelete];
    
    if (newSelected[name]) {
      delete newSelected[name];
      multiDelIds = multiDelIds.filter((item) => item !== filteredItems[name].id);
    } else {
      newSelected[name] = true;
      multiDelIds.push(filteredItems[name].id);
    }

    log('singi');
    log(multiDelIds);
    setSelectedForMultiDelete(multiDelIds);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const auth = useAuth();

  const initItems = (alwaysRun = false) => {
    setAxiosError('');
    const url = `${API_URL}${props.apiPath}`;

    const config = {
      method: 'get',
      url,
      headers: { 
          'Authorization': `Bearer ${auth.userToken}`
      }
    };

    axios(config).then((response) => {
      const theItems = [...Object.values(response.data)]
      setItemList(theItems);
  
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemList.length) : 0);
  
      if ((!filteredItems?.length && theItems.length) || alwaysRun) {
        log('set filtered items');
        setFilteredItems(applySortFilter(theItems, getComparator(order, orderBy), filterName));
      }

      if (!theItems.length) {
        setAxiosError("We couldn't find any data, please create an item.");        
      }
  
      setIsNotFound(!filteredItems?.length && !!filterName);

      log(response);
    })
    .catch((error) => {
      setAxiosError('Something went wrong while fetching the data!');
      log(error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    initItems(true);
  }, [apiRequestsDone]);

  useEffect(() => {
    setFilteredItems(applySortFilter(itemList, getComparator(order, orderBy), filterName));
  }, [filterName, order, orderBy]);

  useEffect(() => {
    log('newitems');
    if (Object.keys(modal.newItem).length) {
    log('newitemsReal');
    setFilteredItems(applySortFilter([...itemList, modal.newItem], getComparator(order, orderBy), filterName));
    }
    log('new modal item');
  }, [modal.newItem])

  useEffect(() => {
    log('delopen');
    initItems(true);
  }, [modal.delOpen]);

  useEffect(() => {
    log('selected');
    log(selected);
  }, [selected])

  

  return (
    <>
    {isLoading && <Loader progress={100}/>}
      <Helmet>
        <title> {props.pageTitle}s {TITLE_SEPERATOR} {APP_NAME} </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {props.pageTitle}s
          </Typography>
          {props.form && <FormModal buttonName={`New ${props.pageTitle}`} form={props.form} title={props.pageTitle}/>}
        </Stack>

        <Card>
          <ItemListToolbar ids={selectedForMultiDelete} name="the selected order updates" path={props.apiPath} key={1} numSelected={Object.keys(selected).length} filterName={filterName} onFilterName={handleFilterByName} pageTitle={props.pageTitle} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ItemListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={props.TABLE_HEAD}
                  rowCount={itemList.length}
                  numSelected={Object.keys(selected).length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  pageTitle={`${props.pageTitle}`}
                  noDelete={props.noDelete}
                />
                <TableBody>
                    
                  {filteredItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowKey) => {
                    const { id, name } = row;
                    const cells = Object.values(row);
                    const cellKeys = Object.keys(row);

                    return (
                      <>
                      <TableRow hover key={`row-${rowKey}-${id}-${row[Object.keys(row)[1]]}`} tabIndex={-1} role="checkbox" selected={selected[rowKey]}>
                        {!props.noDelete &&
                          <TableCell padding="checkbox" key={`chb-${row[Object.keys(row)[1]]}`}>
                            {
                            selected[rowKey] ? <Checkbox key={`checkbox-${rowKey}`} checked onChange={(event) => handleClick(event, rowKey)} />
                            : <Checkbox key={`ncheckbox-${rowKey}`} onChange={(event) => handleClick(event, rowKey)} />
                            }                          
                          </TableCell>
                        }
                        {cells.slice(1).map((value, key) => {
                            return key === 0 &&  typeof value !== "object" ?  
                              <TableCell key={`tablecell-${key}-${value}`} align="left" size="small">
                                <Link to={`${id}`} variant="subtitle2" underline="hover">{value}</Link>
                              </TableCell> 
                              : 
                              <TableCell key={`ztablecell-${key}-${value}`} align="left" size="small">
                                {typeof value === "object" 
                                ? 
                                <Link to={`/${props.objectPath}${value?.[props.objectKeys?.[0]]}`} variant="subtitle2" underline="hover">{value?.[props.objectKeys?.[2]]}</Link> 
                                : 
                                cellKeys[key+1] === 'colorCode' ?
                                <Box sx={{backgroundColor: value, height: 10, width: 10, borderRadius: '100%', marginX: 'auto'}}/>
                                : value.length > 50 ? `${value.slice(0, 50)}...` : value}
                              </TableCell>
                        })}
                        {props.viewOrders &&
                          <TableCell key={`ici-${row[Object.keys(row)[1]]}`} align="right">
                            <Link to={`${props.viewOrders}/${row.id}/${row.email}`}>View orders</Link>
                          </TableCell>
                        }
                        {!props.noEdit &&
                          <TableCell key={`ici-${row[Object.keys(row)[1]]}`} align="right">
                            <IconButton key={`icia-${row[Object.keys(row)[1]]}`} size="large" color="inherit" onClick={handleOpenMenu} id={id} name={row[Object.keys(row)[1]]}  value={rowKey}>
                              <Iconify key={`icic-${row[Object.keys(row)[1]]}`} icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                      {props.updateForm && parseInt(isEditing, 10) === parseInt(id, 10) &&
                      <TableRow>
                        <TableCell colSpan={props.updateColspan} width="100%">                        
                          {props.updateForm(id, row, apiRequestsDone, setApiRequestsDone, handleEditClose)}
                        </TableCell>
                      </TableRow>}
                      </>
                    );
                  })}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
                          
          {axiosError && (<Alert severity='error'>{axiosError}</Alert>)}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={itemList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => {handleCloseMenu(); setIsEditing(open?.id);}}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {!props.noDelete &&
        <MenuItem sx={{ color: 'error.main' }} onClick={() => {handleCloseMenu(); modal.handleDelOpen(open?.id, open?.name, props.apiPath, open?.value)}}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> }
      </Popover>

      <DeleteFormModal/>

      <Snacks snack={snackHandler}/>
    </>
  );
}
